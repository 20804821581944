/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useAlert, useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPermissoes, EnumRoles, EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums';
import { useAuth } from '../../../../modules/auth'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Popover } from '@mui/material';

interface Data {
  Id: number,
  Titulo: string,
  DataCriacao: string,
  Responsavel: number
}

interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  {
    id: 'Titulo',
    label: 'Titulo',
  },
  {
    id: 'DataCriacao',
    label: 'Data de Criação',
  },
  {
    id: 'Responsavel',
    label: 'Responsável',
  }
]

function createData(
  Id: number,
  Titulo: string,
  DataCriacao: string,
  Responsavel: number
): Data {
  return { Id, Titulo, DataCriacao, Responsavel }
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead>
      <TableRow className='table-pro4tech-header'>
        {columns.map((columns) => (
          <TableCell
            className='table-pro4tech-header-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            {columns.label}
          </TableCell>
        ))}
        <TableCell className='table-pro4tech-header-cell fw-bolder' align='center'>
          AÇÕES
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const Looks = () => {
  const httpClient = useHttpClient()
  const alert = useAlert()
  const blockUI = useBlockUI()
  const navigate = useNavigate()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('DataCriacao')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])
  const [search, setSearch] = React.useState<string>('')
  const [ativo, setAtivo] = React.useState(true)
  const { auth } = useAuth()
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes
  const [popoverTableActionsAnchorEl, setPopoverTableActionsAnchorEl] = React.useState<(HTMLButtonElement | null)[]>([])
  const popoverTableActionsOpen = Boolean(popoverTableActionsAnchorEl)

  const onKeyDownBuscar = (search: string) => {
    setSearch(search);
    resquestTable(page, orderBy, order, rowsPerPage, search, true);
  }

  const resquestTable = async (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    search: any = null,
    block: boolean = false,
  ) => {
    let response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Estilo/ComposicaoLook/Listar',
      blockFree: block,
      data: {
        Order: orderRq,
        OrderBy: orderByRq,
        Page: pageRq,
        RowsPerPage: rowsPerPageRq,
        Search: search,
        Filters: {
          Texto: search,
          Ativo: ativo,
        }
      }
    })

    if (response.success && response.payload) {
      let newRows = response.payload.data.map((data: Data) => {
        return createData(
          data.Id,
          data.Titulo,
          data.DataCriacao,
          data.Responsavel,
        )
      })
      setRows([...newRows])
      setTotal(response.payload.total)

      console.log("Dados recebidos do backend:", response.payload.data);
    }
  }

  React.useEffect(() => {
    obterDados()
  }, [ativo])

  const obterDados = async () => {
    blockUI.setBlocking(true)
    await obterDadosTabela()
    blockUI.setBlocking(false)
  }

  const obterDadosTabela = async () => {
    await resquestTable(page, orderBy, order, rowsPerPage, search)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage, search)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage, search)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10), search)
    setPage(0)
  }

  const emptyRows = rows.length

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Looks</h3>
          </div>
          <div className='card-toolbar'>
            {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Design_Sugestão de Compras_Gerenciamento de Looks_Adicionar'])) && (
              <button
                className='btn btn-primary'
                title='Adicionar'
                onClick={(e) => {
                  navigate('/estilo/look/adicionar', {
                    state: {
                      visualizar: false,
                      title: 'Adicionar'
                    }
                  })
                }}
              >
                <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-1" />
                Adicionar
              </button>
            )}
          </div>
        </div>
        <div className='card-body'>

          <form className='form'>
            <div>
              <div className='row' >
                {/* end::Input group */}
                {/* begin::Input group */}
                <div className='mb-2 col-lg-4'>
                  <label className='fw-bold text-pro4tech'>Pesquisar (Titulo)</label>
                  <input
                    type='text'
                    onChange={e => onKeyDownBuscar(e.target.value)}
                    placeholder='Pesquisar (Titulo)'
                    className={`form-control form-control-lg form-control-solid`}
                    value={search}
                  ></input>
                </div>
                {/* end::Input group */}
              </div>
              <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_4"
                    onClick={() => setAtivo(true)}
                  >
                    Ativos
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_5"
                    onClick={() => setAtivo(false)}
                  >
                    Inativos
                  </a>
                </li>
              </ul>
              <Box sx={{ width: '100%' }}>
                <Paper className='table-pro4tech-border mb-0' sx={{ width: '100%', mb: 2 }}>
                  <TableContainer>
                    <Table className='table-pro4tech-header' sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>
                        {rows.map((row, index) => {
                          return (
                            <TableRow
                              className='table-pro4tech-row'
                              hover
                              tabIndex={-1}
                              key={index}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell className='table-pro4tech-cell' align='left'>
                                {row.Titulo}
                              </TableCell>
                              <TableCell className='table-pro4tech-cell' align='left'>
                                {row.DataCriacao}
                              </TableCell>
                              <TableCell className='table-pro4tech-cell' align='left'>
                                {row.Responsavel}
                              </TableCell>
                              <TableCell className='table-pro4tech-cell' align='center'>
                                <div>
                                  <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-active-light-primary btn-custom'
                                    aria-describedby={`table-actions-${index}`}
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                      let newPopoverTableActionsAnchorEl = [...popoverTableActionsAnchorEl]
                                      newPopoverTableActionsAnchorEl[index] = event.currentTarget
                                      setPopoverTableActionsAnchorEl(newPopoverTableActionsAnchorEl)
                                    }}
                                  >
                                    <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-muted svg-icon-2hx' />
                                  </button>
                                  <Popover
                                    id={`table-actions-${index}`}
                                    open={Boolean(popoverTableActionsAnchorEl[index])}
                                    anchorEl={popoverTableActionsAnchorEl[index]}
                                    onClose={() => {
                                      let newPopoverTableActionsAnchorEl = [...popoverTableActionsAnchorEl]
                                      newPopoverTableActionsAnchorEl[index] = null
                                      setPopoverTableActionsAnchorEl(newPopoverTableActionsAnchorEl)
                                    }}
                                    onClick={() => {
                                      let newPopoverTableActionsAnchorEl = [...popoverTableActionsAnchorEl]
                                      newPopoverTableActionsAnchorEl[index] = null
                                      setPopoverTableActionsAnchorEl(newPopoverTableActionsAnchorEl)
                                    }}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    className='popover-table-actions'
                                  >
                                    {/* begin::Menu */}
                                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px d-block border border-3">
                                      {/* begin::Menu item */}
                                      {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Design_Sugestão de Compras_Gerenciamento de Looks_Visualizar'])) && (
                                        <div className="menu-item px-3">
                                          <a
                                            href=''
                                            className="menu-link px-5"
                                            onClick={(e) => {
                                              e.preventDefault()

                                              navigate('/estilo/look/visualizar', {
                                                state: {
                                                  idComposicao: row.Id,
                                                  visualizar: true,
                                                  title: "Visualizar"
                                                }
                                              })
                                            }}
                                          >
                                            <span className="menu-icon" data-kt-element="icon">
                                              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
                                            </span>
                                            <span className="menu-title">Visualizar</span>
                                          </a>
                                        </div>
                                      )}
                                      {/* end::Menu item */}

                                      {/* begin::Menu item */}
                                      {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Design_Sugestão de Compras_Gerenciamento de Looks_Editar'])) && (
                                        <div className="menu-item px-3">
                                          <a
                                            href=''
                                            className="menu-link px-5"
                                            onClick={(e) => {
                                              e.preventDefault()

                                              navigate('/estilo/look/editar', {
                                                state: {
                                                  idComposicao: row.Id,
                                                  visualizar: false,
                                                  title: "Editar"
                                                }
                                              })
                                            }}
                                          >
                                            <span className="menu-icon" data-kt-element="icon">
                                              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                                            </span>
                                            <span className="menu-title">Editar</span>
                                          </a>
                                        </div>
                                      )}
                                      {/* end::Menu item */}

                                      {/* begin::Menu item */}
                                      {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Design_Sugestão de Compras_Gerenciamento de Looks_Ativar/Desativar'])) && (
                                        <div className="menu-item px-3">
                                          <a
                                            href=''
                                            className="menu-link px-5"
                                            onClick={(e) => {
                                              e.preventDefault()

                                              alert.createDialog({
                                                html: `Realmente deseja ${ativo ? "desativar" : "ativar"} esse look?`,
                                                confirmAction: async () => {
                                                  httpClient.request({
                                                    method: RequestMethod.PUT,
                                                    endpoint: '/Estilo/ComposicaoLook/AtivarDesativar',
                                                    data: {
                                                      Id: row.Id
                                                    }
                                                  }).then((response) => {
                                                    if (response.success) obterDadosTabela()
                                                  })
                                                }
                                              })
                                            }}
                                          >
                                            <span className="menu-icon" data-kt-element="icon">
                                              <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-2' />
                                            </span>
                                            <span className="menu-title">{ativo ? "Desativar" : "Ativar"}</span>
                                          </a>
                                        </div>
                                      )}
                                      {/* end::Menu item */}

                                    </div>
                                    {/* end::Menu */}
                                  </Popover>
                                </div>

                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {emptyRows === 0 && (
                          <TableRow className='table-pro4tech-row'>
                            <TableCell className='table-pro4tech-cell text-center' colSpan={columns.length + 1}>
                              Nenhum registro encontrado
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className='table-pro4tech-footer'
                    labelRowsPerPage='linhas por página'
                    rowsPerPageOptions={[10, 25]}
                    component='div'
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { Looks }
