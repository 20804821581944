/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { PerfisPage } from './perfis/PerfisPage'
import { UsuariosPage } from './usuarios/UsuariosPage'
import { useAuth } from '../../modules/auth'
import { EnumPermissoes, EnumRoles } from '../../modules/services/core/_enums'
import { PromptsPage } from './prompts/PromptsPage'
import { AreasPage } from './areas/AreasPage'
import { SubAreasPage } from './subAreas/SubAreasPage'
import { AgentesPage } from './agentes/AgentesPage'
import { NiveisPage } from './niveis/NiveisPage'

const AdministracaoPage: FC = () => {
    const { auth } = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes

    return (
        <Routes>
            <Route element={<Outlet />} >
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Perfis_Visualizar)) && (
                    <Route
                        path='perfis/*'
                        element={
                            <>
                                <PerfisPage />
                            </>
                        }
                    />
                )}
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Usuários_Visualizar)) && (
                    <Route
                        path='usuarios/*'
                        element={
                            <>
                                <UsuariosPage />
                            </>
                        }
                    />
                )}
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Áreas_Visualizar)) && (
                    <Route
                        path='area/*'
                        element={
                            <>
                                <AreasPage />
                            </>
                        }
                    />
                )}
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Subáreas_Visualizar)) && (
                    <Route
                        path='subarea/*'
                        element={
                            <>
                                <SubAreasPage />
                            </>
                        }
                    />
                )}
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Curadoria_Agentes_Gerenciamento de Agentes_Visualizar'])) && (
                    <Route
                        path='agente/*'
                        element={
                            <>
                                <AgentesPage />
                            </>
                        }
                    />
                )}
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Prompts_Visualizar)) && (
                    <Route
                        path='prompts/*'
                        element={
                            <>
                                <PromptsPage />
                            </>
                        }
                    />
                )}
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Administração_Portal_Níveis_Visualizar'])) && (
                    <Route
                        path='niveis/*'
                        element={
                            <>
                                <NiveisPage />
                            </>
                        }
                    />
                )}
            </Route>
        </Routes>
    )
}

export { AdministracaoPage }
