import React from 'react';
import { useEffect, useState } from 'react';
import { useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultValidationSchemas, initialValues } from './helpers/LookHelper';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { ICategoriasLookModel, IProdutosModel, IProdutosSelecionadosModel } from '../../../../modules/services/core/_models';
import '../../../../../styles/looks.css';
import { ModalVisualizarLook } from './ModalVisualizarLook';
import { ModalSalvarLook } from './ModalSalvarLook';

interface Dados {
  idComposicao: string;
  tipo: number;
  visualizar: boolean;
  title: string;
}

const Look = () => {
  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as Dados;
  const informacao: Dados = state;
  const { setBlocking } = useBlockUI();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSalvar, setIsModalOpenSalvar] = useState(false);
  const [produtos, setProdutos] = useState<IProdutosModel[]>([]);
  const [categorias, setCategorias] = useState<ICategoriasLookModel[]>([]);
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState<number[]>([]);
  const [produtosSelecionados, setProdutosSelecionados] = useState<IProdutosSelecionadosModel[]>([]);
  const [termoPesquisa, setTermoPesquisa] = useState<string>('');
  const [titulo, setTitulo] = useState<string>('');

  const obterComposicao = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Estilo/ComposicaoLook/Obter',
      blockFree: true,
      queryObject: {
        IdComposicaoLook: informacao.idComposicao
      }
    })

    if (response.success && response.payload) {
      setProdutosSelecionados(response.payload.composicaoProduto)
      setTitulo(response.payload.composicao)
      setCategoriasSelecionadas(response.payload.categorias)
    }

  }

  const obterCategorias = async () => {
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Estilo/Produto/ObterCategoriasLook',
        blockFree: true,
      })
      .then((response) => {
        if (response.success && response.payload) {
          setCategorias(response.payload.data);
        }
      });
  };

  const obterProdutos = async (categorias: number[], texto: string) => {
    const queryObject = {
      idSubGrupo: categorias.join(','),
      texto: texto,
    };

    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Estilo/Produto/ObterProdutos',
        blockFree: true,
        queryObject: queryObject,
      })
      .then((response) => {
        if (response.success && response.payload) {
          // Mapeando os produtos para o formato desejado
          const produtosFormatados = response.payload.data.map((produto: { CodigoSKU: any; CategoriaNome: any; NomeProduto: any; Imagem: any; }) => ({
            id: produto.CodigoSKU, // Mapeia CodigoSKU para id
            categoria: produto.CategoriaNome, // Mapeia categoriaNome para categoria
            nome: produto.NomeProduto, // Mapeia NomeProduto para nome
            imagem: produto.Imagem, // Mapeia Imagem para imagem
            isPrincipal: false, // Define isPrincipal como false
          }));

          // Atualiza o estado com os produtos formatados
          setProdutos(produtosFormatados);
        }
      });
  };

  const obterDados = async () => {
    setBlocking(true);
    if (informacao && informacao.idComposicao) await obterComposicao()
    await obterCategorias();
    setBlocking(false);
  };

  const toggleCategoria = (id: number) => {
    const novaSelecao = categoriasSelecionadas.includes(id)
      ? categoriasSelecionadas.filter((item) => item !== id)
      : [...categoriasSelecionadas, id];

    setCategoriasSelecionadas(novaSelecao);
  };

  const handlePesquisa = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermoPesquisa(e.target.value);
  };

  const toggleProduto = (produto: IProdutosModel) => {
    const jaSelecionado = produtosSelecionados.find((p) => p.Id === produto.id);
    if (jaSelecionado) {
      const novosProdutos = produtosSelecionados.filter((p) => p.Id !== produto.id);

      // Atualizar o `isPrincipal` se o produto principal for removido
      if (jaSelecionado.IsPrincipal && novosProdutos.length > 0) {
        novosProdutos[0].IsPrincipal = true;
      }

      setProdutosSelecionados(novosProdutos);
    } else {
      let novosProdutos = [...produtosSelecionados];
      const novoProduto: IProdutosSelecionadosModel = {
        Categoria: produto.categoria,
        Id: produto.id,
        Imagem: produto.imagem,
        Nome: produto.nome,
        IsPrincipal: produtosSelecionados.length === 0, // Define como principal se for o único
        Prioridade: produtosSelecionados.length + 1,
      };

      novosProdutos.push(novoProduto);

      // Ajusta o `isPrincipal` caso haja apenas um produto na lista
      if (novosProdutos.length === 1) {
        novosProdutos[0].IsPrincipal = true;
      }

      setProdutosSelecionados(novosProdutos);
    }
  };

  const limpar = () => {
    setProdutosSelecionados([])
    setCategoriasSelecionadas([])
  }

  const atualizarPrioridades = (novosProdutos: IProdutosSelecionadosModel[]) => {
    const produtosOrdenados = novosProdutos.map((produto, index) => ({
      ...produto,
      prioridade: produto.IsPrincipal ? 0 : index + 1,
    }));
    setProdutosSelecionados(produtosOrdenados);
  };

  useEffect(() => {
    obterDados();
  }, []);

  useEffect(() => {
    obterProdutos(categoriasSelecionadas, termoPesquisa);
  }, [categoriasSelecionadas, termoPesquisa]);


  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="card-title m-0 d-flex flex-wrap align-items-start justify-content-between gap-3">
            <div className="d-flex flex-wrap gap-2">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Visualizar
              </button>
              <button
                type="button"
                onClick={() => setIsModalOpenSalvar(true)}
                className="btn btn-primary"
              >
                Salvar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => limpar()}
                disabled={informacao.title === 'Visualizar' && informacao.visualizar === true}
              >
                Limpar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => navigate(-1)}
              >
                Voltar
              </button>
            </div>
          </div>
          <div className="search-container">
            <input
              type="text"
              className="form-control"
              placeholder="Pesquisar..."
              value={termoPesquisa}
              onChange={handlePesquisa}
              style={{ maxWidth: '300px' }}
            />
          </div>
        </div>

        <div className="card-body d-flex">
          {/* Sidebar com categorias */}
          <div
            style={{
              flex: '0 0 150px', // Largura fixa da sidebar
              marginRight: '1rem',
              maxHeight: '500px',
              overflowY: 'auto', // Permite rolagem na sidebar
              position: 'sticky', // Fixa a posição da sidebar
              top: '0', // Garante que a sidebar permaneça visível ao rolar a página
              zIndex: 1, // Mantém a sidebar acima de outros elementos
            }}
          >
            <h5>Filtre por:</h5>
            <h6>Categorias</h6>
            <div>
              {categorias.map((categoria) => (
                <div key={categoria.Id} className="form-check" style={{ marginTop: '1rem' }}>
                  <input
                    type="checkbox"
                    style={{ borderRadius: '100px' }}
                    id={`categoria-${categoria.Id}`}
                    value={categoria.Id}
                    className="form-check-input"
                    onChange={() => toggleCategoria(categoria.Id)}
                    checked={categoriasSelecionadas.includes(categoria.Id)}
                  />
                  <label htmlFor={`categoria-${categoria.Id}`} className="form-check-label">
                    {categoria.Descricao}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Lista de produtos */}
          <div style={{ flex: 'flex-wrap', marginLeft: '20px' }}>
            <div
              className="product-list"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                justifyContent: 'flex-start',
              }}
            >
              {produtos.map((produto) => (
                <div
                  key={produto.id}
                  style={{
                    textAlign: 'center',
                    maxWidth: '300px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column', // Centraliza os itens na vertical
                    alignItems: 'center', // Centraliza o conteúdo
                  }}
                >
                  <img
                    src={produto.imagem}
                    alt={produto.nome}
                    style={{
                      maxWidth: '300px', // Tamanho máximo
                      width: '100%',
                      height: '400px',
                      objectFit: 'cover',
                      borderRadius: '5px',
                      marginBottom: '10px',
                    }}
                  />
                  <div style={{ wordWrap: 'break-word', maxWidth: '300px' }}>
                    <span>{produto.nome}</span>
                    <input
                      disabled={informacao.title === 'Visualizar' && informacao.visualizar === true}
                      type="checkbox"
                      className="form-check-input"
                      style={{ marginLeft: '1rem', borderRadius: '100px' }}
                      onChange={() => toggleProduto(produto)}
                      checked={produtosSelecionados.some((p) => p.Id === produto.id)}
                    />
                  </div>
                </div>
              ))}
              {produtos.length === 0 && (
                <h2>Selecione uma categoria</h2>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ModalVisualizarLook
          titulo={titulo}
          informacao={informacao}
          onUpdateProdutos={atualizarPrioridades}
          produtos={produtosSelecionados}
          show={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {isModalOpenSalvar && (
        <ModalSalvarLook
          titulo={titulo}
          informacao={informacao}
          onUpdateProdutos={atualizarPrioridades}
          produtos={produtosSelecionados}
          show={isModalOpenSalvar}
          onClose={() => setIsModalOpenSalvar(false)}
        />
      )}
    </>
  );
};

export { Look };
