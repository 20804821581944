import React, { useState } from 'react';
import '../../../../../styles/looks.css';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';

interface ModalJustificativaListaProps {
    show: boolean;
    onClose: () => void;
    justificativa: string;
    nome: string;
}

const ModalJustificativaListaVisualizacao: React.FC<ModalJustificativaListaProps> = ({ show, onClose, justificativa, nome }) => {
    const alert = useAlert();
    const httpClient = useHttpClient();

    return (
        <Modal
            className="bg-body"
            id="kt_header_search_modal"
            aria-hidden="true"
            dialogClassName="modal-fullscreen h-auto custom-modal-size"
            show={show}
            onHide={onClose}
            centered
        >
            <div className="card mb-5 mb-xl-10">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5>Justificativa do {nome}</h5>
                    <button className="btn btn-icon btn-sm btn-light-primary ms-2" onClick={onClose}>
                        <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                    </button>
                </div>
                <div className="card-body">
                    <textarea
                        className="form-control"
                        rows={5}
                        value={justificativa}
                        disabled={true}
                    />
                </div>
                <div className="card-footer py-4 d-flex justify-content-end">
                    <button type="button" className="btn btn-secondary me-2" onClick={onClose}>
                        Voltar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export { ModalJustificativaListaVisualizacao };
