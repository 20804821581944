import * as Yup from 'yup'
import { IAgenteModel } from '../../../../../modules/services/core/_models'

const initialValues: IAgenteModel = {
  Id: 0,
  AgenteNivel1Id: 0,
  AgenteNivelFilhoId: 0,
  PalavraChave: '',
  Assunto: '',
  Descricao: '',
  Instrucoes: '',
  Curadores: [],
  QuebraGelo: '',
  Arquivos: []
}

const defaultValidationSchemas = Yup.object().shape({
  AgenteNivelId: Yup.number().moreThan(0, 'O nível é obrigatório'),
  Assunto: Yup.string().required('O Assunto é obrigatório'),
  PalavraChave: Yup.string().required('A palavras-chave é obrigatório'),
  Instrucoes: Yup.string().required('As instruções são obrigatórias'),
  Curadores: Yup.array().min(1, 'É necessário pelo menos um curador'),
  Descricao: Yup.string().required('A Descrição é obrigatória'),
  QuebraGelo: Yup.string().required('O Quebra-gelos é obrigatório'),
})

export { defaultValidationSchemas, initialValues }
