/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../../modules/services/core/Breadcrumb'
import { FeedBackAgentes } from './components/FeedbackAgentes'
import { EnumPermissoes, EnumRoles } from '../../../modules/services/core/_enums'
import { useAuth } from '../../../modules/auth'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Curadoria',
    path: '',
  },
]

const wizardsBreadCrumbsUsuarios: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Curadoria',
    path: '',
  },
  {
    titulo: 'Dúvidas Não Resolvidas',
    path: '/curadoria/duvidasnaoresolvidas/lista',
  }
]

const FeedbackAgentesPage: FC = () => {
    const {auth} = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
  
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='lista'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbs}>Dúvidas Não Resolvidas</BreadcrumbLayout>
                <FeedBackAgentes />
              </>
            }
          />

          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route index element={<Navigate to='/curadoria/duvidasnaoresolvidas/lista' />} />
        </Route>
      </Routes>
    )
}

export {FeedbackAgentesPage}
