import * as React from 'react';
import { Button } from '@mui/material';
import { KTSVG } from '../../../../../_metronic/helpers'; // Supondo que você tenha o KTSVG
import '../../../../../styles/agentes.css'; // Supondo que você tenha este arquivo de estilos
import { useNavigate } from 'react-router-dom';
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';
import { IUsuarioModel } from '../../../../modules/services/core/_models';
import '../../../../../styles/agentes.css';

interface CardAgentesVersaoProps {
    numero: number;
    nome: string;
    dataCriacao: string;
    data: string;
    hora: string;
    responsavel: IUsuarioModel;
    id: number;
    listNumero: number[];
    onUpdate: () => void; // Nova prop para atualizar a tabela
}

const CardAgentesVersao: React.FC<CardAgentesVersaoProps> = ({ numero, nome, dataCriacao, data, hora, responsavel, id, onUpdate, listNumero }) => {
    const httpClient = useHttpClient()
    const navigate = useNavigate()
    const alert = useAlert()

    const maiorNumero = Math.max(...listNumero);

    return (
        <div className="card mb-5 shadow-sm rounded-lg agente-card">
            <div className="card-body d-flex justify-content-between">
                <div className="text-left">
                    <p className='agentes-card-subtitle'>Versão {numero === maiorNumero ? "atual" : numero} </p>
                    <p className="agentes-card-title">{nome}</p>
                    {data != "" && (<p className='agentes-card-text'>Última atualização: {data} às {hora}</p>)}
                    {responsavel.Nome != "" && (<p className='agentes-card-text'>Atualizado por: {responsavel.Nome}</p>)}
                </div>
                <div className="d-flex gap-12 justify-content-end row margin-button button-lista-card">
                    <div className='col-lg-2 fv-row'>
                        <Button
                            className='btn btn-primary btn-icon'
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/administracao/agente/visualizar', {
                                    state: {
                                        idVersao: id,
                                        visualizar: true,
                                        title: "Visualizar"
                                    }
                                })
                            }}>
                            <i className="bi bi-eye" />
                        </Button>
                    </div>
                    <div className='col-lg-2 fv-row'>
                        <Button
                            className='btn btn-primary btn-icon'
                            onClick={(e) => {
                                e.preventDefault()

                                alert.createDialog({
                                    html: `Realmente deseja restaurar essa versão?`,
                                    confirmAction: async () => {
                                        httpClient.request({
                                            method: RequestMethod.POST,
                                            endpoint: '/Administracao/Agente/Restaurar',
                                            data: id
                                        }).then((response) => {
                                            if (response.success) onUpdate();
                                        });
                                    }
                                })
                            }}>
                            <i className="bi bi-arrow-repeat" />
                        </Button>
                    </div>
                    {/* <div className='col-lg-2 fv-row' >
                        <Button
                            className='btn btn-primary btn-icon'
                            onClick={(e) => {
                                e.preventDefault()

                                alert.createDialog({
                                    html: `Realmente deseja deletar a versão deste agente?`,
                                    confirmAction: async () => {
                                        httpClient.request({
                                            method: RequestMethod.DELETE,
                                            endpoint: '/Administracao/Agente/DeletarVersao',
                                            data: id
                                        }).then((response) => {
                                            if (response.success) onUpdate();
                                        });
                                    }
                                })
                            }}>
                            <i className="bi bi-trash" />
                        </Button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export { CardAgentesVersao };
