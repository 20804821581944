import { useEffect } from 'react';
import { useAlert, useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultValidationSchemas, initialValues } from './helpers/NiveisHelper';

interface Dados {
  IdNivel: number;
  visualizar: boolean;
  title: string;
}

const Nivel = () => {
  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as Dados;
  const informacao: Dados = state;
  const alert = useAlert()
  const { setBlocking } = useBlockUI();

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values) => {

      const hasEmptyName = formik.values.Niveis.some(x => !x.Nome?.trim());
  
      if (hasEmptyName) {
        alert.createMessage({
          html: 'O nome do nível não pode estar vazio.',
          icon: 'warning',
        });
        return;
      }

      await httpClient.request({
        method: informacao && informacao.IdNivel ? RequestMethod.PUT : RequestMethod.POST,
        endpoint: `/Administracao/AgenteNivel/${informacao && informacao.IdNivel ? 'Editar' : 'Adicionar'}`,
        data: values,
        ifSuccessRedirectTo: '/administracao/niveis/lista',
      });
    },
  });

  const adicionarNivel = () => {
    if (formik.values.Niveis.length < 4) {
      const novoNivel = { Id: null, Nome: '', IdPai: null };
      formik.setFieldValue('Niveis', [...formik.values.Niveis, novoNivel]);
    }
  };

  const removerNivel = (index: number) => {
    if (formik.values.Niveis.length > 1) {
      const novosNiveis = formik.values.Niveis.filter((_, i) => i !== index);
      formik.setFieldValue('Niveis', novosNiveis);
    }
  };

  useEffect(() => {
    const obterNivel= async () => {
      const response = await httpClient.request({
        method: RequestMethod.GET,
        endpoint: '/Administracao/AgenteNivel/Obter',
        blockFree: true,
        queryObject: { id: informacao.IdNivel },
      });
      if (response.success && response.payload) {
        formik.setValues(response.payload);
      }
    };

    const obterDados = async () => {
      setBlocking(true);
      if (informacao && informacao.IdNivel) await obterNivel();
      setBlocking(false);
    };
    obterDados();
  }, [informacao]);

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">{informacao.title} Nível</h3>
        </div>
      </div>

      <div className="card-body">
        <FormikProvider value={formik}>
          <div className="w-100">
            <form noValidate>
              {formik.values.Niveis.map((nivel, index) => (
                <div className="row" key={index}>
                  <div className="col-lg-10 fv-row mb-5">
                    <label className="form-label required">Nome Nível {index + 1}</label>
                    <Field
                      name={`Niveis[${index}].Nome`}
                      placeholder={`Nome do Nível ${index + 1}`}
                      className="form-control form-control-lg form-control-solid"
                      disabled={informacao && informacao.IdNivel && informacao.visualizar}
                    />
                    <div className="text-danger mt-2">
                      <ErrorMessage name={`Niveis[${index}].Nome`} />
                    </div>
                  </div>
                  {index === formik.values.Niveis.length - 1 && (
                    <div className="col-lg-2 d-flex align-items-center gap-4">
                      {(formik.values.Niveis.length < 4 && !(informacao && informacao.IdNivel && informacao.visualizar)) && (
                        <button type="button" className="btn btn-primary bi-plus-lg" onClick={adicionarNivel}></button>
                      )}
                      {(formik.values.Niveis.length > 1 && !(informacao && informacao.IdNivel && informacao.visualizar)) && (
                        <button type="button" className="btn btn-danger bi-dash-lg" onClick={() => removerNivel(index)}></button>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </form>
          </div>
        </FormikProvider>
      </div>

      <div className="card-footer py-4 d-flex justify-content-between">
        <button type="button" className="btn btn-secondary" onClick={() => navigate(-1)}>
          Voltar
        </button>
        {informacao.title && informacao.title !== 'Visualizar' && (
          <button
            type="button"
            onClick={() => formik.handleSubmit()}
            className={`btn btn-lg btn-primary ${informacao && informacao.visualizar ? 'd-none' : ''}`}
          >
            {informacao.title}
          </button>
        )}
      </div>
    </div>
  );
};

export { Nivel };
