/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useBlockUI, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { defaultValidationSchemas, initialValues } from './helpers/ProdutoHelper'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import Switch from 'react-switch'
import { ICategoriasModel, IGruposModel, IPerfilModel } from '../../../../modules/services/core/_models'

interface Dados {
  idProduto: string
  visualizar: boolean
  title: string
}

const Produto = () => {
  const httpClient = useHttpClient()
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as Dados
  const informacao: Dados = state
  const { setBlocking } = useBlockUI()
  const [categorias, setCategorias] = useState<ICategoriasModel[]>([]);
  const [grupos, setGrupos] = useState<IGruposModel[]>([]);

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values) => {

      const data = {
          CodigoSKU:values.CodigoSKU,
          NomeProduto: values.NomeProduto,
          GrupoID: values.Grupo,
          SubGrupoID: values.SubGrupo,
          Estoque: values.Estoque,
          Imagem: values.Imagem,
          Ativo: values.Ativo
      }

      await httpClient.request({
        method: (informacao && informacao.idProduto) ? RequestMethod.PUT : RequestMethod.POST,
        endpoint: `/Estilo/Produto/${(informacao && informacao.idProduto) ? "Editar" : "Adicionar"}`,
        data: data,
        ifSuccessRedirectTo: '/estilo/produto/lista'
      })
    },
  })

  const obterUsuario = async () => {

    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Estilo/Produto/Obter',
      blockFree: true,
      queryObject: {
        idProduto: informacao.idProduto
      }
    })

    if (response.success && response.payload) {
      formik.setValues(response.payload)
      formik.setFieldValue('SubGrupo', response.payload.SubGrupoID)
      formik.setFieldValue('Grupo', response.payload.GrupoID)
    }

  }

  const obterCategorias = async () => {
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Estilo/Produto/ObterCategorias',
        blockFree: true,
      })
      .then((response) => {
        if (response.success && response.payload) {
          setCategorias(response.payload.data);
        }
      });
  };

  const obterGrupos = async () => {
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Estilo/Produto/ObterGrupos',
        blockFree: true,
      })
      .then((response) => {
        if (response.success && response.payload) {
          setGrupos(response.payload.data);
        }
      });
  };

  const obterDados = async () => {

    setBlocking(true)

    if (informacao && informacao.idProduto) await obterUsuario()

    await obterCategorias()
    await obterGrupos()

    setBlocking(false)

  }

  useEffect(() => {
    obterDados()
  }, [informacao])

  const handleSwitchChange = (checked: boolean) => {
    formik.setFieldValue("Ativo", checked);
  };

  const handleSwitchChangeEstoque = (checked: boolean) => {
    formik.setFieldValue("Estoque", checked);
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>

        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{informacao.title} Usuário</h3>
          </div>
        </div>

        <div className='card-body'>
          <FormikProvider value={formik}>
            <div className='w-100'>
              <form noValidate >
                <div className="row">
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>Descrição</label>
                    <Field
                      name='NomeProduto'
                      placeholder='Descrição do produto'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.NomeProduto}
                      disabled={informacao && informacao.idProduto && informacao.visualizar}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='NomeProduto' />
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>SKU</label>
                    <Field
                      name='CodigoSKU'
                      placeholder='Código SKU do produto'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.CodigoSKU}
                      disabled={informacao && informacao.idProduto}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='CodigoSKU' />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className='col-lg-12 fv-row mb-5'>
                    <label className='form-label required'>Imagem(URL)</label>
                    <Field
                      name='Imagem'
                      placeholder='URL da imagem'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.Imagem}
                      disabled={informacao && informacao.idProduto && informacao.visualizar}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Imagem' />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>Grupo</label>
                    <Select
                      placeholder='Selecione...'
                      className='react-select-styled react-select-lg react-select-solid'
                      classNamePrefix='react-select'
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      options={grupos.map((data) => ({
                        value: data.Id,
                        label: data.NomeGrupo
                      }))}
                      isDisabled={informacao && informacao.visualizar}
                      value={
                        formik.values.Grupo
                          ? { value: formik.values.Grupo, label: grupos.find(p => p.Id === formik.values.Grupo)?.NomeGrupo }
                          : null
                      }
                      isSearchable
                      onChange={(selectedOption) => formik.setFieldValue('Grupo', selectedOption?.value || null)}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Grupo' />
                    </div>
                  </div>

                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>SubGrupo</label>
                    <Select
                      placeholder='Selecione...'
                      className='react-select-styled react-select-lg react-select-solid'
                      classNamePrefix='react-select'
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      options={categorias.map((data) => ({
                        value: data.Id, // ID do subgrupo
                        label: data.NomeSubGrupo // Nome do subgrupo
                      }))}
                      isDisabled={informacao && informacao.visualizar}
                      value={
                        formik.values.SubGrupo
                          ? { value: formik.values.SubGrupo, label: categorias.find(p => p.Id === formik.values.SubGrupo)?.NomeSubGrupo }
                          : null
                      }
                      isSearchable
                      onChange={(selectedOption) => formik.setFieldValue('SubGrupo', selectedOption?.value || null)}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='SubGrupo' />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>Estoque</label>
                    <br/>
                    <Switch
                        checked={formik.values.Estoque}
                        onChange={handleSwitchChangeEstoque}
                        onColor="#0d6efd"
                        offColor="#ccc"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        disabled={informacao && informacao.visualizar}
                      />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Estoque' />
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>Ativo</label>
                    <br/>
                    <Switch
                        checked={formik.values.Ativo}
                        onChange={handleSwitchChange}
                        onColor="#0d6efd"
                        offColor="#ccc"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        disabled={informacao && informacao.visualizar}
                      />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Ativo' />
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </FormikProvider>
        </div>
        <div className="card-footer py-4 d-flex justify-content-between">
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => navigate(-1)}
          >
            Voltar
          </button>
          {informacao.title && informacao.title !== 'Visualizar' && (
            <button
              type='button'
              onClick={() => formik.handleSubmit()}
              className={`btn btn-lg btn-primary ${informacao && informacao.visualizar ? "d-none" : ""}`}
            >
              {informacao.title}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export { Produto }

