import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import { TablePerfis } from './partials/TablePerfis'
import { useAuth } from '../../../../modules/auth';
import { EnumPermissoes, EnumRoles } from '../../../../modules/services/core/_enums';

const Perfis = () => {
  const navigate = useNavigate();
  const {auth} = useAuth()
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes

  return (
    <>

    <div className='card mb-xl-10'>
      <div
        className='card-header'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Perfis</h3>
        </div>
        <div className='card-toolbar'>
        {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Administração_Portal_Perfis_Adicionar'])) && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => { 
              navigate('/administracao/perfis/adicionar',
              { state: { 
                Acao: "Adicionar"
              }}
              )
            }}
          >
            <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-1" />
            Adicionar
          </button>                         
        )}     
        </div>
      </div>

      <div className='card-body'>
        <TablePerfis />
      </div>
    </div>
    </>
  )
}

export {Perfis}
