/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../../modules/services/core/Breadcrumb'
import { Agentes } from './components/Agentes'
import { Agente } from './components/Agente'
import { EnumPermissoes, EnumRoles } from '../../../modules/services/core/_enums'
import { useAuth } from '../../../modules/auth'
import { AgentesVersao } from './components/AgentesVersao'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Administração',
    path: '',
  },
]

const wizardsBreadCrumbsUsuarios: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Administração',
    path: '',
  },
  {
    titulo: 'Agente',
    path: '/administracao/agente/lista',
  }
]

const AgentesPage: FC = () => {
    const {auth} = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
  
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='lista'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbs}>Agentes</BreadcrumbLayout>
                <Agentes />
              </>
            }
          />

          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Curadoria_Agentes_Gerenciamento de Agentes_Visualizar'])) && (
              <Route
                path='adicionar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Adicionar Agente</BreadcrumbLayout>
                    <Agente />
                  </>
                }
              /> 
          )}
          
          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Curadoria_Agentes_Gerenciamento de Agentes_Editar'])) && (
              <Route
                path='editar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Editar Agente</BreadcrumbLayout>
                    <Agente />
                  </>
                }
              /> 
          )}      

          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Curadoria_Agentes_Gerenciamento de Agentes_Editar'])) && (
              <Route
                path='listarVersao'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Versão do agente</BreadcrumbLayout>
                    <AgentesVersao />
                  </>
                }
              /> 
          )}      

          <Route
            path='visualizar'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Visualizar Agente</BreadcrumbLayout>
                <Agente />
              </>
            }
          /> 

          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route index element={<Navigate to='/administracao/agente/lista' />} />
        </Route>
      </Routes>
    )
}

export {AgentesPage}
