/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useAlert, useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPermissoes, EnumRoles, EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums';
import { useAuth } from '../../../../modules/auth'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Popover } from '@mui/material';
import { TablePendentes } from './TablePendentes';
import { TableResolvidos } from './TableResolvidos';
import { ModalJustificativaLista } from './ModalJustificativaLista';
import { TableAgentesNaoEncontrados } from './TableAgentesNaoEncontrados';

const FeedBackAgentes = () => {
  const httpClient = useHttpClient()
  const alert = useAlert()
  const blockUI = useBlockUI()
  const navigate = useNavigate()
  const [search, setSearch] = React.useState<string>('')
  const [ativo, setAtivo] = React.useState(1)
  const { auth } = useAuth()
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const [showModalPendente, setShowModalPendente] = React.useState<boolean>(false)

  const onKeyDownBuscar = (search: string) => {
    setSearch(search);
  }

  const handleSelectionChange = (ids: number[]) => {
    setSelectedIds(ids);
  };

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Dúvidas Não Resolvidas</h3>
          </div>
          <div className='card-toolbar'>
            {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Curadoria_Agentes_Dúvidas Não Resolvidas_Concluir'])) && (ativo === 1 || ativo === 3) && (
              <button
                className='btn btn-primary'
                onClick={(e) => {
                  setShowModalPendente(true)
                }}
                disabled={(ativo === 1 && selectedIds.length <= 1) || (ativo === 3 && selectedIds.length <= 0)}
              >
                Concluir
              </button>
            )}
          </div>
        </div>
        <div className='card-body'>
          <form className='form'>
            <div>
              <div className='row' >
                {/* end::Input group */}
                {/* begin::Input group */}
                {/* <div className='mb-2 col-lg-4'>
                  <label className='fw-bold text-pro4tech'>Pesquisar (Nome)</label>
                  <input
                    type='text'
                    onChange={e => onKeyDownBuscar(e.target.value)}
                    placeholder='Pesquisar (Nome)'
                    className={`form-control form-control-lg form-control-solid`}
                    value={search}
                  ></input>
                </div> */}
                {/* end::Input group */}
              </div>
              <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_4"
                    onClick={() => setAtivo(1)}
                  >
                    Pendentes
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_5"
                    onClick={() => setAtivo(2)}
                  >
                    Resolvidos
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_5"
                    onClick={() => setAtivo(3)}
                  >
                    Agentes não encontrados
                  </a>
                </li>
              </ul>
              {ativo === 1 && (
                <TablePendentes pendente={true} onSelectionChange={handleSelectionChange} />
              )}
              {ativo === 2 && (
                <TableResolvidos pendente={false} />
              )}
              {ativo === 3 && (
                <TableAgentesNaoEncontrados  pendente={true} onSelectionChange={handleSelectionChange} />
              )}
            </div>
          </form>
        </div>
      </div>
      {showModalPendente && (
        <ModalJustificativaLista ids={selectedIds} onClose={() => setShowModalPendente(false)} show={showModalPendente} />
      )}
    </>
  )
}

export { FeedBackAgentes }
