import * as Yup from 'yup'
import { IPerfilModel } from '../../../../../modules/services/core/_models'

const defaultValidationSchemas = Yup.object().shape({
  Nome: Yup.string()
  .max(256, 'O nome deve ter no máximo 256 caracteres')
  .required('O nome é obrigatório')
})

const defaultPerfil: IPerfilModel = {
  Id: 0,
  Nome: '',
  Permissoes: []
}

export {defaultValidationSchemas, defaultPerfil}