/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import './AuthLayout.css'
import { useThemeMode } from '../../../_metronic/partials'
import { ThemeModeComponent } from '../../../_metronic/assets/ts/layout'

const AuthLayout = () => {
  const { mode } = useThemeMode()
  const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

  const currentMode = mode === 'system' ? systemMode : mode

  return (
    <div className='d-flex flex-column flex-root min-vh-100 layout-background'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/pro4tech/pro4tech-login.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        minHeight: '100vh',
      }}>
      <div className='d-flex flex-column flex-column-fluid flex-lg-row justify-content-center justify-content-xl-between'>
        <div className='d-none d-xl-flex flex-row w-50'>
          <div className='d-flex flex-center flex-column'>
            <div className='d-flex px-20'>
              <div className='d-flex flex-column'>
                <div className='text-white fw-bolder display-6 display-lg-5'>
                  {/* <p>Bem-vindo(a) ao Portal Debrum!</p> */}
                </div>
                <div className='text-white fw-semibold fs-3 fs-lg-2'>
                  {/* <p>Sua plataforma única para acesso aos mais novos recursos de tecnologia. Navegue com facilidade e aproveite ao máximo tudo que preparamos para você.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center w-100 w-xl-50 p-5 p-lg-20'>
          <div className='layout-login-background d-flex flex-column align-items-stretch flex-center rounded-4 shadow-lg w-100 w-md-600px p-10'>
            <div className='my-3 text-center'>
              <img
                alt='Logo'
                src={currentMode === 'dark'
                  ? toAbsoluteUrl('/media/debrum/logo-claro.png')
                  : toAbsoluteUrl('/media/debrum/logo-escuro.png')}
                className='w-100 h-auto mw-325px mw-xl-400px'
              />
            </div>
            <div className='d-flex flex-center flex-column flex-column-fluid px-lg-10'>
              <Outlet />
            </div>
            <div className='d-flex justify-content-center flex-stack px-lg-10'>
              <div className='d-flex fw-semibold text-primary fs-base gap-5'>
                <a href='/termos' target='_blank'>
                  Termos
                </a>

                <a href='/' target='_blank'>
                  Planos
                </a>

                <a href='/' target='_blank'>
                  Contato
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AuthLayout }
