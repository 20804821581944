/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useAlert, useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPermissoes, EnumRoles, EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums';
import { useAuth } from '../../../../modules/auth'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Button, Checkbox, Popover } from '@mui/material';
import { ModalMensagensAgenteNaoEncontrado } from './ModalMensagensAgenteNaoEncontrado';

interface TableProps {
    pendente: boolean;
}

interface Data {
    Id: number,
    Data: string,
    Hora: string,
    AgenteNivelNome: string,
    TituloMensagem: string,
    AgenteId: string,
}

interface Column {
    id: keyof Data
    label: string
    minWidth?: number
    align?: 'center'
    format?: (value: number) => string
}

const columns: readonly Column[] = [
    {
        id: 'Data',
        label: 'Data e Hora',
    },
    {
        id: 'AgenteNivelNome',
        label: 'Nível',
    },
    {
        id: 'TituloMensagem',
        label: 'Assunto',
    }
]

function createData(
    Id: number,
    Data: string,
    Hora: string,
    AgenteNivelNome: string,
    TituloMensagem: string,
    AgenteId: string,
): Data {
    return { Id, Data, Hora, AgenteNivelNome, TituloMensagem, AgenteId }
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
    order: Order
    orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps & { onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void, numSelected: number, rowCount: number }) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
        <TableHead>
            <TableRow className='table-pro4tech-header'>
                <TableCell
                    className='table-pro4tech-header-cell'
                    padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all items',
                        }}
                    />
                </TableCell>
                {columns.map((column) => (
                    <TableCell
                        className='table-pro4tech-header-cell'
                        key={column.id}
                        align={column.align ? 'right' : 'left'}
                    >
                        {column.label}
                    </TableCell>
                ))}
                <TableCell className='table-pro4tech-header-cell fw-bolder' align='center'>
                    AÇÕES
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const TableAgentesNaoEncontrados: React.FC<TableProps & { onSelectionChange: (selectedIds: number[]) => void }> = ({ pendente, onSelectionChange }) => {
    const httpClient = useHttpClient()
    const alert = useAlert()
    const blockUI = useBlockUI()
    const navigate = useNavigate()
    const [order, setOrder] = React.useState<Order>('asc')
    const [orderBy, setOrderBy] = React.useState<keyof Data>('Data')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [total, setTotal] = React.useState(0)
    const [rows, setRows] = React.useState<Data[]>([])
    const [selected, setSelected] = React.useState<number[]>([]);
    const { auth } = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
    const [popoverTableActionsAnchorEl, setPopoverTableActionsAnchorEl] = React.useState<(HTMLButtonElement | null)[]>([])
    const popoverTableActionsOpen = Boolean(popoverTableActionsAnchorEl)
    const [showJustificativaModal, setShowJustificativaModal] = React.useState(false);
    const [idAgenteModal, setIdAgenteModal] = React.useState("");
    const [idFeedbackModal, setIdFeedbackModal] = React.useState(0);

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => row.Id);
            setSelected(newSelecteds);
            onSelectionChange(newSelecteds);
            return;
        }
        setSelected([]);
        onSelectionChange([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [...selected];

        if (selectedIndex === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(selectedIndex, 1);
        }

        setSelected(newSelected);
        onSelectionChange(newSelected);
    };

    const resquestTable = async (
        pageRq: number,
        orderByRq: string,
        orderRq: string,
        rowsPerPageRq: number,
        search: any = null,
        block: boolean = false,
    ) => {
        let response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Administracao/Agente/ListarAgentesNaoEncontrados',
            blockFree: block,
            data: {
                Order: orderRq,
                OrderBy: orderByRq,
                Page: pageRq,
                RowsPerPage: rowsPerPageRq,
                Search: search,
                Filters: {
                    Ativo: pendente,
                }
            }
        })

        if (response.success && response.payload) {
            let newRows = response.payload.data.map((data: Data) => {
                return createData(
                    data.Id,
                    data.Data,
                    data.Hora,
                    data.AgenteNivelNome,
                    data.TituloMensagem,
                    data.AgenteId
                )
            })
            setRows([...newRows])
            setTotal(response.payload.total)

        }
    }

    React.useEffect(() => {
        obterDados()
    }, [pendente])

    const obterDados = async () => {
        blockUI.setBlocking(true)
        await obterDadosTabela()
        blockUI.setBlocking(false)
    }

    const obterDadosTabela = async () => {
        await resquestTable(page, orderBy, order, rowsPerPage)
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        resquestTable(newPage, orderBy, order, rowsPerPage)
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        resquestTable(page, orderBy, order, parseInt(event.target.value, 10))
        setPage(0)
    }

    const emptyRows = rows.length

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Paper className='table-pro4tech-border mb-0' sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table className='table-pro4tech-header' sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                numSelected={selected.length}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {rows.map((row, index) => {
                                    const isItemSelected = selected.indexOf(row.Id) !== -1;
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            className='table-pro4tech-row'
                                            hover
                                            // onClick={(event) => handleClick(event, row.Id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.Id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox" className='table-pro4tech-cell'>
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.Id)}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='left'>
                                                {row.Data} {row.Hora}
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='left'>
                                                {row.AgenteNivelNome}
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='left'>
                                                {row.TituloMensagem}
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='center'>
                                                <div>
                                                    <button
                                                        type='button'
                                                        className='btn btn-sm btn-icon btn-active-light-primary btn-custom'
                                                        aria-describedby={`table-actions-${index}`}
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            let newPopoverTableActionsAnchorEl = [...popoverTableActionsAnchorEl]
                                                            newPopoverTableActionsAnchorEl[index] = event.currentTarget
                                                            setPopoverTableActionsAnchorEl(newPopoverTableActionsAnchorEl)
                                                        }}
                                                    >
                                                        <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-muted svg-icon-2hx' />
                                                    </button>
                                                    <Popover
                                                        id={`table-actions-${index}`}
                                                        open={Boolean(popoverTableActionsAnchorEl[index])}
                                                        anchorEl={popoverTableActionsAnchorEl[index]}
                                                        onClose={() => {
                                                            let newPopoverTableActionsAnchorEl = [...popoverTableActionsAnchorEl]
                                                            newPopoverTableActionsAnchorEl[index] = null
                                                            setPopoverTableActionsAnchorEl(newPopoverTableActionsAnchorEl)
                                                        }}
                                                        onClick={() => {
                                                            let newPopoverTableActionsAnchorEl = [...popoverTableActionsAnchorEl]
                                                            newPopoverTableActionsAnchorEl[index] = null
                                                            setPopoverTableActionsAnchorEl(newPopoverTableActionsAnchorEl)
                                                        }}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        className='popover-table-actions'
                                                    >
                                                        {/* begin::Menu */}
                                                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px d-block border border-3">
                                                            
                                                            <div className="menu-item px-3">
                                                                <a
                                                                    href=''
                                                                    className="menu-link px-5"
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setIdAgenteModal(row.AgenteId)
                                                                        setIdFeedbackModal(row.Id)
                                                                        setShowJustificativaModal(true)
                                                                    }}
                                                                >
                                                                    <span className="menu-icon" data-kt-element="icon">
                                                                        <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
                                                                    </span>
                                                                    <span className="menu-title">Visualizar Mensagem</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {/* end::Menu */}
                                                    </Popover>
                                                </div>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {emptyRows === 0 && (
                                    <TableRow className='table-pro4tech-row'>
                                        <TableCell className='table-pro4tech-cell text-center' colSpan={columns.length + 2}>
                                            Nenhum registro encontrado
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className='table-pro4tech-footer'
                        labelRowsPerPage='linhas por página'
                        rowsPerPageOptions={[10, 25]}
                        component='div'
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                {showJustificativaModal && (
                    <ModalMensagensAgenteNaoEncontrado
                        show={showJustificativaModal}
                        onClose={() => setShowJustificativaModal(false)}
                        idAgente={idAgenteModal}
                        idFeedback={idFeedbackModal}
                    />
                )}
            </Box>
        </div>
    )
}

export { TableAgentesNaoEncontrados }
