/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../../modules/services/core/Breadcrumb'
import { Looks } from './components/Looks'
import { Look } from './components/Look'
import { EnumPermissoes, EnumRoles } from '../../../modules/services/core/_enums'
import { useAuth } from '../../../modules/auth'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Estilo',
    path: '',
  },
]

const wizardsBreadCrumbsUsuarios: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Estilo',
    path: '',
  },
  {
    titulo: 'Looks',
    path: '/estilo/look/lista',
  }
]

const LookPage: FC = () => {
    const {auth} = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
  
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='lista'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbs}>Composição de Looks</BreadcrumbLayout>
                <Looks />
              </>
            }
          />

          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Design_Sugestão de Compras_Gerenciamento de Looks_Adicionar'])) && (
              <Route
                path='adicionar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Adicionar Look</BreadcrumbLayout>
                    <Look />
                  </>
                }
              /> 
          )}
          
          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Design_Sugestão de Compras_Gerenciamento de Looks_Editar'])) && (
              <Route
                path='editar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Editar Look</BreadcrumbLayout>
                    <Look />
                  </>
                }
              /> 
          )}          

          <Route
            path='visualizar'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Visualizar Look</BreadcrumbLayout>
                <Look />
              </>
            }
          /> 

          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route index element={<Navigate to='/estilo/look/lista' />} />
        </Route>
      </Routes>
    )
}

export {LookPage}
