/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAlert, useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPermissoes, EnumRoles, EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums';
import { useAuth } from '../../../../modules/auth'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Popover } from '@mui/material';
import Select from 'react-select'
import { IAreasModel, IUsuarioModel } from '../../../../modules/services/core/_models';
import '../../../../../styles/agentes.css';
import { CardComBotoes } from './CardAgentes';
import { number } from 'yup';

interface Data {
  Id: string,
  Nome: string,
  DataUltimaAtualizacaoString: string,
  DataCriacaoString: string,
  Hora: string,
  UsuarioUltimaAtualizacao: IUsuarioModel,
  UsuarioCriacao: IUsuarioModel,
  QuantidadeAtualizacoes: number
}

function createData(
  Id: string,
  Nome: string,
  DataUltimaAtualizacaoString: string,
  DataCriacaoString: string,
  Hora: string,
  UsuarioUltimaAtualizacao: IUsuarioModel,
  UsuarioCriacao: IUsuarioModel,
  QuantidadeAtualizacoes: number
): Data {
  return { Id, Nome, DataUltimaAtualizacaoString, DataCriacaoString, Hora, UsuarioUltimaAtualizacao, UsuarioCriacao, QuantidadeAtualizacoes }
}

const Agentes = () => {
  const httpClient = useHttpClient()
  const alert = useAlert()
  const blockUI = useBlockUI()
  const navigate = useNavigate()
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([]);
  const [search, setSearch] = React.useState<string>('')
  const [ativo, setAtivo] = React.useState(true)
  const { auth } = useAuth()
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes
  const [nivel1, setNivel1] = React.useState<IAreasModel | null>(null);
  const [nivelFilho, setNivelFilho] = React.useState<IAreasModel | null>(null);
  const [assunto, setAssunto] = React.useState<string>("");
  const [niveis1, setNiveis1] = React.useState<IAreasModel[]>([]);
  const [niveisFilho, setNiveisFilho] = React.useState<IAreasModel[]>([]);

  const obterNiveis1 = async () => {
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Administracao/AgenteNivel/ObterParaSelect',
        blockFree: true,
      })
      .then((response) => {
        if (response.success && response.payload) {
          setNiveis1(response.payload);
        }
      });
  };

  const obterSubAreas = async (id: number | null) => {
    setNivelFilho(null)
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Administracao/AgenteNivel/ObterParaSelectFilhos',
        queryObject: {
          id: id === null ? 0 : id
        },
        blockFree: true,
      })
      .then((response) => {
        if (response.success && response.payload) {
          setNiveisFilho(response.payload);
        }
      });
  };

  const resquestTable = async () => {
    let response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Administracao/Agente/Listar',
      blockFree: false,
      data: {
        Filters: {
          AgenteNivelId: nivelFilho?.Id ? nivelFilho?.Id : nivel1?.Id ,
          Nome: assunto,
          Ativo: ativo
        }
      }
    })

    if (response.success && response.payload) {
      let newRows = response.payload.data.map((data: Data) => {
        let hora = ""
        let dataString = ""
        if (data.DataUltimaAtualizacaoString != null) {
          hora = data.DataUltimaAtualizacaoString.split(" ")[1];
          dataString = data.DataUltimaAtualizacaoString.split(" ")[0];
        }
        return createData(
          data.Id,
          data.Nome,
          dataString,
          data.DataCriacaoString,
          hora,
          data.UsuarioUltimaAtualizacao,
          data.UsuarioCriacao,
          data.QuantidadeAtualizacoes
        )
      })
      setRows([...newRows])
      setTotal(response.payload.total)

    }
  }

  React.useEffect(() => {
    obterNiveis1()
  }, [])

  React.useEffect(() => {
    obterDados()
  }, [nivel1, assunto, nivelFilho, ativo])

  const obterDados = async () => {
    blockUI.setBlocking(true)
    await obterDadosTabela()
    blockUI.setBlocking(false)
  }

  const obterDadosTabela = async () => {
    await resquestTable()
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <Accordion className="custom-accordion">
            <AccordionSummary expandIcon={<i className='bi bi-funnel-fill'></i>} className='custom-accordion-summary'>
              <span className='text-lg font-semibold'>Pesquisa de Agente</span>
            </AccordionSummary>
            <AccordionDetails className='custom-accordion-details'>
              <Box display='flex' gap={2}>
                <div className="row w-100">
                  <div className='col-lg-12 fv-row mb-5'>
                    <label className='form-label'>Nível 1</label>
                    <Select
                      placeholder='Selecione...'
                      className='react-select-styled react-select-lg react-select-solid'
                      classNamePrefix='react-select'
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      options={niveis1.map((data) => ({
                        value: data,
                        label: data.Nome
                      }))}
                      value={
                        nivel1
                          ? { value: nivel1, label: niveis1.find(p => p.Id === nivel1.Id)?.Nome }
                          : null
                      }
                      isSearchable
                      isClearable
                      onChange={(selectedOption) => {
                        setNivel1(selectedOption?.value || null)
                        obterSubAreas(selectedOption?.value.Id || null)
                      }}
                    />
                  </div>
                  {niveisFilho.length > 0 && (
                    <div className='col-lg-12 fv-row mb-5'>
                      <label className='form-label'>Demais níveis</label>
                      <Select
                        placeholder='Selecione...'
                        className='react-select-styled react-select-lg react-select-solid'
                        classNamePrefix='react-select'
                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                        options={niveisFilho.map((data) => ({
                          value: data,
                          label: data.Nome
                        }))}
                        value={
                          nivelFilho
                            ? { value: nivelFilho, label: niveisFilho.find(p => p === nivelFilho)?.Nome }
                            : null
                        }
                        isSearchable
                        isClearable
                        onChange={(selectedOption) => setNivelFilho(selectedOption?.value || null)}
                      />
                    </div>
                  )}
                  <div className='col-lg-12 fv-row mb-5'>
                    <label className='fw-bold text-pro4tech'>Assunto</label>
                    <input
                      type='text'
                      onChange={e => setAssunto(e.target.value)}
                      placeholder='Assunto'
                      className={`form-control`}
                      value={assunto}
                    ></input>
                  </div>
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>
          <div className='row'>
            <span>
              {nivel1?.Nome}{nivelFilho?.Nome != null ? ", " : ""}{nivelFilho?.Nome}{assunto != "" ? ", " : ""}{assunto}
              {(nivel1?.Nome != null || nivelFilho?.Nome != null || assunto != "") && (
                <a onClick={() => {
                  setNivel1(null)
                  setNivelFilho(null)
                  setAssunto("")
                }} className='bi bi-x botao-filtro' />
              )}
            </span>
          </div>
          <div className='card-toolbar mt-2 d-flex justify-content-between w-100'>
            <span className="agentes-title">Gerenciamento de Agentes</span>
            {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Curadoria_Agentes_Gerenciamento de Agentes_Adicionar'])) && (
              <button
                className='btn btn-primary'
                title='Adicionar'
                onClick={(e) => {
                  navigate('/administracao/agente/adicionar', {
                    state: {
                      visualizar: false,
                      title: 'Adicionar'
                    }
                  })
                }}
              >
                <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-1" />
                Adicionar
              </button>
            )}
          </div>
          <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_4"
                onClick={() => setAtivo(true)}
              >
                Ativos
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_5"
                onClick={() => setAtivo(false)}
              >
                Inativos
              </a>
            </li>
          </ul>
          <div className="mt-5">
            {rows.map((row, index) => {
              return (
                <CardComBotoes
                  numero={row.QuantidadeAtualizacoes}
                  nome={row.Nome}
                  dataCriacao={row.DataCriacaoString}
                  data={row.DataUltimaAtualizacaoString}
                  hora={row.Hora}
                  responsavel={row.UsuarioUltimaAtualizacao}
                  responsavelCriacao={row.UsuarioCriacao}
                  id={row.Id}
                  onUpdate={obterDados}
                />
              )
            })}
          </div>
        </div>
      </div >
    </>
  )
}

export { Agentes }
