import { LogLevel, Configuration } from "@azure/msal-browser";
import { Msal2Config } from "@microsoft/mgt-msal2-provider";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graph: "https://graph.microsoft.com/v1.0/",
};

export const graphMsal2Config: Msal2Config = {
  clientId: process.env.REACT_APP_CLIENT_ID as string,
  authority: process.env.REACT_APP_AUTHORITY,
  redirectUri: "/",
  scopes: [
    "user.read",
    "user.readbasic.all",
    "openid",
    "profile",
    "people.read",
  ],
};

// "api://cc151bac-ed22-4c46-b1c7-627b8d7724b0/OrquestradorScope",
// "api://cc151bac-ed22-4c46-b1c7-627b8d7724b0/PortalScope",
