import * as Yup from 'yup'
import { IProdutoModel } from '../../../../../modules/services/core/_models'

const initialValues: IProdutoModel = {
  CodigoSKU: '',
  NomeProduto: '',
  Imagem: '',
  Grupo: 0,
  SubGrupo: 0,
  Estoque: false,
  Ativo: true, // Adicionando o campo Ativo
}

const defaultValidationSchemas = Yup.object().shape({
  CodigoSKU: Yup.string()
    .max(20, 'O Código SKU deve ter no máximo 20 caracteres')
    .required('O Código SKU é obrigatório'),

  NomeProduto: Yup.string()
    .required('O nome do produto é obrigatório'),

  Imagem: Yup.string()
    .url('A URL da imagem deve ser válida')
    .max(2083, 'A URL da imagem deve ter no máximo 2083 caracteres')
    .required('A URL da imagem é obrigatória'),

  Grupo: Yup.number()
    .min(1, 'O Grupo é obrigatório')
    .required('O Grupo é obrigatório'),

  SubGrupo: Yup.number()
    .min(1, 'O SubGrupo é obrigatório')
    .required('O SubGrupo é obrigatório'),

  Estoque: Yup.boolean()
    .required('O campo Estoque é obrigatório'),

  Ativo: Yup.boolean()
    .required('O campo Ativo é obrigatório'),

})

export { defaultValidationSchemas, initialValues }
