/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useBlockUI, useHttpClient } from '../../../../modules/services/Bundle'
import { EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums'
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { defaultValidationSchemas, initialValues } from './helpers/SubAreaHelper'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import Switch from 'react-switch'
import { IAreasModel } from '../../../../modules/services/core/_models'

interface Dados {
  idArea: number
  visualizar: boolean
  title: string
}

const SubArea = () => {
  const httpClient = useHttpClient()
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as Dados
  const informacao: Dados = state
  const { setBlocking } = useBlockUI()
  const [areas, setAreas] = useState<IAreasModel[]>([]);

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values) => {

      const data = {
        Id: informacao.idArea > 0 ? informacao.idArea : null,
        Nome: values.Nome,
        Descricao: values.Descricao,
        AreaId: values.AreaId
      }

      await httpClient.request({
        method: (informacao && informacao.idArea) ? RequestMethod.PUT : RequestMethod.POST,
        endpoint: `/Administracao/SubArea/${(informacao && informacao.idArea) ? "Editar" : "Adicionar"}`,
        data: data,
        ifSuccessRedirectTo: '/administracao/subarea/lista'
      })
    },
  })

  const obterSubArea = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/SubArea/Obter',
      blockFree: true,
      queryObject: {
        idSubArea: informacao.idArea
      }
    })

    if (response.success && response.payload) {
      formik.setValues(response.payload)
    }

  }

  const obterAreas = async () => {
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Administracao/Area/ObterTodosAtivo',
        blockFree: true,
      })
      .then((response) => {
        if (response.success && response.payload) {
          setAreas(response.payload);
        }
      });
  };


  const obterDados = async () => {

    setBlocking(true)

    if (informacao && informacao.idArea) await obterSubArea()

    obterAreas()

    setBlocking(false)

  }

  useEffect(() => {
    obterDados()
  }, [informacao])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>

        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{informacao.title} Subárea</h3>
          </div>
        </div>

        <div className='card-body'>
          <FormikProvider value={formik}>
            <div className='w-100'>
              <form noValidate >
                <div className="row">
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>Nome</label>
                    <Field
                      name='Nome'
                      placeholder='Nome da subárea'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.Nome}
                      disabled={informacao && informacao.idArea && informacao.visualizar}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Nome' />
                    </div>
                  </div>
                  <div className='col-lg-6 fv-row mb-5'>
                    <label className='form-label required'>Área</label>
                    <Select
                      placeholder='Selecione...'
                      className='react-select-styled react-select-lg react-select-solid'
                      classNamePrefix='react-select'
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      options={areas.map((data) => ({
                        value: data.Id,
                        label: data.Nome
                      }))}
                      isDisabled={informacao && informacao.visualizar}
                      value={
                        formik.values.AreaId
                          ? { value: formik.values.AreaId, label: areas.find(p => p.Id === formik.values.AreaId)?.Nome }
                          : null
                      }
                      isSearchable
                      isClearable
                      onChange={(selectedOption) => formik.setFieldValue('AreaId', selectedOption?.value || null)}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='AreaId' />
                    </div>
                  </div>
                  <div className='col-lg-12 fv-row mb-5'>
                    <label className='form-label'>Descrição</label>
                    <Field
                      as='textarea'
                      name='Descricao'
                      placeholder='Digite a descrição aqui...'
                      className='form-control form-control-lg form-control-solid'
                      value={formik.values.Descricao}
                      disabled={informacao && informacao.idArea && informacao.visualizar}
                      rows={5}
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='Descricao' />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </FormikProvider>
        </div>
        <div className="card-footer py-4 d-flex justify-content-between">
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => navigate(-1)}
          >
            Voltar
          </button>
          {informacao.title && informacao.title !== 'Visualizar' && (
            <button
              type='button'
              onClick={() => formik.handleSubmit()}
              className={`btn btn-lg btn-primary ${informacao && informacao.visualizar ? "d-none" : ""}`}
            >
              {informacao.title}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export { SubArea }

