import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import InputMask from 'react-input-mask'
import {useHttpClient} from '../../services/Bundle'
import {EnumUsuarioTipo, RequestMethod} from '../../services/core/_enums'
import { IconButton } from '@mui/material'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'

const initialValues = {
  name: '',
  email: '',
  cellphone: '',
  password: '',
  confirmpassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .max(150, 'O nome completo deve ter no máximo 150 caracteres')
    .matches(/^[A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ][a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{0,20}(?: [A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ][a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{0,20}){1,}$/ , 'O nome deve iniciar com letra maiúscula, ter apenas letras (A-Z) e cada palavra deve ter no máximo 20 caracteres')
    .required('O nome completo é obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .required('O email é obrigatório'),
  cellphone: Yup.string()
    .test('Celular', 'O celular é inválido', (val = '') => {
      if (!val) return true
      return val.replace(/\D/g, '').length === 11
    })
    .required('O celular é obrigatório'),  
  password: Yup.string()
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .max(20, 'A senha deve ter no máximo 20 caracteres')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!?%\-\=\\\/\[\]\{\}\(\)])[0-9a-zA-Z$*&@#!?%\-\=\\\/\[\]\{\}\(\)]{8,20}$/,
      'A senha deve ter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 símbolo'
    )
    .required('A senha é obrigatória'),
  confirmpassword: Yup.string()
    .required('A confirmação de senha é obrigatória')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'A senha e a confirmação de senha não conferem'
      ),
    }),
  acceptTerms: Yup.bool().required('Você deve aceitar os termos e condições'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const httpClient = useHttpClient()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setLoading(true)
      try {

        const response = await httpClient.request({
          method: RequestMethod.POST,
          endpoint: '/Account/SignUp',
          ifSuccessRedirectTo: '/',
          data: {
            Tipo: EnumUsuarioTipo['Domínio Externo'],
            Nome: values.name,
            Email: values.email,
            Celular: values.cellphone.replace(/\D/g, ''),
            Senha: values.password,
          },
        })

        if(response.success) resetForm()
        setSubmitting(false)
        setLoading(false)
        
      } catch (error) {

        console.log(error)
        setSubmitting(false)
        setLoading(false)

      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Cadastro</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>Crie sua conta</div>
      </div>
      {/* end::Heading */}

      {/* begin::Form group Nome */}
      <div className='fv-row mb-8'>
        <input
          placeholder='Nome'
          type='text'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          type='email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Cellphone */}
      <div className='fv-row mb-8'>
        <InputMask
          mask={'(99) 99999-9999'}
          placeholder='Celular'
          type='text'
          {...formik.getFieldProps('cellphone')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.cellphone && formik.errors.cellphone,
            },
            {
              'is-valid': formik.touched.cellphone && !formik.errors.cellphone,
            }
          )}
        ></InputMask>
        {formik.touched.cellphone && formik.errors.cellphone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.cellphone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <div className='position-relative mb-3'>

            <div className="wrapper-olho">
              <div className={`${formik.touched.password ? "icon-olho-distancia-maior" : "icon-olho"}`}>
                <IconButton
                  aria-label="visualizar senha"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  tabIndex={-1}
                >
                  {showPassword ?<i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                </IconButton>
              </div>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Senha'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  'input-olho',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
            </div>  
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 a 20 caracteres com uma mistura de letras, números e símbolos.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>

      <div className="wrapper-olho">
          <div className={`${formik.touched.confirmpassword ? "icon-olho-distancia-maior" : "icon-olho"}`}>
            <IconButton
              aria-label="visualizar senha"
              onClick={handleClickShowConfirmPassword}
              onMouseDown={handleMouseDownConfirmPassword}
              edge="end"
              tabIndex={-1}
            >
              {showConfirmPassword ?<i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
            </IconButton>
          </div>
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder='Confirmação de senha'
            autoComplete='off'
            {...formik.getFieldProps('confirmpassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              'input-olho',
              {
                'is-invalid': formik.touched.confirmpassword && formik.errors.confirmpassword,
              },
              {
                'is-valid': formik.touched.confirmpassword && !formik.errors.confirmpassword,
              }
            )}
          />
        </div>  
        {formik.touched.confirmpassword && formik.errors.confirmpassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmpassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline form-check-solid' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            checked={formik.values.acceptTerms}
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            Eu aceito os
            <a href='termos' target='_blank' className='ms-1 link-primary'>
              termos
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Continuar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Aguarde...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancelar
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
