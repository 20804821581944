/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {useNavigate} from 'react-router-dom'
import Popover from '@mui/material/Popover'
import { useBlockUI, useHttpClient } from '../../../../../modules/services/Bundle'
import { EnumPermissoes, EnumRoles, RequestMethod } from '../../../../../modules/services/core/_enums'
import { IAplicacaoModel } from '../../../../../modules/services/core/_models'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useAuth } from '../../../../../modules/auth'

interface Data {
  Id: number
  TipoNome: string
  NomeAplicacao: string
}

interface Column {
  id: keyof Data
  label: string
  minWidth?: number
  align?: 'center'
  format?: (value: number) => string
}

interface Filtros {
  IdAplicacao?: number
}

const columns: readonly Column[] = [
  {
    id: 'TipoNome',
    label: 'TIPO',
  },
  {
    id: 'NomeAplicacao',
    label: 'APLICAÇÃO',
  },
]

function createData(Id: number, TipoNome: string, NomeAplicacao: string): Data {
  return {Id, TipoNome, NomeAplicacao}
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

const TablePrompts: React.FC = () => {
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('TipoNome')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])
  const navigate = useNavigate()
  const {setBlocking} = useBlockUI()
  const [aplicacoes, setAplicacoes] = React.useState<IAplicacaoModel[]>([])
  const [filtros, setFiltros] = React.useState<Filtros>({
    IdAplicacao: undefined,
  })
  const [search, setSearch] = React.useState<string>('')
  const {auth} = useAuth()
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes
  const [popoverTableActionsAnchorEl, setPopoverTableActionsAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const popoverTableActionsOpen = Boolean(popoverTableActionsAnchorEl)

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead>
        <TableRow className='table-pro4tech-header'>
          {columns.map((columns) => (
            <TableCell
              className='table-pro4tech-header-cell'
              key={columns.id}
              align={columns.align ? 'right' : 'left'}
            >
              {columns.label}
            </TableCell>
          ))}
          <TableCell className='table-pro4tech-header-cell fw-bolder' align='center'>
            AÇÕES
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const onKeyDownBuscar = (search: string) => {
    setSearch(search);
    resquestTable(page, orderBy, order, rowsPerPage, filtros, search, true);    
  }

  const resquestTable = async (
    pageRq: number,
    orderByRq: string,
    orderRq: string,
    rowsPerPageRq: number,
    Filtros: Filtros,
    search: any = null,
    block: boolean = false,
  ) => {
    let response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Administracao/Prompt/Listar',
      blockFree: block,
      data: {
        Order: orderRq,
        OrderBy: orderByRq,
        Page: pageRq,
        RowsPerPage: rowsPerPageRq,
        Filters: Filtros,
        Search: search,
      },
    })

    if (response.success && response.payload) {
      let newRows = response.payload.data.map((data: Data) => {
        return createData(data.Id, data.TipoNome, data.NomeAplicacao)
      })
      setRows([...newRows])
      setTotal(response.payload.total)
    }
  }

  const obterAplicacoes = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Aplicacao/ObterParaSelect',
    })
    
    if (response.success && response.payload) setAplicacoes(response.payload)
  }

  const obterDadosTabela = async () => {
    await resquestTable(page, orderBy, order, rowsPerPage, filtros, search)
  }

  const obterDados = async() => {
    
    setBlocking(true)

    await obterAplicacoes()

    await obterDadosTabela()

    setBlocking(false)

  }

  React.useEffect(() => {    
    obterDados()
  }, [filtros])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage, filtros, search)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage, filtros, search)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10), filtros, search)
    setPage(0)
  }

  const emptyRows = rows.length

  return (
    <>
      <form className='form'>
        <div>
          <div className='row' >                
            {/* begin::Input group */}
            <div className='mb-2 col-lg-4'>
              <label className='fw-bold text-pro4tech'>Aplicação</label>
              <select 
                onChange={e => {
                  let newFiltros = {...filtros}
                  newFiltros.IdAplicacao = e.target.value ? parseInt(e.target.value) : undefined
                  setFiltros(newFiltros)
                }}
                placeholder='Selecione...'
                className={`form-select form-select-lg form-select-solid`}
                value={filtros.IdAplicacao}
              >
                <option value=''>Selecione...</option>
                {
                  aplicacoes && aplicacoes.length > 0 && aplicacoes.map((data: any, index: any) => {
                    return <option key={`IdAplicacao-option-${index}`} value={data.Id}>{data.Nome}</option>
                  })
                }
              </select>
            </div>
            {/* end::Input group */}
            {/* begin::Input group */}
            <div className='mb-2 col-lg-4'>
              <label className='fw-bold text-pro4tech'>Pesquisar (Tipo)</label>
              <input 
                type='text'
                onChange={e => onKeyDownBuscar(e.target.value)} 
                placeholder='Pesquisar (Tipo)' 
                className={`form-control form-control-lg form-control-solid`}
                value={search}
              ></input>
            </div>
            {/* end::Input group */}
          </div>
        </div>
        <Box sx={{ width: '100%' }}>
            <Paper className='table-pro4tech-border mb-0' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  className='table-pro4tech-header'
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-pro4tech-row'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-pro4tech-cell' align='left'>
                            {row.TipoNome}
                          </TableCell>
                          <TableCell className='table-pro4tech-cell' align='left'>
                            {row.NomeAplicacao}
                          </TableCell>
                          <TableCell className='table-pro4tech-cell' align="center">

                            <div>

                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-active-light-primary btn-custom'
                                aria-describedby='table-actions'
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                  setPopoverTableActionsAnchorEl(event.currentTarget)
                                }}
                              >
                                <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-muted svg-icon-2hx' />
                              </button>
                              <Popover
                                id='table-actions'
                                open={popoverTableActionsOpen}
                                anchorEl={popoverTableActionsAnchorEl}
                                onClose={() => {
                                  setPopoverTableActionsAnchorEl(null)
                                }}
                                onClick={() => {
                                  setPopoverTableActionsAnchorEl(null)
                                }}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                className='popover-table-actions'
                              >
                                {/* begin::Menu */}
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px d-block border border-3">

                                  {/* begin::Menu item */}
                                  <div className="menu-item px-3">
                                    <a 
                                      href='' 
                                      className="menu-link px-5"
                                      onClick={(e) => {
                                        e.preventDefault()

                                        navigate('/administracao/prompts/visualizar',
                                        { 
                                          state: 
                                          { 
                                            Id: row.Id,
                                            Acao: "Visualizar"
                                          }
                                        })
                                      }}
                                    >
                                      <span className="menu-icon" data-kt-element="icon">
                                        <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
                                      </span>
                                      <span className="menu-title">Visualizar</span>
                                    </a>
                                  </div>
                                  {/* end::Menu item */}

                                  {/* begin::Menu item */}
                                  {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Prompts_Editar)) && (
                                    <div className="menu-item px-3">
                                      <a 
                                        href='' 
                                        className="menu-link px-5"
                                        onClick={(e) => {
                                          e.preventDefault()

                                          navigate('/administracao/prompts/editar',
                                          { 
                                            state: 
                                            { 
                                              Id: row.Id,
                                              Acao: "Editar"
                                            }
                                          })
                                        }}
                                      >
                                        <span className="menu-icon" data-kt-element="icon">
                                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                                        </span>
                                        <span className="menu-title">Editar</span>
                                      </a>
                                    </div>                                  
                                  )}         
                                  {/* end::Menu item */}

                                </div>
                                {/* end::Menu */}
                              </Popover>                             

                            </div>

                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows === 0 && (
                      <TableRow className='table-pro4tech-row'>
                        <TableCell className='table-pro4tech-cell text-center' colSpan={columns.length + 1}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-pro4tech-footer'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
      </form>
    </>
  )
}

export {TablePrompts}
