import * as React from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useAuth } from '../../../../modules/auth';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import "../../../../../styles/chatAgente.css";
import { RequestMethod } from '../../../../modules/services/core/_enums';
import { useHttpClient } from '../../../../modules/services/Bundle';

interface ChatAgenteProps {
    id: string | null | undefined;
    idAgenteNivel: number | null | undefined;
    nome: string | null | undefined
}

enum MessageRole {
    "User" = 0,
    "Assistant" = 1,
}

interface IThreadMessageModel {
    IdMensagem: number;
    Id: string;
    Role: MessageRole;
    CreatedAt: Date;
    Data: string;
    Hora: string;
    Texto: string;
}

const ChatAgente: React.FC<ChatAgenteProps> = ({ id, idAgenteNivel, nome }) => {
    const { auth } = useAuth();
    const httpClient = useHttpClient()
    const [threadId, setThreadId] = React.useState<string | undefined>(undefined)
    const [message, setMessage] = React.useState<string>("");
    const [messages, setMessages] = React.useState<IThreadMessageModel[]>([]);
    const [isRecording, setIsRecording] = React.useState(false);
    const [isWriting, setIsWriting] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [activeChatId, setActiveChatId] = React.useState<number | null>(null);
    const messageInputRef = React.useRef<HTMLInputElement>(null);
    const fotoDataUrl = auth?.User?.FotoDataUrl;

    const {
        transcript,
        resetTranscript,
    } = useSpeechRecognition();

    const handleStartRecording = () => {
        setIsRecording(true);
        resetTranscript();
        SpeechRecognition.startListening({ continuous: false }); // Para de ouvir automaticamente após o usuário terminar
    };

    const handleStopRecording = () => {
        setIsRecording(false);
        SpeechRecognition.stopListening();

        if (transcript.trim()) {
            setMessage(transcript)
            sendMessageTranscript(transcript)
            resetTranscript(); // Limpa o texto para uma nova interação
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            if (idAgenteNivel) await createThreadRun()
        }

        fetchData()
    }, [idAgenteNivel])

    const createThreadRun = async () => {
        const response = await httpClient.request({
            method: RequestMethod.GET,
            blockFree: false,
            endpoint: '/Assistant/CreateThread',
        })

        setIsLoading(false);

        if (response.success && response.payload) {
            setThreadId(response.payload)
            setMessage('')
            await createNewChat(response.payload)
        }
    }

    const createNewChat = async (propThreadId: string) => {
        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/CreateHistoricoChat',
            data: {
                ThreadId: propThreadId,
                idAgenteNivel: idAgenteNivel
            },
        })

        if (response.success && response.payload) {
            setActiveChatId(response.payload.Id);
        }

    };

    const sendMessage = async () => {
        if (!threadId || !message) return
        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/CreateMessage',
            queryObject: {
                msg: message,
                threadId: threadId,
                idChatHistorico: activeChatId
            },
            blockFree: true
        })
        setMessage('')
        if (response.success && response.payload) {
            const lastMessage: IThreadMessageModel = response.payload
            setMessages(msgs => [...msgs, lastMessage])
            getResponseMessage(lastMessage)
        }
    }

    const sendMessageTranscript = async (text: string) => {
        if (!threadId || !text) return
        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/CreateMessage',
            queryObject: {
                msg: text,
                threadId: threadId,
                idChatHistorico: activeChatId
            },
            blockFree: true
        })
        setMessage('')
        if (response.success && response.payload) {
            const lastMessage: IThreadMessageModel = response.payload
            setMessages(msgs => [...msgs, lastMessage])
            getResponseMessageTranscript(lastMessage, text)
        }
    }

    const getResponseMessage = async (lastMessage: IThreadMessageModel) => {
        if (!threadId || !message) return

        setIsLoading(true);

        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/GetResponseMessageByAgente',
            blockFree: true,
            queryObject: {
                threadId: threadId,
                lastMessageId: lastMessage.Id,
                idChatHistorico: activeChatId,
                IdMensagem: lastMessage.IdMensagem,
                IdAgente: id
            },
        })

        setIsLoading(false);

        if (response.success && response.payload) {
            setMessages(msgs => [...msgs, response.payload])
            if (messages.length === 0) gerarTituloEditar(message)
        }
    }

    const getResponseMessageTranscript = async (lastMessage: IThreadMessageModel, text: string) => {
        if (!threadId || !text) return

        setIsLoading(true);

        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/GetResponseMessageByAgente',
            blockFree: true,
            queryObject: {
                threadId: threadId,
                lastMessageId: lastMessage.Id,
                idChatHistorico: activeChatId,
                IdMensagem: lastMessage.IdMensagem,
                IdAgente: id
            },
        })

        setIsLoading(false);

        if (response.success && response.payload) {
            setMessages(msgs => [...msgs, response.payload])
            if (messages.length === 0) gerarTituloEditar(message)
        }
    }

    const gerarTituloEditar = async (texto: string) => {
        const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Assistant/GerarTituloEditarPreVisualizar',
            blockFree: true,
            queryObject: {
                texto: texto,
                idHistoricoChat: activeChatId
            }
        })
    }

    return (
        <>
            {(id && idAgenteNivel) ? (
                <div className="chat-content-agente">
                    {messages.length > 0 ? (
                        <>
                            <div className="messages-agente">
                                {messages.map((msg, index) => (
                                    <div key={index} className={`message-container ${msg.Role === MessageRole.User ? "user-container" : "chat-container"}`}>
                                        <div className="message-header">
                                            {msg.Role === MessageRole.User ? (
                                                <>
                                                    <span className="timestamp-user">{msg.Hora}</span>
                                                    <span className="sender-name-user">{msg.Role === MessageRole.User ? "Você" : "Assistente"}</span>
                                                    <img src={(fotoDataUrl && fotoDataUrl !== '' ? fotoDataUrl : toAbsoluteUrl('/media/avatars/blank.png'))} alt="Avatar" className="avatar-user" />
                                                </>
                                            ) : (
                                                <>
                                                    <img src={toAbsoluteUrl('/media/pro4tech/Assistente.png')} alt="Avatar" className="avatar" />
                                                    <span className="sender-name">{nome ? nome : "Assistente"}</span>
                                                    <span className="timestamp">{msg.Hora}</span>
                                                </>
                                            )}

                                        </div>
                                        <div className={`message ${msg.Role === MessageRole.User ? "user-message" : "chat-message"}`} dangerouslySetInnerHTML={{ __html: msg.Texto }} />
                                    </div>
                                ))}
                                {isLoading && <div className="loading-dots">
                                    <span className="dot"></span>
                                    <span className="dot"></span>
                                    <span className="dot"></span>
                                </div>}
                            </div>
                            <div className="recording-indicator-container">
                                {isRecording && (
                                    <div className="recording-indicator">
                                        <span className="recording-dot" />
                                        Gravando...
                                    </div>
                                )}
                            </div>
                            <div className="message-input">
                                <input
                                    type="text"
                                    value={message}
                                    disabled={isLoading}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        setMessage(inputValue);
                                        setIsWriting(inputValue.trim().length > 0);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            sendMessage();
                                            setIsWriting(false);
                                        }
                                    }}
                                    placeholder="Digite sua mensagem ou use o microfone..."
                                />
                                {isWriting ? (
                                    <button
                                        disabled={isLoading}
                                        onClick={() => {
                                            sendMessage();
                                            setIsWriting(false);
                                        }}
                                        className="bi-send"
                                    />
                                ) : (
                                    <button
                                        disabled={isLoading}
                                        onClick={isRecording ? handleStopRecording : handleStartRecording}
                                        className={`${isRecording ? "bi-stop-fill" : "bi-mic-fill"}`}
                                    ></button>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="chat-placeholder">
                                <h2>Pré-visualizar!</h2>
                                <h5>Salve suas alterações para atualizar o chat.</h5>
                                <p>Digite uma mensagem ou grave para começar a conversa.</p>
                                <div className="message-input">
                                    <input
                                        type="text"
                                        value={message}
                                        disabled={isLoading}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            setMessage(inputValue);
                                            setIsWriting(inputValue.trim().length > 0);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                sendMessage();
                                                setIsWriting(false);
                                            }
                                        }}
                                        placeholder="Digite sua mensagem ou use o microfone..."
                                    />
                                    {isWriting ? (
                                        <button
                                            onClick={() => {
                                                sendMessage();
                                                setIsWriting(false);
                                            }}
                                            disabled={isLoading}
                                            className="bi-send"
                                        />
                                    ) : (
                                        <button
                                            disabled={isLoading}
                                            onClick={isRecording ? handleStopRecording : handleStartRecording}
                                            className={`${isRecording ? "bi-stop-fill" : "bi-mic-fill"}`}
                                        ></button>
                                    )}
                                </div>
                            </div>

                        </>
                    )}
                </div>
            ) : (
                <div className="chat-placeholder">
                    <h2>Pré-visualizar!</h2>
                    <p>Crie seu agente para ativar o chat.</p>
                </div>
            )}
        </>
    );
};

export { ChatAgente };
