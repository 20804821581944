/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import { EnumPermissoes, EnumRoles } from '../../modules/services/core/_enums'
import { LookPage } from './looks/LooksPage'
import { ProdutoPage } from './produtos/ProdutosPage'

const EstiloPage: FC = () => {
    const { auth } = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes

    return (
        <Routes>
            <Route element={<Outlet />} >
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Design_Sugestão de Compras_Gerenciamento de Looks_Visualizar'])) && (
                    <Route
                        path='look/*'
                        element={
                            <>
                                <LookPage />
                            </>
                        }
                    />
                )}
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Design_Sugestão de Compras_Produtos_Visualizar'])) && (
                    <Route
                        path='produto/*'
                        element={
                            <>
                                <ProdutoPage />
                            </>
                        }
                    />
                )}

            </Route>
        </Routes>
    )
}

export { EstiloPage }
