import {Navigate, Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import { Registration } from './components/Registration'
import { TwoSteps } from './components/TwoSteps'
import { Termos } from './components/Termos'
import { ForgotPassword } from './components/ForgotPassword'
import { ResetPassword } from './components/ResetPassword'
import { ResendConfirmAccount } from './components/ResendConfirmAccount'
import { ConfirmAccount } from './components/ConfirmAccount'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='two-steps' element={<TwoSteps />} />
      <Route path='termos' element={<Termos />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='resend-confirm-account' element={<ResendConfirmAccount />} />
      <Route path='confirm-account' element={<ConfirmAccount />} />
      <Route index element={<Navigate to='/login' />} />
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Route>
  </Routes>
)

export {AuthPage}
