/* eslint-disable react-hooks/exhaustive-deps */
import {useLocation, useNavigate} from 'react-router-dom'
import {ErrorMessage, Field, FormikProvider, useFormik} from 'formik'
import {useEffect} from 'react'
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle'
import { RequestMethod } from '../../../../modules/services/core/_enums'
import { defaultValidationSchemas, initialValues } from './helpers/PromptHelper'

type Dados = {
  Id: number
  Acao: string,
}

const Prompt = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const httpClient = useHttpClient()
  const alert = useAlert()
  const state = location.state as Dados
  const informacao: Dados = state

  const formik = useFormik({
    initialValues: {...initialValues},
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values) => {
      alert.createDialog({
        html: `Realmente deseja editar esse prompt?`,
        confirmAction: async () => {
          await httpClient.request({
            method: RequestMethod.PUT,
            endpoint: '/Administracao/Prompt/Editar',
            data: values,
            ifSuccessRedirectTo: '/administracao/prompts/lista',
          })
        },
      })
    },
  })

  const obterPrompt = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: '/Administracao/Prompt/Obter',
      queryObject: {
        idPrompt: informacao.Id,
      },
    })
    
    if (response.success && response.payload) formik.setValues(response.payload)
  }

  useEffect(() => {
    if (informacao && informacao.Id) obterPrompt()
  },[informacao])

  return (
    <div className='card mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{informacao.Acao} Prompt</h3>
        </div>
      </div>

      <div className='card-body'>
        <FormikProvider value={formik}>
          <div className='w-100'>
            <form noValidate>
              <div className='row'>                
                <div className='col-lg-6 fv-row mb-5'>
                  <label className='form-label'>Tipo:</label>
                  <Field
                    type='text'
                    name={`TipoNome`}
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.TipoNome}
                    disabled
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='TipoNome' />
                  </div>
                </div>
                <div className='col-lg-6 fv-row mb-5'>
                  <label className='form-label'>Aplicação:</label>
                  <Field
                    type='text'
                    name={`NomeAplicacao`}
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.NomeAplicacao}
                    disabled
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='NomeAplicacao' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='form-label required'>Mensagem:</label>
                  <Field
                    as='textarea'
                    name={`Mensagem`}
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.Mensagem}
                    rows={10}
                    disabled={(informacao && informacao.Acao === 'Visualizar' ? true : false)}
                  ></Field>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='Mensagem' />
                  </div>
                  {formik.values.Parametros && formik.values.Parametros.length > 0 && (
                    <>
                      <label className='mt-2'>Parametros obrigatórios:</label>
                      <ul>
                        {formik.values.Parametros.map((parametro) => {
                          return <li>{parametro}</li>
                        })}
                      </ul>
                    </>
                  )}                  
                </div>                
              </div>
            </form>
          </div>
        </FormikProvider>
      </div>

      <div className='card-footer py-4 d-flex justify-content-between'>
        <button
          type='button'
          className='btn btn-secondary'
          onClick={() => navigate(-1)}
        >
          Voltar
        </button>
        {informacao.Acao && informacao.Acao !== 'Visualizar' && (
          <button
            type='button'
            onClick={() => formik.handleSubmit()}
            className={`btn btn-lg btn-primary`}
          >
            {informacao.Acao}
          </button>
        )} 
      </div>
    </div>
  )
}

export {Prompt}
