import {useState, useEffect} from 'react'
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom'
import { useHttpClient } from '../../services/Bundle'
import { RequestMethod } from '../../services/core/_enums'
import { AuthModel } from '../core/_models';
import { useAuth } from '../core/Auth';

export function ConfirmAccount() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") ?? ''
  const token = searchParams.get("token") ?? ''
  const httpClient = useHttpClient()
  const {saveAuth} = useAuth()
  const [accountConfirmed, setAccountConfirmed] = useState(false)
  const navigate = useNavigate()

  const confirmAccount = async () => {
    const response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Account/ConfirmAccount',
      data: {
        Email: email,
        Token: token,
      },
    })

    if (response.success && response.payload) {
      setAccountConfirmed(true)
      const newAuth: AuthModel = response.payload
      saveAuth(newAuth)
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    if(email && token) confirmAccount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if(!email || !token){
    return (
      <Navigate to='*' />
    )
  }

  return (
    <>
      <h1 className={`text-muted text-center`}>
        {accountConfirmed ? 'Sua conta foi confirmada!' : 'Confirmando sua conta...'}
      </h1>
    </>
  )
}
