/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "../../../styles/chat.css";
import { ThemeModeComponent } from "../../../_metronic/assets/ts/layout";
import { useThemeMode } from "../../../_metronic/partials";
import { boolean, date } from "yup";
import { useAlert, useHttpClient } from "../../modules/services/Bundle";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import Popover, { PopoverProps } from "@mui/material/Popover";
import { MenuItem } from "@mui/material";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { RequestMethod } from "../../modules/services/core/_enums";
import { useAuth } from "../../modules/auth";
import { useSpeech } from "react-text-to-speech";

interface ChatHistory {
    Id: number;
    Nome: string;
    DataCriacao: string; // Data no formato ISO (e.g., "2025-01-27T15:45:00")
}

interface Niveis {
    Nivel1: NivelVM
    Niveis: NivelVM[];
}


interface NivelVM {
    Id: number;
    Nome: string;
    IdPai?: number;
}

interface Subarea {
    Id: number;
    Nome: string;
}

enum MessageRole {
    "User" = 0,
    "Assistant" = 1,
}

interface IThreadMessageModel {
    IdMensagem: number;
    Id: string;
    Role: MessageRole;
    CreatedAt: Date;
    Data: string;
    Hora: string;
    Texto: string;
}


const ChatApp: React.FC = () => {
    const { mode } = useThemeMode()
    const { auth } = useAuth();
    const httpClient = useHttpClient()
    const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'
    const currentMode = mode === 'system' ? systemMode : mode
    const alert = useAlert()
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [chatHistory, setChatHistory] = useState<ChatHistory[]>([]);
    const [activeChatId, setActiveChatId] = useState<number | null>(null);
    const [message, setMessage] = useState<string>("");
    const [messages, setMessages] = useState<IThreadMessageModel[]>([]);
    const [niveis, setNiveis] = useState<Niveis[] | null>(null);
    const [expandedNivel, setExpandedNivel] = useState<NivelVM | null>(null);
    const [selectedNivel1, setSelectedNivel1] = useState<NivelVM | null>(null);
    const [selectedNivelFIlho, setSelectedNivelFilho] = useState<NivelVM | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isWriting, setIsWriting] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [threadId, setThreadId] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [editingChatId, setEditingChatId] = useState<number | null>(null);
    const [newTitle, setNewTitle] = useState<string>("");
    const [agenteId, setAgenteId] = useState<string>("");
    const [agenteNome, setAgenteNome] = useState<string>("");
    const fotoDataUrl = auth?.User?.FotoDataUrl;
    const [textSpeech, setTextSpeech] = React.useState<string>("");
    const {
        Text, // Component that returns the modified text property
        speechStatus, // String that stores current speech status
        isInQueue, // Indicates whether the speech is currently playing or waiting in the queue
        start, // Function to start the speech or put it in queue
        pause, // Function to pause the speech
        stop, // Function to stop the speech or remove it from queue
    } = useSpeech({
        text: textSpeech,
        lang: 'pt-BR',      // Define o idioma (português do Brasil)
        voiceURI: 'Google português do Brasil' // Define a voz
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
        setEditingChatId(null); // Garante que a edição só começa ao clicar em "Renomear"
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    const handleStartRecording = () => {
        setIsRecording(true);
        resetTranscript(); // Limpa o texto anterior
        SpeechRecognition.startListening({ continuous: true, interimResults: true }); // Para de ouvir automaticamente após o usuário terminar
    };

    const handleStopRecording = () => {
        setIsRecording(false);

        setTimeout(() => {
            SpeechRecognition.stopListening();

            if (transcript.trim()) {
                setMessage(transcript);
                sendMessageTranscript(transcript);
                resetTranscript(); // Limpa o texto para uma nova interação
            }
        }, 500); // Espera 500ms antes de parar a gravação
    };

    const groupChatsByDate = () => {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        const chatsByGroup: { [key: string]: ChatHistory[] } = {
            Hoje: [],
            Ontem: [],
            "Últimos 7 Dias": [],
        };

        const sortedChats = chatHistory.sort((a, b) => new Date(b.DataCriacao).getTime() - new Date(a.DataCriacao).getTime());

        sortedChats.forEach((chat) => {
            const chatDate = new Date(chat.DataCriacao);

            if (chatDate.toDateString() === today.toDateString()) {
                chatsByGroup["Hoje"].push(chat);
            } else if (chatDate.toDateString() === yesterday.toDateString()) {
                chatsByGroup["Ontem"].push(chat);
            } else if (
                chatDate > new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
            ) {
                chatsByGroup["Últimos 7 Dias"].push(chat);
            } else {
                const year = chatDate.getFullYear().toString();
                if (!chatsByGroup[year]) {
                    chatsByGroup[year] = [];
                }
                chatsByGroup[year].push(chat);
            }
        });

        const years = Object.keys(chatsByGroup).filter((key) => /^\d{4}$/.test(key));
        years.sort((a, b) => Number(b) - Number(a));

        const finalChatsOrder: { [key: string]: ChatHistory[] } = {
            Hoje: chatsByGroup["Hoje"],
            Ontem: chatsByGroup["Ontem"],
            "Últimos 7 Dias": chatsByGroup["Últimos 7 Dias"],
        };

        years.forEach((year) => {
            finalChatsOrder[year] = chatsByGroup[year];
        });

        return finalChatsOrder;
    };

    const groupedChats = groupChatsByDate();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    function removeTags(input: string): string {
        if (!input) return "";

        // Remove tags HTML
        let output = input.replace(/<[^>]+>/g, "");

        // Remove Markdown
        output = output
            .replace(/\*\*(.*?)\*\*/g, "$1") // Negrito
            .replace(/\*(.*?)\*/g, "$1") // Itálico
            .replace(/__(.*?)__/g, "$1") // Negrito
            .replace(/_(.*?)_/g, "$1") // Itálico
            .replace(/~~(.*?)~~/g, "$1") // Tachado
            .replace(/`{1,2}([^`]+)`{1,2}/g, "$1") // Inline code
            .replace(/^#{1,6}\s*(.*)/gm, "$1") // Cabeçalhos
            .replace(/!\[.*?\]\(.*?\)/g, "") // Imagens
            .replace(/\[.*?\]\(.*?\)/g, "$1") // Links
            .replace(/>-?(.*)/g, "$1") // Citações
            .replace(/\|?([-:]+\|)+/g, "") // Tabelas
            .replace(/\n{2,}/g, "\n"); // Remove quebras de linha excessivas

        return output.trim();
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        document.body.classList.add("chat-page");

        const toolbar = document.getElementById("kt_app_toolbar");
        if (toolbar) {
            toolbar.style.display = "none";
        }

        return () => {
            document.body.classList.remove("chat-page");
            if (toolbar) {
                toolbar.style.display = ""; // Volta ao valor padrão
            }
        };
    }, []);

    React.useEffect(() => {
        if (textSpeech) {
            start()
        }

    }, [textSpeech])

    React.useEffect(() => {
        obterAreas()
    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            setMessages([])
            setAgenteId("")
            setAgenteNome("")

            if (activeChatId) {
                if (selectedNivelFIlho || selectedNivel1) {
                    const threadId = await obterChatsMensagens();
                    await createThreadRun(threadId);
                }
            }

            if (selectedNivel1 || selectedNivelFIlho) {
                await obterChats();
            }
        };

        fetchData(); // Chama a função assíncrona
    }, [activeChatId, selectedNivel1, selectedNivelFIlho]);


    const createThreadRun = async (threadIdPromisse: string | undefined) => {
        const response = await httpClient.request({
            method: RequestMethod.GET,
            blockFree: false,
            endpoint: '/Assistant/CreateThread',
        })
        if (response.success && response.payload) {
            if (threadIdPromisse === undefined) setThreadId(response.payload)
            setMessage('')
            if (threadIdPromisse === undefined) editChat(null, response.payload, null)
        }
    }

    const editChat = async (nome: string | null, threadId: string | null, chatId: number | null) => {
        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/EditHistoricoChat',
            data: {
                Id: chatId == null ? activeChatId : chatId,
                ThreadId: threadId,
                Nome: nome
            },
        })
    }

    const obterChats = async () => {
        const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Assistant/ObterHistoricoChat',
            blockFree: false,
            queryObject: {
                idAgenteNivel: selectedNivelFIlho?.Id ? selectedNivelFIlho.Id : selectedNivel1?.Id
            }
        })

        if (response.success && response.payload) {
            setChatHistory(response.payload);
        }

    }

    const obterChatsMensagens = async (): Promise<string | undefined> => {
        const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Assistant/ObterHistoricoChatMensagem',
            queryObject: {
                idHistoricoChat: activeChatId
            }
        });

        if (response.success && response.payload) {
            const { mensagens, agente, historicoChat } = response.payload;

            setMessages(mensagens);
            setAgenteId(agente?.Id ?? "");
            setAgenteNome(agente?.Nome ?? "");

            const threadId: string | undefined = historicoChat?.ThreadId ?? undefined;
            setThreadId(threadId);

            return threadId;
        }

        return undefined;
    };

    const sendMessage = async () => {
        if (!threadId || !message) return
        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/CreateMessage',
            queryObject: {
                msg: message,
                threadId: threadId,
                idChatHistorico: activeChatId
            },
            blockFree: true
        })
        setMessage('')
        if (response.success && response.payload) {
            const lastMessage: IThreadMessageModel = response.payload
            setMessages(msgs => [...msgs, lastMessage])
            getResponseMessage(lastMessage)
            if (messages.length === 0) gerarTituloEditar(message)
        }
    }

    const gerarTituloEditar = async (texto: string) => {
        const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: '/Assistant/GerarTituloEditar',
            blockFree: true,
            queryObject: {
                texto: texto,
                idHistoricoChat: activeChatId
            }
        })
        if (response.success && response.payload) {
            obterChats()
        }
    }

    const sendMessageTranscript = async (text: string) => {
        if (!threadId || !text) return
        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/CreateMessage',
            queryObject: {
                msg: text,
                threadId: threadId,
                idChatHistorico: activeChatId
            },
            blockFree: true
        })
        setMessage('')
        if (response.success && response.payload) {
            const lastMessage: IThreadMessageModel = response.payload
            setMessages(msgs => [...msgs, lastMessage])
            getResponseMessageTranscript(lastMessage, text)
        }
    }

    const getResponseMessage = async (lastMessage: IThreadMessageModel) => {
        if (!threadId || !message) return

        setIsLoading(true);

        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/GetResponseMessageFirst',
            blockFree: true,
            queryObject: {
                threadId: threadId,
                lastMessageId: lastMessage.Id,
                idChatHistorico: activeChatId,
                IdMensagem: lastMessage.IdMensagem,
                mensagem: lastMessage.Texto,
                idAgenteNivel: selectedNivelFIlho?.Id ? selectedNivelFIlho.Id : selectedNivel1?.Id,
                AgenteId: agenteId
            },
        })

        setIsLoading(false);

        if (response.success && response.payload) {
            // setTextSpeech(removeTags(response.payload.ThreadMessage.Texto))
            setMessages(msgs => [...msgs, response.payload.ThreadMessage])
            setAgenteId(response.payload.AgenteId)
            if (response.payload.AgenteNome) setAgenteNome(response.payload.AgenteNome)
        }
    }

    const getResponseMessageTranscript = async (lastMessage: IThreadMessageModel, text: string) => {
        if (!threadId || !text) return

        setIsLoading(true);

        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/GetResponseMessageFirst',
            blockFree: true,
            queryObject: {
                threadId: threadId,
                lastMessageId: lastMessage.Id,
                idChatHistorico: activeChatId,
                IdMensagem: lastMessage.IdMensagem,
                mensagem: lastMessage.Texto,
                idAgenteNivel: selectedNivelFIlho?.Id ? selectedNivelFIlho.Id : selectedNivel1?.Id,
                AgenteId: agenteId
            },
        })

        setIsLoading(false);

        if (response.success && response.payload) {
            setTextSpeech(removeTags(response.payload.ThreadMessage.Texto))
            setMessages(msgs => [...msgs, response.payload.ThreadMessage])
            setAgenteId(response.payload.AgenteId)
            if (response.payload.AgenteNome) setAgenteNome(response.payload.AgenteNome)
        }
    }

    const createNewChat = async () => {
        const response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Assistant/CreateHistoricoChat',
            data: {
                ThreadId: threadId,
                IdAgenteNivel: selectedNivelFIlho?.Id ? selectedNivelFIlho.Id : selectedNivel1?.Id
            },
        })

        if (response.success && response.payload) {
            setActiveChatId(response.payload.Id);
        }

    };

    const handleRename = async () => {
        if (editingChatId && newTitle.trim()) {
            await editChat(newTitle, null, editingChatId)
            await obterChats()
        }
        setEditingChatId(null);
    };

    const obterAreas = async () => {
        await httpClient
            .request({
                method: RequestMethod.GET,
                endpoint: '/Administracao/AgenteNivel/ListarNiveisChat',
                blockFree: true,
            })
            .then((response) => {
                if (response.success && response.payload) {
                    setNiveis(response.payload.data);
                }
            });
    };

    const open = Boolean(anchorEl);
    const id = open ? "chat-popover" : undefined;

    const formatDate = (date: string) => {
        const chatDate = new Date(date);
        const day = chatDate.getDate().toString().padStart(2, '0');
        const month = (chatDate.getMonth() + 1).toString().padStart(2, '0');
        const year = chatDate.getFullYear();
        const hours = chatDate.getHours().toString().padStart(2, '0');
        const minutes = chatDate.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const formatMessage = (text: string) => {
        return text
            .replace(/<br><br>/g, "\n\n") // Substitui quebras duplas por parágrafo
            .replace(/<br>/g, "\n") // Substitui quebra simples por nova linha
            .replace(/(\d+\.)\s/g, "- ") // Formata a lista numerada para bullets
    }

    function formatTime(timestamp: string | number | Date): string {
        const date = new Date(timestamp);
        return date.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" });
    }


    return (
        <div className="chat-app">
            <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}${currentMode === 'dark' ? "" : "-white"}`}>
                <div className="sidebar-header">
                    <button className={`toggle-btn ${isSidebarOpen ? 'bi-layout-text-sidebar' : 'bi-layout-sidebar'}`} onClick={toggleSidebar} />
                    {isSidebarOpen && (
                        <button className="toggle-btn bi-plus-square" onClick={createNewChat} />
                    )}
                </div>
                <div className={`sidebar-list`}>
                    {isSidebarOpen && (
                        <>
                            {niveis && (
                                <div className={`area-section${currentMode === 'dark' ? "" : "-white"}`}>
                                    {
                                        niveis.map((nivel) => (
                                            <div key={nivel.Nivel1.Nome}>
                                                <div
                                                    className={`area-name${currentMode === 'dark' ? "" : "-white"} ${selectedNivel1 === nivel.Nivel1 ? "selected" : ""
                                                        }`}
                                                    onClick={() => {
                                                        if (selectedNivel1 === nivel.Nivel1) {
                                                            setExpandedNivel(expandedNivel === nivel.Nivel1 ? null : nivel.Nivel1);
                                                            setSelectedNivelFilho(null);
                                                            setMessages([]);
                                                            setActiveChatId(null)
                                                        } else {
                                                            setSelectedNivel1(nivel.Nivel1);
                                                            setSelectedNivelFilho(null);
                                                            setMessages([]);
                                                            setExpandedNivel(nivel.Nivel1);
                                                            setActiveChatId(null)
                                                        }
                                                    }}

                                                >
                                                    {nivel.Nivel1.Nome}
                                                </div>
                                                {expandedNivel === nivel.Nivel1 && (
                                                    <div className="subarea-list">
                                                        {nivel.Niveis.map((nivelFilho) => (
                                                            <div
                                                                key={nivelFilho.Nome}
                                                                className={`subarea-name${currentMode === 'dark' ? "" : "-white"} ${selectedNivelFIlho === nivelFilho ? "selected" : ""
                                                                    }`}
                                                                onClick={() => {
                                                                    setSelectedNivelFilho(nivelFilho);
                                                                    setSelectedNivel1(nivel.Nivel1); // Mantém a área selecionada
                                                                    setActiveChatId(null)
                                                                }}
                                                            >
                                                                {nivelFilho.Nome}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    }
                                </div>
                            )}
                            <ul className={`chat-history${currentMode === "dark" ? "" : "-white"}`} style={{ marginRight: '1rem' }}>
                                {["Hoje", "Ontem", "Últimos 7 Dias", ...Object.keys(groupedChats).filter(key => /^\d{4}$/.test(key)).sort((a, b) => Number(b) - Number(a))].map((group) => (
                                    <React.Fragment key={group}>
                                        {groupedChats[group] && groupedChats[group].length > 0 && (
                                            <>
                                                <li className="chat-group-title">{group}</li>
                                                {groupedChats[group].map((chat) => (
                                                    <React.Fragment key={chat.Id}>
                                                        <li
                                                            className={`chat-item ${chat.Id === activeChatId ? "active" : ""}`}
                                                            onClick={() => {
                                                                if (!editingChatId) setActiveChatId(chat.Id)
                                                            }
                                                            }
                                                        >
                                                            <div className="chat-item-content">
                                                                <div className="chat-item-left">
                                                                    {editingChatId === chat.Id ? (
                                                                        <input
                                                                            type="text"
                                                                            className="chat-title-input"
                                                                            value={newTitle}
                                                                            onChange={(e) => setNewTitle(e.target.value)}
                                                                            onKeyDown={(e) => e.key === "Enter" && handleRename()}
                                                                            autoFocus
                                                                        />
                                                                    ) : (
                                                                        <div className={`chat-title${currentMode === "dark" ? "" : "-white"}`}>
                                                                            {chat.Nome}
                                                                        </div>
                                                                    )}
                                                                    <div className="chat-timestamp">
                                                                        {formatDate(chat.DataCriacao)}
                                                                    </div>
                                                                </div>

                                                                {editingChatId === chat.Id ? (
                                                                    <button
                                                                        className="chat-item-button bi-check-lg"
                                                                        onClick={handleRename}
                                                                    />
                                                                ) : (
                                                                    <button
                                                                        className="chat-item-button bi-three-dots"
                                                                        data-chat-id={chat.Id}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleClick(e);
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </li>
                                                        <Popover
                                                            id={chat.Id.toString()}
                                                            open={!!open && anchorEl?.dataset.chatId === chat.Id.toString()}
                                                            anchorEl={anchorEl}
                                                            onClose={handleClose}
                                                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                                            transformOrigin={{ vertical: "top", horizontal: "left" }}
                                                            className="popover-table-actions"
                                                        >
                                                            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px d-block border border-3">
                                                                <div
                                                                    className="menu-item px-3"
                                                                    onClick={() => {
                                                                        setEditingChatId(chat.Id);
                                                                        setNewTitle(chat.Nome);
                                                                        handleClose();
                                                                    }}
                                                                >
                                                                    <span className="menu-link px-5">
                                                                        <span className="menu-icon" data-kt-element="icon">
                                                                            <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-2" />
                                                                        </span>
                                                                        <span className="menu-title">Renomear</span>
                                                                    </span>
                                                                </div>
                                                                <div className="menu-item px-3"
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        alert.createDialog({
                                                                            html: `Realmente deseja desativar esse chat?`,
                                                                            confirmAction: async () => {
                                                                                httpClient.request({
                                                                                    method: RequestMethod.PUT,
                                                                                    endpoint: '/Assistant/AtivarDesativar',
                                                                                    data: {
                                                                                        Id: chat.Id
                                                                                    }
                                                                                }).then(async (response) => {
                                                                                    if (response.success) {
                                                                                        await obterChats()
                                                                                        if (activeChatId === chat.Id) await setActiveChatId(null)
                                                                                    }
                                                                                })
                                                                            }
                                                                        })
                                                                        handleClose()
                                                                    }}
                                                                >
                                                                    <span className="menu-link px-5">
                                                                        <span className="menu-icon" data-kt-element="icon">
                                                                            <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-2" />
                                                                        </span>
                                                                        <span className="menu-title">Excluir</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                        <div className="linha-horizontal"></div>
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
            <div className="chat-window">
                {activeChatId ? (
                    <div className="chat-content">
                        <div className="messages">
                            {/* Exibe as mensagens */}
                            {messages.map((msg, index) => (
                                <div key={index} className={`message-container ${msg.Role === MessageRole.User ? "user-container" : "chat-container"}`}>
                                    <div className="message-header">
                                        {msg.Role === MessageRole.User ? (
                                            <>
                                                <span className="timestamp-user">{msg.Data} {msg.Hora}</span>
                                                <span className="sender-name-user">{msg.Role === MessageRole.User ? "Você" : agenteNome}</span>
                                                <img src={(fotoDataUrl && fotoDataUrl !== '' ? fotoDataUrl : toAbsoluteUrl('/media/avatars/blank.png'))} alt="Avatar" className="avatar-user" />
                                            </>
                                        ) : (
                                            <>
                                                <img src={toAbsoluteUrl('/media/pro4tech/Assistente.png')} alt="Avatar" className="avatar" />
                                                <span className="sender-name">{agenteNome}</span>
                                                <span className="timestamp">{msg.Data} {msg.Hora}</span>
                                            </>
                                        )}

                                    </div>
                                    <div className={`message ${msg.Role === MessageRole.User ? "user-message" : "chat-message"}`} dangerouslySetInnerHTML={{ __html: msg.Texto }} />
                                </div>
                            ))}
                            {isLoading && <div className="loading-dots">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                            </div>}
                        </div>
                        <div className="recording-indicator-container">
                            {isRecording && (
                                <div className="recording-indicator">
                                    <span className="recording-dot" />
                                    Gravando...
                                </div>
                            )}
                        </div>
                        <div className="message-input">
                            <input
                                type="text"
                                value={message}
                                disabled={isLoading}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    setMessage(inputValue);
                                    setIsWriting(inputValue.trim().length > 0);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        sendMessage();
                                        setIsWriting(false);
                                    }
                                }}
                                placeholder="Digite sua mensagem ou use o microfone..."
                            />
                            {isWriting ? (
                                <button
                                    disabled={isLoading}
                                    onClick={() => {
                                        sendMessage();
                                        setIsWriting(false);
                                    }}
                                    className="bi-send"
                                />
                            ) : (
                                <>
                                    {speechStatus === "started" ?
                                        (<>
                                            <button
                                                disabled={isLoading}
                                                onClick={stop}
                                                className={"bi-stop-fill"}
                                            ></button>
                                        </>)
                                        :
                                        (<>
                                            <button
                                                disabled={isLoading}
                                                onClick={isRecording ? handleStopRecording : handleStartRecording}
                                                className={`${isRecording ? "bi-stop-fill" : "bi-mic-fill"}`}
                                            ></button>
                                        </>)}
                                </>

                            )}

                        </div>
                    </div>
                ) : (
                    <div className="chat-placeholder">
                        <h1 className='fs-2hx fw-bolder text-bioma-2'>Vamos lá!</h1>
                        <p className='fs-2 text-muted'>Escolha um nível e inicie um novo chat para começar</p>
                    </div>
                )}
            </div>
        </div >
    );
};

export default ChatApp;