/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import {useAlert, useHttpClient } from '../../../../../modules/services/Bundle';
import { EnumPermissoes, EnumRoles, RequestMethod } from '../../../../../modules/services/core/_enums';
import { useAuth } from '../../../../../modules/auth';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { Popover } from '@mui/material';

interface Data {
  Id: number,
  Nome: string,
  Ativo: boolean,
}

interface Column {
  id: keyof Data;
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { 
    id: 'Nome', 
    label: 'NOME'
  },
  {
    id: 'Ativo',
    label: 'ATIVO',
  },
];

function createData(
  Id: number,
  Nome: string,
  Ativo: boolean
): Data {
  return { Id, Nome, Ativo};
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

const TablePerfis: React.FC = () => {  
  const httpClient = useHttpClient()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('Nome')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])   
  const alert = useAlert()
  const navigate = useNavigate()
  const [search, setSearch] = React.useState<string>('')
  const {auth} = useAuth()
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes
  const [popoverTableActionsAnchorEl, setPopoverTableActionsAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const popoverTableActionsOpen = Boolean(popoverTableActionsAnchorEl)

  const onKeyDownBuscar = (search: string) => {
    setSearch(search);
    resquestTable(page, orderBy, order, rowsPerPage, search, true);    
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead>
        <TableRow className='table-pro4tech-header'>
          {columns.map((columns) => (
            <TableCell
              className='table-pro4tech-header-cell'
              key={columns.id}
              align={columns.align ? 'right' : 'left'}
            >
              {columns.label}
            </TableCell>
          ))}
          <TableCell className='table-pro4tech-header-cell fw-bolder' align='center'>
            AÇÕES
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, search: any = null, block: boolean = false) => {
    
      httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/Administracao/Perfil/Listar',
        blockFree: block,
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPage,
          Search: search,
        }
      }).then((result) => {
          
          const newRows = result.payload.data.map((data: Data) => {
            return createData(data.Id, data.Nome, data.Ativo)
          })
          setRows([...newRows])
          setTotal(result.payload.total)
      })
    
  }

  React.useEffect(() => {
      resquestTable(page, orderBy, order, rowsPerPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rows.length;

  return (
    <>
      <form className='form'>
        <div>
          <div className='row' >         
            {/* begin::Input group */}
            <div className='mb-2 col-lg-4'>
              <label className='fw-bold text-pro4tech'>Pesquisar (Nome)</label>
              <input 
                type='text'
                onChange={e => onKeyDownBuscar(e.target.value)} 
                placeholder='Pesquisar (Nome)' 
                className={`form-control form-control-lg form-control-solid`}
                value={search}
              ></input>
            </div>
            {/* end::Input group */}
          </div>
          <Box sx={{ width: '100%' }}>
            <Paper className='table-pro4tech-border mb-0' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  className='table-pro4tech-header'
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-pro4tech-row'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-pro4tech-cell' align="left">{row.Nome}</TableCell>
                          <TableCell className='table-pro4tech-cell' align='left'>
                            <span className={`badge badge-light-${row.Ativo ? 'success' : 'danger'}`}>{row.Ativo ? 'Sim' : 'Não'}</span>
                          </TableCell>
                          <TableCell className='table-pro4tech-cell' align="center">

                            <div>

                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-active-light-primary btn-custom'
                                aria-describedby='table-actions'
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                  setPopoverTableActionsAnchorEl(event.currentTarget)
                                }}
                              >
                                <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-muted svg-icon-2hx' />
                              </button>
                              <Popover
                                id='table-actions'
                                open={popoverTableActionsOpen}
                                anchorEl={popoverTableActionsAnchorEl}
                                onClose={() => {
                                  setPopoverTableActionsAnchorEl(null)
                                }}
                                onClick={() => {
                                  setPopoverTableActionsAnchorEl(null)
                                }}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                className='popover-table-actions'
                              >
                                {/* begin::Menu */}
                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px d-block border border-3">

                                  {/* begin::Menu item */}
                                  <div className="menu-item px-3">
                                    <a 
                                      href='' 
                                      className="menu-link px-5"
                                      onClick={(e) => {
                                        e.preventDefault()

                                        navigate('/administracao/perfis/visualizar',
                                        { 
                                          state: 
                                          { 
                                            Id: row.Id,
                                            Acao: "Visualizar"
                                          }
                                        })
                                      }}
                                    >
                                      <span className="menu-icon" data-kt-element="icon">
                                        <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
                                      </span>
                                      <span className="menu-title">Visualizar</span>
                                    </a>
                                  </div>
                                  {/* end::Menu item */}

                                  {/* begin::Menu item */}
                                  {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes["Administração_Portal_Perfis_Editar"])) && (
                                    <div className="menu-item px-3">
                                      <a 
                                        href='' 
                                        className="menu-link px-5"
                                        onClick={(e) => {
                                          e.preventDefault()

                                          navigate('/administracao/perfis/editar',
                                          { 
                                            state: 
                                            { 
                                              Id: row.Id,
                                              Acao: "Editar"
                                            }
                                          })
                                        }}
                                      >
                                        <span className="menu-icon" data-kt-element="icon">
                                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                                        </span>
                                        <span className="menu-title">Editar</span>
                                      </a>
                                    </div>                                  
                                  )}         
                                  {/* end::Menu item */}

                                  {/* begin::Menu item */}
                                  {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes["Administração_Portal_Perfis_Ativar/Desativar"])) && (                                      
                                    <div className="menu-item px-3">
                                      <a 
                                        href='' 
                                        className="menu-link px-5"
                                        onClick={(e) => {
                                          e.preventDefault()

                                          alert.createDialog({
                                            html: `<label>Realmente deseja ${row.Ativo ? "desativar" : "ativar"} esse perfil?</label>
                                            <div class='mt-5'>
                                              <div class="alert alert-danger d-flex align-items-center p-5">
                                                <div class="d-flex flex-column">
                                                  <h5 class="mb-1">Atenção</h5>
                                                  <span>As permissões dos usuários vinculadas a esse perfil também serão ${row.Ativo ? "desativadas" : "ativadas"}!</span>
                                                </div>
                                              </div>
                                            </div>`,
                                            confirmAction: async () => {                              
                                                httpClient.request({
                                                    method: RequestMethod.PUT,
                                                    endpoint: '/Administracao/Perfil/AtivarDesativar',
                                                    data: row.Id
                                                }).then((response) => {
                                                    if (response.success) resquestTable(page, orderBy, order, rowsPerPage)                                   
                                                })
                                            }
                                          })
                                        }}
                                      >
                                        <span className="menu-icon" data-kt-element="icon">
                                          <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-2' />
                                        </span>
                                        <span className="menu-title">{row.Ativo ? "Desativar" : "Ativar"}</span>
                                      </a>
                                    </div>                                  
                                  )}         
                                  {/* end::Menu item */}

                                </div>
                                {/* end::Menu */}
                              </Popover>                             

                            </div>

                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows === 0 && (
                      <TableRow className='table-pro4tech-row'>
                        <TableCell className='table-pro4tech-cell text-center' colSpan={columns.length + 1}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-pro4tech-footer'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </div>
      </form>
    </>    
  )
}

export {TablePerfis}