import React, { useState } from 'react';
import '../../../../../styles/looks.css';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useAlert } from '../../../../modules/services/Bundle';

interface ModalSalvarLookProps {
    show: boolean;
    onClose: () => void;
}


const ModalJustificativaAgente: React.FC<ModalSalvarLookProps & { onConfirm: (justificativa: string) => void }> = ({
    show, onClose, onConfirm
}) => {
    const alert = useAlert();
    const [justificativaAgente, setJustificativaAgente] = useState<string>("");

    const validarJustificativa = () => {
        if (!justificativaAgente.trim()) {
            alert.createMessage({
                html: 'Por favor, descreva uma justificativa.',
                icon: 'warning',
            });
            return false;
        }
        return true;
    };

    const closeModal = () => {
        if (validarJustificativa()) {
            onClose();
        }
    };

    const salvarJustificativa = () => {
        if (validarJustificativa()) {
            onClose();
        }
    };

    return (
        <Modal
            className="bg-body"
            id="kt_header_search_modal"
            aria-hidden="true"
            dialogClassName="modal-fullscreen h-auto custom-modal-size"
            show={show}
            onHide={onClose}
            centered
        >
            <div className="card mb-5 mb-xl-10">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5>Justificativa</h5>
                    <div className="btn btn-icon btn-sm btn-light-primary ms-2" onClick={closeModal}>
                        <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                    </div>
                </div>
                <div className="card-body">
                    <p className="text-muted">
                        A justificativa é necessária para documentar as alterações realizadas. Isso garante maior transparência e controle sobre as modificações.
                    </p>
                    <textarea
                        className='form-control'
                        rows={5}
                        value={justificativaAgente}
                        onChange={(e) => setJustificativaAgente(e.target.value)}
                    />
                </div>
                <div className="card-footer py-4 d-flex justify-content-end">
                    <button type="button" className="btn btn-secondary" style={{ marginRight: '1rem' }} onClick={closeModal}>
                        Voltar
                    </button>
                    <button type="button" className="btn btn-lg btn-primary" onClick={() => {
                        if (validarJustificativa()) onConfirm(justificativaAgente)
                    }}>
                        Salvar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export { ModalJustificativaAgente };
