/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import { EnumPermissoes, EnumRoles } from '../../modules/services/core/_enums'
import { FeedbackAgentesPage } from './feedbackAgentes/FeedbackAgentesPage'

const CuradoriaPage: FC = () => {
    const { auth } = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes

    return (
        <Routes>
            <Route element={<Outlet />} >
                {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Curadoria_Agentes_Dúvidas Não Resolvidas_Visualizar'])) && (
                    <Route
                        path='duvidasnaoresolvidas/*'
                        element={
                            <>
                                <FeedbackAgentesPage />
                            </>
                        }
                    />
                )}

            </Route>
        </Routes>
    )
}

export { CuradoriaPage }
