/* eslint-disable react-hooks/exhaustive-deps */
import { SetStateAction, useEffect, useState } from 'react';
import { useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultValidationSchemas, initialValues } from './helpers/AgentesHelper';
import { IAgentePayloadModel, IAreasModel, IArquivoModel, IUsuarioSelectModel, IUsuariosSelectModel } from '../../../../modules/services/core/_models';
import Select from 'react-select'
import '../../../../../styles/agentes.css';
import { ChatAgente } from './ChatAgente';
import { Modal } from 'react-bootstrap';
import { ModalJustificativaAgente } from './ModalJustificativaAgente';

interface Dados {
  idAgente: string;
  idVersao: number | null;
  idJustificativa: number | null;
  visualizar: boolean;
  title: string;
}

const Agente = () => {
  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as Dados;
  const informacao: Dados = state;
  const { setBlocking } = useBlockUI();
  const [niveis1, setNiveis1] = useState<IAreasModel[]>([]);
  const [niveisFilho, setNiveisFilho] = useState<IAreasModel[]>([]);
  const [curadores, setCuradores] = useState<IUsuariosSelectModel[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [agentePayload, setAgentePayload] = useState<IAgentePayloadModel | null>(null)
  const [showJustificativaModal, setShowJustificativaModal] = useState(false);
  const [justificativa, setJustificativa] = useState("");
  const [pendingSubmitValues, setPendingSubmitValues] = useState<any | null>(null);

  const getFileExtension = (fileName: string): string => {
    return fileName.split('.').pop() || '';
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      Promise.all(
        files.map(file =>
          new Promise<IArquivoModel>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const base64String = (reader.result as string).split(",")[1];
              resolve({ Base64: base64String as string, Nome: file.name, Extensao: getFileExtension(file.name), Tamanho: file.size, Tipo: file.type, Url: null })
            };
            reader.onerror = error => reject(error);
          })
        )
      ).then(fileData => {
        formik.setFieldValue('Arquivos', [...formik.values.Arquivos, ...fileData]);
      });
    }
  };

  const removeFile = (index: number) => {
    const updatedFiles = formik.values.Arquivos.filter((_, i) => i !== index);
    formik.setFieldValue('Arquivos', updatedFiles);
  };

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: defaultValidationSchemas,
    onSubmit: async (values) => {
      // Se for uma edição e tiver JustificativaId, pedir justificativa antes de enviar
      if (informacao.idAgente && informacao.idJustificativa) {
        setPendingSubmitValues(values);  // Armazena os valores
        setShowJustificativaModal(true); // Abre a modal
        return;
      }

      // Se não precisar da justificativa, prossegue normalmente
      handleSubmit(values, justificativa);
    },
  });

  const handleSubmit = async (values: any, justificativaTexto: string) => {
    var curadoresFormatados = formik.values.Curadores.map((id) => ({ Id: id }));

    const data = {
      Id: informacao.idAgente ? informacao.idAgente : null,
      AgenteNivel1Id: values.AgenteNivel1Id,
      AgenteNivelFilhoId: values.AgenteNivelFilhoId == 0 ? null : values.AgenteNivelFilhoId,
      PalavraChave: values.PalavraChave,
      Descricao: values.Descricao,
      Nome: values.Assunto,
      Arquivos: values.Arquivos,
      QuebraGelo: values.QuebraGelo,
      Instrucoes: values.Instrucoes,
      Curadores: curadoresFormatados,
      Justificativa: justificativaTexto || null,
      IdJustificativa: informacao.idJustificativa
    };

    const response = await httpClient.request({
      method: informacao.idAgente ? RequestMethod.PUT : RequestMethod.POST,
      endpoint: `/Administracao/Agente/${informacao.idAgente ? 'Editar' : 'Adicionar'}`,
      data: data
    });

    if (response.success) {
      informacao.idAgente = response.payload.Id
      informacao.title = "Editar"
      informacao.visualizar = false
      setAgentePayload(response.payload)
    }
  }

  const handleJustificativaConfirm = (justificativaTexto: string) => {
    setShowJustificativaModal(false);
    if (pendingSubmitValues) {
      handleSubmit(pendingSubmitValues, justificativaTexto);
      setPendingSubmitValues(null);
    }
  };

  const obterAgente = async () => {
    const response = await httpClient.request({
      method: RequestMethod.GET,
      endpoint: `/Administracao/Agente/${(informacao.idVersao == 0 || informacao.idVersao == null) && (informacao.idAgente != null || informacao.idAgente != '') ? 'Obter' : 'ObterVersao'}`,
      queryObject: {
        idAgente: informacao.idAgente ? informacao.idAgente : informacao.idVersao,
      },
    });

    if (response.success && response.payload) {
      setAgentePayload(response.payload)
      await formik.setValues(response.payload);
      await formik.setFieldValue('Assunto', response.payload.Nome)
      await formik.setFieldValue('AgenteNivel1Id', response.payload.AgenteNivel1Id)
      // Garante que os curadores iniciais sejam preservados
      setCuradores((prevCuradores) => {
        const idsExistentes = new Set(prevCuradores.map(c => String(c.Id)));
        const novosCuradores = response.payload.Curadores.map((curador: { Id: any; Email: any; }) => ({
          Id: curador.Id,
          Nome: curador.Email
        })).filter((c: { Id: any; }) => !idsExistentes.has(String(c.Id)));

        return [...prevCuradores, ...novosCuradores];
      });

      await formik.setFieldValue(
        'Curadores',
        response.payload.Curadores.map((curador: { Id: any }) => curador.Id)
      );
      const agenteNivelFilhoId = response.payload.AgenteNivelFilhoId;
      await obterNiveisFilho(response.payload.AgenteNivel1Id, agenteNivelFilhoId).then(() => {
        formik.setFieldValue('AgenteNivelFilhoId', agenteNivelFilhoId);
      });
    }
  };

  const obterNiveis1 = async () => {
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Administracao/AgenteNivel/ObterParaSelect',
      })
      .then((response) => {
        if (response.success && response.payload) {
          setNiveis1(response.payload);
        }
      });
  };

  const obterCuradores = async (e: string) => {
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Administracao/Usuario/ObterTodosTiposParaSelect',
        queryObject: {
          search: e
        },
        blockFree: true
      })
      .then((response) => {
        if (response.success && response.payload) {
          setCuradores((prevCuradores) => {
            // Criar um conjunto de IDs existentes para evitar duplicatas
            const idsExistentes = new Set(prevCuradores.map(c => String(c.Id)));

            // Filtrar apenas os novos curadores que ainda não estão na lista
            const novosCuradores = response.payload.filter((c: { Id: any; }) => !idsExistentes.has(String(c.Id)));

            return [...prevCuradores, ...novosCuradores];
          });
        }
      });
  };


  const obterNiveisFilho = async (id: number | null, subArea: number | null) => {
    await httpClient
      .request({
        method: RequestMethod.GET,
        endpoint: '/Administracao/AgenteNivel/ObterParaSelectFilhos',
        queryObject: {
          id: id === null ? 0 : id
        },
        blockFree: true,
      })
      .then((response) => {
        if (response.success && response.payload) {
          setNiveisFilho(response.payload);
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setBlocking(true);

      if (informacao.idAgente || informacao.idVersao) {
        await obterAgente();
      }

      await obterNiveis1();
      setBlocking(false);
    };

    fetchData();
  }, [informacao]);


  return (
    <div className='row'>
      <div className={`${informacao.idVersao ? 'col-lg-12' : 'col-lg-6'} mb-10`}>
        <FormikProvider value={formik}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className='card card-bioma-agente h-100'>
              <div className='card-header'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>{informacao.title} Agente</h3>
                </div>
                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => navigate(-1)}
                    style={{ marginRight: "1rem" }}
                  >
                    Voltar
                  </button>
                  {informacao.title && informacao.title !== 'Visualizar' && (<button className='btn btn-primary'>{informacao.title}</button>)}

                </div>
              </div>
              <div className='card-body' style={{ overflowX: "auto", maxHeight: "70vh" }}>
                <div className='row'>
                  <div className='col-lg-12 fv-row mb-5'>
                    <label className='form-label required'>Nível 1</label>
                    <Select
                      placeholder='Selecione...'
                      className='react-select-styled react-select-lg react-select-solid'
                      classNamePrefix='react-select'
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      isDisabled={informacao && informacao.visualizar}
                      options={niveis1.map((data) => ({
                        value: data.Id,
                        label: data.Nome
                      }))}
                      value={
                        formik.values.AgenteNivel1Id
                          ? { value: formik.values.AgenteNivel1Id, label: niveis1.find(p => p.Id === formik.values.AgenteNivel1Id)?.Nome }
                          : null
                      }
                      isSearchable
                      isClearable
                      onChange={(selectedOption) => {
                        formik.setFieldValue('AgenteNivel1Id', selectedOption?.value || null)
                        obterNiveisFilho(selectedOption?.value || null, null)
                      }}
                    />
                    <ErrorMessage name='AgenteNivel1Id' component='div' className='text-danger mt-2' />
                  </div>
                  {niveisFilho.length > 0 && (
                    <div className='col-lg-12 fv-row mb-5'>
                      <label className='form-label'>Demais Níveis</label>
                      <Select
                        placeholder='Selecione...'
                        className='react-select-styled react-select-lg react-select-solid'
                        classNamePrefix='react-select'
                        noOptionsMessage={() => 'Nenhum registro encontrado'}
                        isDisabled={informacao && informacao.visualizar}
                        options={niveisFilho.map((data) => ({
                          value: data.Id,
                          label: data.Nome
                        }))}
                        value={
                          formik.values.AgenteNivelFilhoId
                            ? { value: formik.values.AgenteNivelFilhoId, label: niveisFilho.find(p => p.Id === formik.values.AgenteNivelFilhoId)?.Nome }
                            : null
                        }
                        isSearchable
                        isClearable
                        onChange={(selectedOption) => formik.setFieldValue('AgenteNivelFilhoId', selectedOption?.value || null)}
                      />
                      <ErrorMessage name='AgenteNivelFilhoId' component='div' className='text-danger mt-2' />
                    </div>
                  )}
                  <div className='mb-5'>
                    <label className='form-label required'>Assunto</label>
                    <Field
                      name='Assunto'
                      className='form-control'
                      placeholder='Insira o assunto principal do agente'
                      disabled={informacao && informacao.visualizar}
                    />
                    <ErrorMessage name='Assunto' component='div' className='text-danger mt-2' />
                  </div>
                  <div className='mb-5'>
                    <label className='form-label required'>Palavras-chave {`(Separe por ,)`}</label>
                    <Field
                      name='PalavraChave'
                      className='form-control'
                      placeholder='Insira as palavras-chave do agente'
                      disabled={informacao && informacao.visualizar}
                    />
                    <ErrorMessage name='PalavraChave' component='div' className='text-danger mt-2' />
                  </div>
                  <div className='mb-5'>
                    <label className='form-label required'>Descrição</label>
                    <Field
                      as='textarea'
                      name='Descricao'
                      className='form-control'
                      placeholder='Insira uma descrição do agente'
                      rows={4}
                      disabled={informacao && informacao.visualizar}
                    />
                    <ErrorMessage name='Descricao' component='div' className='text-danger mt-2' />
                  </div>
                  <div className="mb-5">
                    <label className="form-label required">Curador</label>
                    <Select
                      placeholder='Digite o e-mail e selecione o curador'
                      className='react-select-styled react-select-lg react-select-solid'
                      classNamePrefix='react-select'
                      noOptionsMessage={() => 'Nenhum registro encontrado'}
                      onInputChange={(e) => { obterCuradores(e) }}
                      isDisabled={informacao && informacao.visualizar}
                      options={curadores.map((data) => ({
                        value: String(data.Id), // Garante que é uma string
                        label: data.Nome,
                      }))}
                      value={curadores && curadores.length > 0 && formik.values.Curadores && formik.values.Curadores.length > 0 ? formik.values.Curadores.map((id) => ({
                        value: id,
                        label: curadores.find(x => String(x.Id) === String(id))?.Nome ?? ''
                      })) : []}
                      isMulti
                      isSearchable
                      isClearable
                      onChange={(selectedOptions) => {
                        const novosCuradores = selectedOptions.map(option => ({
                          Id: option.value,
                          Nome: option.label,
                        }));
                        setCuradores((prevCuradores) => {
                          const idsExistentes = new Set(prevCuradores.map(c => c.Id));
                          const curadoresAtualizados = [...prevCuradores, ...novosCuradores.filter(c => !idsExistentes.has(c.Id))];
                          return curadoresAtualizados;
                        });
                        formik.setFieldValue("Curadores", novosCuradores.map(c => c.Id));
                      }}
                    />
                    <ErrorMessage name='Curadores' component='div' className='text-danger mt-2' />
                  </div>
                  <div className='mb-5 position-relative'>
                    <label className='form-label required'>Instruções</label>
                    <div className='position-relative'>
                      <Field
                        as='textarea'
                        name='Instrucoes'
                        className='form-control position-relative'
                        placeholder='Especifique diretrizes ou parâmetros que definam como o agente deve interagir'
                        disabled={informacao && informacao.visualizar}
                        rows={4}
                        style={{ paddingBottom: '2.5rem' }}
                        maxLength={32000}
                      />
                      <button
                        type='button'
                        className='btn btn-outline-secondary bi-arrows-angle-expand position-absolute'
                        style={{ bottom: '5px', right: '5px' }}
                        onClick={() => {
                          setModalIsOpen(true)
                        }}
                      >
                      </button>
                    </div>
                    <ErrorMessage name='Instrucoes' component='div' className='text-danger mt-2' />
                  </div>
                  <div className='mb-5'>
                    <label className='form-label required'>Quebra-gelos</label>
                    <Field
                      as='textarea'
                      name='QuebraGelo'
                      className='form-control'
                      placeholder='Mensagem inicial para começar uma conversa com o usuário'
                      rows={4}
                      disabled={informacao && informacao.visualizar}
                    />
                    <ErrorMessage name='QuebraGelo' component='div' className='text-danger mt-2' />
                  </div>
                  <div className='mb-5'>
                    <label className="form-label">Anexar Arquivos</label>
                    <div>
                      <label htmlFor="fileUpload" className="form-control rounded-lg p-4  flex items-center cursor-pointer hover:border-blue-500 transition relative w-full">
                        <i className="bi bi-paperclip text-gray-500 text-xl mr-3" style={{ fontSize: "2rem" }}></i>
                        <span className="text-gray-600 flex-grow">Clique aqui e anexe arquivos para aumentar a precisão de respostas e tornar a comunicação mais eficiente</span>
                        <input
                          id="fileUpload"
                          type="file"
                          disabled={informacao && informacao.visualizar}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer selecionar-arquivos"
                          style={{ display: "none" }}
                          multiple
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                  </div>
                  {formik.values.Arquivos.length > 0 && (
                    <div className='mb-5'>
                      <label className='form-label'>Arquivos Anexados</label>
                      <ul>
                        {formik.values.Arquivos.map((file, index) => (
                          <li key={index} className='d-flex justify-content-between align-items-center mt-4'>
                            <span>{file.Nome}</span> {/* Exibe o nome do arquivo */}
                            <div className="d-flex gap-2">
                              {file.Url && (
                                <button
                                  type="button"
                                  className="btn-install-list bi bi-arrow-down-square"
                                  onClick={() => window.open(file.Url == null ? '' : file.Url, '_blank')}
                                />
                              )}
                              <button
                                type="button"
                                className="btn-remove-list bi bi-x"
                                onClick={() => removeFile(index)}
                                disabled={informacao && informacao.visualizar}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)} centered size='xl'  >
              <Modal.Header closeButton>
                <Modal.Title>Editar Instruções</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <textarea
                  className='form-control'
                  rows={20}
                  value={formik.values.Instrucoes}
                  onChange={(e) => formik.setFieldValue('Instrucoes', e.target.value)}
                  disabled={informacao && informacao.visualizar}
                  maxLength={32000}
                />
              </Modal.Body>
            </Modal>
            {showJustificativaModal && (
              <ModalJustificativaAgente
                show={showJustificativaModal}
                onClose={() => setShowJustificativaModal(false)}
                onConfirm={handleJustificativaConfirm}
              />
            )}
          </form>
        </FormikProvider>
      </div >
      {/* Lado direito - Espaço reservado para chat */}
      {!informacao.idVersao && (
        <div className='col-lg-6 mb-10'>
          <div className='card h-100 min-h-450px'>
            <ChatAgente id={agentePayload?.Id} idAgenteNivel={agentePayload?.AgenteNivelFilhoId ? agentePayload?.AgenteNivelFilhoId : agentePayload?.AgenteNivel1Id} nome={agentePayload?.Nome} />
          </div>
        </div>
      )}
    </div>
  );
};

export { Agente };
