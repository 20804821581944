import {RequestMethod} from './_enums'

export interface RequestModel {
  method: RequestMethod
  endpoint: string
  data?: any
  queryObject?: any
  headers?: any
  ifSuccessRedirectTo?: string
  blockFree?: boolean
}

export interface FileModel {
  Nome: string
  Extensao: string
  Tamanho: number
  Tipo: string
  Base64: string
  Url: string
}

export const defaultRequestModel: Partial<RequestModel> = {
  data: null,
  queryObject: null,
  headers: null,
  ifSuccessRedirectTo: '',
}

export interface ISelectModel {
  Id: number              
  Nome: string 
  Texto?: string
}

export interface JwtTokenModel {
  iss?: string;
  sub?: string;
  aud?: string[] | string;
  roles?: string[];
  exp?: number;
  nbf?: number;
  iat?: number;
  jti?: string;
}

export interface IAcaoModel {
  Id: number,
  Nome: string,
}

export interface IFuncionalidadeModel {
  Id: number,
  IdInterface: number,
  IdAcao: number,
  Codigo: string,
  Ativa: boolean,
  Acao: string,
}

export interface IPermissaoModel {
  Id: number,
  IdFuncionalidade: number,
  IdPerfil: number,
  Funcionalidade?: IFuncionalidadeModel,
}

export interface IInterfaceModel {
  Id: number,
  IdAplicacao: number,
  Nome: string,
  Rota: string,
  Icone: string,
  Administrativa: boolean,
  Funcionalidades: IFuncionalidadeModel[],
}

export interface IAplicacaoModel {
  Id: number,
  IdModulo: number,
  Nome: string,
  Url: string,
  Icone: string,
  Interfaces: IInterfaceModel[],
}

export interface IModuloModel {
  Id: number,
  Nome: string,
  Categoria: number,
  CategoriaNome: string,
  Icone: string,
  Aplicacoes: IAplicacaoModel[],
}

export interface IDadosMenuModel {
  Categoria: number,
  CategoriaNome: string,
  Modulos: IModuloModel[],
}

export interface IPerfilModel {
  Id: number,
  Nome: string,
  Permissoes: IPermissaoModel[],
}

export interface IProdutosModel {
  id: string,
  nome: string,
  categoria: string,
  imagem: string,
  isPrincipal: boolean | null
}

export interface INivelModel {
  Niveis: { Id: number | null; Nome: string, IdPai: number | null }[];
}


export interface ICombinacaoModel {
  id: string,
  prioridade: number,
  idPrincipal: string,
  idReferencia: string,
  nome: string,
}

export interface IProdutosSelecionadosModel {
  Id: string,
  Nome: string,
  Categoria: string,
  Imagem: string,
  IsPrincipal: boolean | null,
  Prioridade: number
}

export interface ICategoriasModel {
  Id: number,
  NomeSubGrupo: string,
}

export interface ICategoriasLookModel {
  Id: number,
  Descricao: string,
}

export interface IGruposModel {
  Id: number,
  NomeGrupo: string,
}

export interface IAreasModel {
  Id: number,
  Nome: string,
}

export interface IUsuarioSelectModel {
  Id: number,
  Nome: string,
}

export interface IUsuariosSelectModel {
  Id: string,
  Nome: string,
}

export interface IUsuarioModel {
  Id: string,
  Tipo: number,
  Nome: string,
  Email: string,
  Celular: string,
  Senha: string,
  Perfis: number[]
}

export interface IProdutoModel {
  CodigoSKU: string,
  NomeProduto: string,
  Imagem: string,
  Grupo: number,
  SubGrupo: number,
  Estoque: boolean,
  Ativo: boolean
}

export interface IAreaModel {
  Id: number,
  Nome: string,
  Descricao: string,
}

export interface IArquivoModel {
  Base64: string;
  Nome: string;
  Tipo: string;
  Tamanho: number;
  Extensao: string;
  Url: string | null;
}


export interface IAgenteModel {
  Id: number,
  AgenteNivel1Id: number,
  AgenteNivelFilhoId: number,
  PalavraChave: string,
  Assunto: string,
  Descricao: string,
  Curadores: string[],
  Instrucoes: string,
  QuebraGelo: string,
  Arquivos: IArquivoModel[]
}

export interface IAgentePayloadModel {
  Id: string,
  AgenteNivel1Id: number,
  AgenteNivelFilhoId: number,
  Nome: string,
  Descricao: string,
  Curadores: string[],
  Instrucoes: string,
  QuebraGelo: string,
  Arquivos: IArquivoModel[]
}

export interface ISubAreaModel {
  Id: number,
  Nome: string,
  Descricao: string,
  AreaId: number,
}

export interface ILookModel {
  Id: string,
  Nome: string,
  LookPrincipal: number,
  LookReferencia: number[],
}

export interface IPromptModel {
  Id: number,
  IdAplicacao: number,
  NomeAplicacao: string,
  Tipo: number,
  TipoNome: string,
  TipoDescricao: string,
  Parametros: string[],
  Mensagem: string,
}