import * as React from 'react';
import { Button } from '@mui/material';
import { KTSVG } from '../../../../../_metronic/helpers'; // Supondo que você tenha o KTSVG
import '../../../../../styles/agentes.css'; // Supondo que você tenha este arquivo de estilos
import { useNavigate } from 'react-router-dom';
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';
import { IUsuarioModel } from '../../../../modules/services/core/_models';
import '../../../../../styles/agentes.css';

interface CardComBotoesProps {
    numero: number;
    nome: string;
    dataCriacao: string;
    data: string;
    hora: string;
    responsavel: IUsuarioModel;
    responsavelCriacao: IUsuarioModel;
    id: string;
    onUpdate: () => void; // Nova prop para atualizar a tabela
}

const CardComBotoes: React.FC<CardComBotoesProps> = ({ numero, nome, dataCriacao, data, hora, responsavel, responsavelCriacao, id, onUpdate }) => {
    const httpClient = useHttpClient()
    const navigate = useNavigate()
    const alert = useAlert()

    return (
        <div className="card mb-5 shadow-sm rounded-lg agente-card">
            <div className="card-body d-flex justify-content-between">
                <div className="text-left">
                    <p className='agentes-card-subtitle'>Atualizado {numero} {numero > 1 || numero == 0 ? 'vezes' : 'vez'}</p>
                    <p className="agentes-card-title">Agente {nome}</p>
                    {data == "" && (<p className='agentes-card-text'>Data de criação: {dataCriacao.split(" ")[0]} às {dataCriacao.split(" ")[1]}</p>)}
                    {data != "" && (<p className='agentes-card-text'>Última atualização: {data} às {hora}</p>)}
                    {responsavel.Nome != "" && (<p className='agentes-card-text'>Atualizado por: {responsavel.Nome}</p>)}
                    {responsavel.Nome == "" && (<p className='agentes-card-text'>Criado por: {responsavelCriacao.Nome}</p>)}
                </div>
                <div className="d-flex gap-4 justify-content-end row margin-button button-lista-card">
                    <div className='col-lg-2 fv-row'>
                        <Button
                            className='btn btn-primary btn-icon'
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/administracao/agente/visualizar', {
                                    state: {
                                        idAgente: id,
                                        visualizar: true,
                                        title: "Visualizar"
                                    }
                                })
                            }}>
                            <i className="bi bi-eye" />
                        </Button>
                    </div>
                    <div className='col-lg-2 fv-row'>
                        <Button
                            className='btn btn-primary btn-icon'
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/administracao/agente/editar', {
                                    state: {
                                        idAgente: id,
                                        visualizar: false,
                                        title: "Editar"
                                    }
                                })
                            }}>
                            <i className="bi bi-pencil-square" />
                        </Button>
                    </div>
                    <div className='col-lg-2 fv-row'>
                        <Button
                            className='btn btn-primary btn-icon'
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/administracao/agente/listarVersao', {
                                    state: {
                                        idAgente: id,
                                        title: nome
                                    }
                                })
                            }}
                        >
                            <i className="bi bi-journal-text" />
                        </Button>
                    </div>
                    <div className='col-lg-2 fv-row' >
                        <Button
                            className='btn btn-primary btn-icon'
                            onClick={(e) => {
                                e.preventDefault()

                                alert.createDialog({
                                    html: `Realmente deseja desativar esse agente?`,
                                    confirmAction: async () => {
                                        httpClient.request({
                                            method: RequestMethod.DELETE,
                                            endpoint: '/Administracao/Agente/Deletar',
                                            data:JSON.stringify(id.trim())
                                        }).then((response) => {
                                            if (response.success) onUpdate();
                                        });
                                    }
                                })
                            }}>
                            <i className="bi bi-trash" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { CardComBotoes };
