/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useAlert, useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPermissoes, EnumRoles, EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums';
import { useAuth } from '../../../../modules/auth'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Checkbox, Popover } from '@mui/material';
import { ModalJustificativaListaVisualizacao } from './ModalJustificativaListaVisualizacao';

interface TableProps {
    pendente: boolean;
}

interface Data {
    Id: number,
    Data: string,
    Hora: string,
    AgenteNivelNome: string,
    TituloMensagem: string,
    UsuarioNome: string,
    DataFeedback: string,
    HoraFeedback: string,
    Justificativa: string,
    AgenteId: string,
}

interface Column {
    id: keyof Data
    label: string
    minWidth?: number
    align?: 'center'
    format?: (value: number) => string
}

const columns: readonly Column[] = [
    {
        id: 'Data',
        label: 'Data e Hora',
    },
    {
        id: 'AgenteNivelNome',
        label: 'Nível',
    },
    {
        id: 'TituloMensagem',
        label: 'Assunto',
    },
    {
        id: 'UsuarioNome',
        label: 'Responsavel',
    },
    {
        id: 'DataFeedback',
        label: 'Data do feedback',
    }
]

function createData(
    Id: number,
    Data: string,
    Hora: string,
    AgenteNivelNome: string,
    TituloMensagem: string,
    UsuarioNome: string,
    DataFeedback: string,
    HoraFeedback: string,
    Justificativa: string,
    AgenteId: string,
): Data {
    return { Id, Data, Hora, AgenteNivelNome, TituloMensagem, UsuarioNome, DataFeedback, HoraFeedback, Justificativa, AgenteId }
}

type Order = 'asc' | 'desc'

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
    order: Order
    orderBy: string
}

function EnhancedTableHead(props: EnhancedTableProps) {
    return (
        <TableHead>
            <TableRow className='table-pro4tech-header'>
                {columns.map((columns) => (
                    <TableCell
                        className='table-pro4tech-header-cell'
                        key={columns.id}
                        align={columns.align ? 'right' : 'left'}
                    >
                        {columns.label}
                    </TableCell>
                ))}
                <TableCell className='table-pro4tech-header-cell fw-bolder' align='center'>
                    AÇÕES
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

const TableResolvidos: React.FC<TableProps> = ({ pendente }) => {
    const httpClient = useHttpClient()
    const alert = useAlert()
    const blockUI = useBlockUI()
    const navigate = useNavigate()
    const [order, setOrder] = React.useState<Order>('asc')
    const [orderBy, setOrderBy] = React.useState<keyof Data>('Data')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [total, setTotal] = React.useState(0)
    const [rows, setRows] = React.useState<Data[]>([])
    const [selected, setSelected] = React.useState<number[]>([]);
    const { auth } = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
    const [popoverTableActionsAnchorEl, setPopoverTableActionsAnchorEl] = React.useState<(HTMLButtonElement | null)[]>([])
    const popoverTableActionsOpen = Boolean(popoverTableActionsAnchorEl)
    const [showJustificativaModal, setShowJustificativaModal] = React.useState(false);
    const [nomeAssuntoModal, setNomeAssuntoModal] = React.useState("");
    const [justificativaFeedbackModal, setJustificativaFeedbackModal] = React.useState("");

    const resquestTable = async (
        pageRq: number,
        orderByRq: string,
        orderRq: string,
        rowsPerPageRq: number,
        search: any = null,
        block: boolean = false,
    ) => {
        let response = await httpClient.request({
            method: RequestMethod.POST,
            endpoint: '/Administracao/Agente/ListarResolvidos',
            blockFree: block,
            data: {
                Order: orderRq,
                OrderBy: orderByRq,
                Page: pageRq,
                RowsPerPage: rowsPerPageRq,
                Search: search,
                Filters: {
                    Ativo: pendente,
                }
            }
        })

        if (response.success && response.payload) {
            let newRows = response.payload.data.map((data: Data) => {
                return createData(
                    data.Id,
                    data.Data,
                    data.Hora,
                    data.AgenteNivelNome,
                    data.TituloMensagem,
                    data.UsuarioNome,
                    data.DataFeedback,
                    data.HoraFeedback,
                    data.Justificativa,
                    data.AgenteId
                )
            })
            setRows([...newRows])
            setTotal(response.payload.total)

        }
    }

    React.useEffect(() => {
        obterDados()
    }, [pendente])

    const obterDados = async () => {
        blockUI.setBlocking(true)
        await obterDadosTabela()
        blockUI.setBlocking(false)
    }

    const obterDadosTabela = async () => {
        await resquestTable(page, orderBy, order, rowsPerPage)
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        resquestTable(newPage, orderBy, order, rowsPerPage)
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        resquestTable(page, orderBy, order, parseInt(event.target.value, 10))
        setPage(0)
    }

    const emptyRows = rows.length

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Paper className='table-pro4tech-border mb-0' sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table className='table-pro4tech-header' sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {rows.map((row, index) => {
                                    const isItemSelected = selected.indexOf(row.Id) !== -1;
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            className='table-pro4tech-row'
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell className='table-pro4tech-cell' align='left'>
                                                {row.Data} {row.Hora}
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='left'>
                                                {row.AgenteNivelNome}
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='left'>
                                                {row.TituloMensagem}
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='left'>
                                                {row.UsuarioNome}
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='left'>
                                                {row.DataFeedback} {row.HoraFeedback}
                                            </TableCell>
                                            <TableCell className='table-pro4tech-cell' align='center'>
                                                <div>
                                                    <button
                                                        type='button'
                                                        className='btn btn-sm btn-icon btn-active-light-primary btn-custom bi-chat-left-text svg-icon-muted svg-icon-2hx'
                                                        aria-describedby={`table-actions-${index}`}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setNomeAssuntoModal(row.TituloMensagem)
                                                            setJustificativaFeedbackModal(row.Justificativa)
                                                            setShowJustificativaModal(true)
                                                        }}
                                                    >
                                                    </button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {emptyRows === 0 && (
                                    <TableRow className='table-pro4tech-row'>
                                        <TableCell className='table-pro4tech-cell text-center' colSpan={columns.length + 2}>
                                            Nenhum registro encontrado
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className='table-pro4tech-footer'
                        labelRowsPerPage='linhas por página'
                        rowsPerPageOptions={[10, 25]}
                        component='div'
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                {showJustificativaModal && (
                    <ModalJustificativaListaVisualizacao
                        show={showJustificativaModal}
                        onClose={() => setShowJustificativaModal(false)}
                        nome={nomeAssuntoModal}
                        justificativa={justificativaFeedbackModal}
                    />
                )}
            </Box>
        </div>
    )
}

export { TableResolvidos }
