import * as Yup from 'yup'
import { EnumUsuarioTipo } from '../../../../../modules/services/core/_enums'
import { IUsuarioModel } from '../../../../../modules/services/core/_models'

const initialValues:IUsuarioModel = {
  Id: '',
  Tipo: 1,
  Nome: '',
  Email: '',
  Celular: '',
  Senha: '',
  Perfis: []
}

const defaultValidationSchemas = Yup.object().shape({
  Tipo: Yup.number().required("O tipo é obrigatório"),
  Nome: Yup.string()
    .max(150, 'O nome completo deve ter no máximo 150 caracteres')
    .matches(/^[A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ][a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{0,20}(?: [A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ][a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{0,20}){1,}$/ , 'O nome deve iniciar com letra maiúscula, ter apenas letras (A-Z) e cada palavra deve ter no máximo 20 caracteres')
    .required('O nome completo é obrigatório'),
  Email: Yup.string().required("O email é obrigatório")
  .when('Tipo', {
    is: EnumUsuarioTipo['Microsoft Externo'] || EnumUsuarioTipo['Domínio Externo'],
    then: Yup.string().email('O email é inválido')
  }).when('Tipo', {
    is: EnumUsuarioTipo['Microsoft Interno'],
    // eslint-disable-next-line no-useless-escape
    then: Yup.string().matches(/^[A-Za-z0-9\\\._-]{1,}$/, 'O email deve ter apenas letras (a-z), números (0-9) ou os seguintes caracteres especiais (._-)').max(64, 'O email deve ter entre 1 e 64 caracteres')
  }),
  Celular: Yup.string()
    .test('Celular', 'Celular inválido', (val = '') => {
      if (!val) return true
      return val.replace(/\D/g, '').length === 11
    })
    .required('O celular é obrigatório'), 
  Senha: Yup.string().when('Tipo', {
    is: EnumUsuarioTipo['Microsoft Interno'],
    then: Yup.string().min(8, 'A senha deve ter no mínimo 8 caracteres')
    .max(20, 'A senha deve ter no máximo 20 caracteres')
    // eslint-disable-next-line no-useless-escape
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!?%\-\=\\\/\[\]\{\}\(\)])[0-9a-zA-Z$*&@#!?%\-\=\\\/\[\]\{\}\(\)]{8,20}$/, 'A senha deve ter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 símbolo')
    .required('A senha é obrigatória')
  }),
})

export {defaultValidationSchemas, initialValues}
