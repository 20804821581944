import { TablePrompts } from './partials/TablePrompts'

const Prompts = () => {
  return (
    <>
      <div className='card mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Prompts</h3>
          </div>
        </div>

        <div className='card-body'>
          <TablePrompts />
        </div>
      </div>
    </>
  )
}

export {Prompts}
