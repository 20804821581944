/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAlert, useBlockUI, useHttpClient } from '../../../../modules/services/Bundle';
import { EnumPermissoes, EnumRoles, EnumUsuarioTipo, RequestMethod } from '../../../../modules/services/core/_enums';
import { useAuth } from '../../../../modules/auth'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Popover } from '@mui/material';
import Select from 'react-select'
import { IAreasModel, IUsuarioModel } from '../../../../modules/services/core/_models';
import '../../../../../styles/agentes.css';
import { CardComBotoes } from './CardAgentes';
import { number } from 'yup';
import { CardAgentesVersao } from './CardAgentesVersao';

interface Dados {
  idAgente: string;
  title: string;
}

interface Data {
  Id: number,
  Nome: string,
  DataUltimaAtualizacaoString: string,
  DataCriacaoString: string,
  Hora: string,
  UsuarioUltimaAtualizacao: IUsuarioModel,
  NumeroVersao: number
}

function createData(
  Id: number,
  Nome: string,
  DataUltimaAtualizacaoString: string,
  DataCriacaoString: string,
  Hora: string,
  UsuarioUltimaAtualizacao: IUsuarioModel,
  NumeroVersao: number
): Data {
  return { Id, Nome, DataUltimaAtualizacaoString, DataCriacaoString, Hora, UsuarioUltimaAtualizacao, NumeroVersao }
}

const AgentesVersao = () => {
  const httpClient = useHttpClient();
  const alert = useAlert();
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as Dados;
  const informacao: Dados = state;
  const [total, setTotal] = React.useState(0);
  const [rows, setRows] = React.useState<Data[]>([]);
  const { auth } = useAuth();
  const roles = auth!.Roles
  const permissoes = auth!.Permissoes
  const [listVersao, setListVersao] = React.useState<number[]>([]);

  const resquestTable = async () => {
    let response = await httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/Administracao/Agente/ListarVersao',
      blockFree: false,
      data: {
        Filters: {
          IdAgente: informacao.idAgente
        }
      }
    })

    if (response.success && response.payload) {
      let newRows = response.payload.data.map((data: Data) => {
        let hora = ""
        let dataString = ""
        if (data.DataUltimaAtualizacaoString != null) {
          hora = data.DataUltimaAtualizacaoString.split(" ")[1];
          dataString = data.DataUltimaAtualizacaoString.split(" ")[0];
        }
        setListVersao(prevList => [...prevList, data.NumeroVersao]);
        return createData(
          data.Id,
          data.Nome,
          dataString,
          data.DataCriacaoString,
          hora,
          data.UsuarioUltimaAtualizacao,
          data.NumeroVersao
        )
      })
      setRows([...newRows])
      setTotal(response.payload.total)

    }
  }

  React.useEffect(() => {
    obterDados()
  }, [])

  const obterDados = async () => {
    blockUI.setBlocking(true)
    await obterDadosTabela()
    blockUI.setBlocking(false)
  }

  const obterDadosTabela = async () => {
    await resquestTable()
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <div className='card-toolbar mt-2 d-flex justify-content-between w-100'>
            <span className="agentes-title text-2xl">Agente {informacao.title}</span>
            <div>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={() => navigate(-1)}
                style={{ marginRight: "1rem" }}
              >
                Voltar
              </button>
              {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes['Curadoria_Agentes_Gerenciamento de Agentes_Editar'])) && (
                <button
                  className='btn btn-primary'
                  title='Adicionar'
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/administracao/agente/editar', {
                      state: {
                        idAgente: informacao.idAgente,
                        visualizar: false,
                        title: "Editar"
                      }
                    })
                  }}
                >
                  Criar nova versão
                </button>
              )}
            </div>
          </div>
          <div className="mt-5">
            {rows.map((row, index) => {
              return (
                <CardAgentesVersao
                  numero={row.NumeroVersao}
                  nome={row.Nome}
                  dataCriacao={row.DataCriacaoString}
                  data={row.DataUltimaAtualizacaoString}
                  hora={row.Hora}
                  responsavel={row.UsuarioUltimaAtualizacao}
                  id={row.Id}
                  listNumero={listVersao}
                  onUpdate={obterDados}
                />
              )
            })}
          </div>
        </div>
      </div >
    </>
  )
}

export { AgentesVersao }
