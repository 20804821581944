import React from 'react';
import '../../../../../styles/looks.css';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../../_metronic/helpers';
import { ICombinacaoModel, IProdutosSelecionadosModel } from '../../../../modules/services/core/_models';
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';

interface ModalSalvarLookProps {
    produtos: IProdutosSelecionadosModel[];
    informacao: Dados;
    titulo: string;
    show: boolean;
    onClose: () => void;
    onUpdateProdutos: (updatedProdutos: IProdutosSelecionadosModel[]) => void;
}

interface Dados {
    idComposicao: string;
    tipo: number;
    visualizar: boolean;
    title: string;
}

const ModalSalvarLook: React.FC<ModalSalvarLookProps> = ({ produtos, show, onClose, onUpdateProdutos, informacao, titulo }) => {
    const httpClient = useHttpClient();
    const alert = useAlert()
    const [pageProdutos, setPageProdutos] = React.useState(0);
    const [rowsPerPageProdutos, setRowsPerPageProdutos] = React.useState(10);
    const [pageCombinacao, setPageCombinacao] = React.useState(0);
    const [rowsPerPageCombinacao, setRowsPerPageCombinacao] = React.useState(10);
    const [combinacao, setCombinacao] = React.useState<ICombinacaoModel[]>([]);
    const [tituloCadastro, setTituloCadastro] = React.useState<string>('');

    const handlePesquisa = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTituloCadastro(e.target.value);
    };

    const sortedProdutos = React.useMemo(() => {
        return [...produtos].sort((a, b) => {
            if (a.IsPrincipal) return -1;
            if (b.IsPrincipal) return 1;
            return a.Prioridade - b.Prioridade;
        });
    }, [produtos]);

    const handleChangePageProduto = (event: unknown, newPage: number) => {
        setPageProdutos(newPage);
    };

    const handleChangePageCombinacao = (event: unknown, newPage: number) => {
        setPageCombinacao(newPage);
    };

    const handleChangeRowsPerPageProduto = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPageProdutos(parseInt(event.target.value, 10));
        setPageProdutos(0);
    };

    const handleChangeRowsPerPageCombinacao = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPageCombinacao(parseInt(event.target.value, 10));
        setPageCombinacao(0);
    };

    const moveItemProduto = (currentIndex: number, direction: 'up' | 'down') => {
        const absoluteIndex = pageProdutos * rowsPerPageProdutos + currentIndex; // Índice absoluto no array completo
        const newProdutos = [...sortedProdutos];

        if (
            (direction === 'up' && absoluteIndex > 0) || // Verifica se pode mover para cima
            (direction === 'down' && absoluteIndex < newProdutos.length - 1) // Verifica se pode mover para baixo
        ) {
            const swapIndex = direction === 'up' ? absoluteIndex - 1 : absoluteIndex + 1;

            [newProdutos[absoluteIndex], newProdutos[swapIndex]] = [
                newProdutos[swapIndex],
                newProdutos[absoluteIndex],
            ];

            // Atualiza as prioridades com base na nova ordem
            newProdutos.forEach((produto, index) => {
                if (!produto.IsPrincipal) {
                    produto.Prioridade = index;
                }
            });

            onUpdateProdutos(newProdutos);
        }
    };

    const excluirProduto = (idProduto: string) => {
        // Filtra os produtos removendo o produto pelo id
        const novosProdutos = produtos.filter(produto => produto.Id !== idProduto);
        // Atualiza o estado com os novos produtos
        onUpdateProdutos(novosProdutos);

        // Remove o item da combinação também
        const novaCombinacao = combinacao.filter(item => item.idPrincipal !== idProduto && item.idReferencia !== idProduto);
        setCombinacao(novaCombinacao);
    };

    const salvarComposicao = async () => {
        try {

            if (produtos.length < 2) {
                return alert.createMessage({
                    html: 'Por favor, selecione pelo menos dois produtos para continuar.',
                    icon: 'warning',
                });
            }


            const dataSalvar = {
                model: {
                    Titulo: tituloCadastro
                },
                produtos: produtos,
            };

            const dataEditar = {
                model: {
                    Titulo: tituloCadastro,
                    Id: informacao.idComposicao
                },
                produtos: produtos,
            };

            await httpClient.request({
                method: informacao?.idComposicao ? RequestMethod.POST : RequestMethod.POST,
                endpoint: `/Estilo/ComposicaoLook/${informacao.idComposicao ? 'Editar' : 'Adicionar'}`,
                data: informacao.idComposicao ? dataEditar : dataSalvar,
                ifSuccessRedirectTo: '/estilo/look/lista',
            });
        } catch (error) {
            console.error("Erro ao salvar composição:", error);
        }
    };
    React.useEffect(() => {
        if (titulo != null || titulo !== '') setTituloCadastro(titulo)
    }, [titulo]);

    React.useEffect(() => {
        const gerarCombinacoes = () => {
            const combinacoesTemp: ICombinacaoModel[] = [];

            produtos.forEach(produtoPrincipal => {
                if (produtoPrincipal.IsPrincipal) {
                    const produtosNaoPrincipais = produtos.filter(p => !p.IsPrincipal);
                    produtosNaoPrincipais.forEach(p => {
                        combinacoesTemp.push({
                            id: `${produtoPrincipal.Id}-${p.Id}`,
                            prioridade: p.Prioridade, // Prioridade retirada diretamente do produto
                            idPrincipal: produtoPrincipal.Id,
                            idReferencia: p.Id,
                            nome: `${produtoPrincipal.Nome} + ${p.Nome}`,
                        });
                    });
                }
            });

            setCombinacao(combinacoesTemp);
        };

        gerarCombinacoes();
    }, [produtos]);

    return (
        <Modal
            className="bg-body"
            id="kt_header_search_modal"
            aria-hidden="true"
            dialogClassName="modal-fullscreen h-auto custom-modal-size"
            show={show}
            onHide={onClose}
            centered
        >
            <div className="card mb-5 mb-xl-10">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5>Salvar Look</h5>
                    <div className="btn btn-icon btn-sm btn-light-primary ms-2" onClick={onClose}>
                        <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                    </div>
                </div>
                <div className="px-8 pt-8 w-100">
                    <label className='form-label required' style={{fontSize:'1.2rem'}}>Título</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Insira o título do look"
                        value={tituloCadastro}
                        onChange={handlePesquisa}
                        disabled={informacao.title === 'Visualizar' && informacao.visualizar === true}
                    />
                </div>
                <div className="card-body">
                    <form className="form">
                        <Box sx={{ width: '100%' }}>
                            <Paper className="table-pro4tech-border mb-0" sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table className="table-pro4tech-header" aria-labelledby="tableTitle">
                                        <TableHead>
                                            <TableRow className="table-pro4tech-header">
                                                <TableCell className='table-pro4tech-header-cell' align="center">Mover</TableCell>
                                                <TableCell className='table-pro4tech-header-cell' align="left">Categoria</TableCell>
                                                <TableCell className='table-pro4tech-header-cell' align="left">Referência</TableCell>
                                                <TableCell className='table-pro4tech-header-cell' align="left">Produto</TableCell>
                                                <TableCell className='table-pro4tech-header-cell' align="center">Prioridade</TableCell>
                                                <TableCell className='table-pro4tech-header-cell' align="center">Ações</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedProdutos
                                                .slice(
                                                    pageProdutos * rowsPerPageProdutos,
                                                    pageProdutos * rowsPerPageProdutos + rowsPerPageProdutos
                                                )
                                                .map((row, index) => {
                                                    // Recalcula o índice absoluto para cada linha da tabela
                                                    const absoluteIndex = pageProdutos * rowsPerPageProdutos + index;

                                                    return (
                                                        <TableRow
                                                            key={row.Id}
                                                            className="table-pro4tech-row"
                                                            hover
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell className='table-pro4tech-cell' align="center">
                                                                {!row.IsPrincipal === true && (
                                                                    <>
                                                                        <button
                                                                            className="btn btn-icon btn-sm btn-light"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                moveItemProduto(index, 'up');
                                                                            }}
                                                                            disabled={
                                                                                absoluteIndex === 0 ||
                                                                                sortedProdutos[absoluteIndex - 1]?.IsPrincipal === true ||
                                                                                (informacao.title === 'Visualizar' && informacao.visualizar === true)
                                                                            }
                                                                            style={{ marginRight: '1rem' }}

                                                                        >
                                                                            ▲
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-icon btn-sm btn-light"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                moveItemProduto(index, 'down');
                                                                            }}
                                                                            disabled={
                                                                                absoluteIndex === sortedProdutos.length - 1
                                                                                || (informacao.title === 'Visualizar' && informacao.visualizar === true)
                                                                            }
                                                                        >
                                                                            ▼
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className='table-pro4tech-cell' align="left">{row.Categoria}</TableCell>
                                                            <TableCell className='table-pro4tech-cell' align="left">{row.Id}</TableCell>
                                                            <TableCell className='table-pro4tech-cell' align="left">{row.Nome}</TableCell>
                                                            <TableCell className='table-pro4tech-cell' align="center">
                                                                {row.IsPrincipal ? 'Principal' : row.Prioridade}
                                                            </TableCell>
                                                            <TableCell className='table-pro4tech-cell' align="center">
                                                                {!row.IsPrincipal === true && (
                                                                    <button
                                                                        className="btn btn-icon btn-sm btn-danger "
                                                                        onClick={() => excluirProduto(row.Id)}
                                                                        disabled={informacao.title === 'Visualizar' && informacao.visualizar === true}
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                                                                    </button>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {sortedProdutos.length === 0 && (
                                                <TableRow className='table-pro4tech-row'>
                                                    <TableCell className='table-pro4tech-cell text-center' colSpan={6}>
                                                        Nenhum registro encontrado
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    className="table-pro4tech-footer"
                                    labelRowsPerPage="Linhas por página"
                                    rowsPerPageOptions={[10, 25]}
                                    component="div"
                                    count={produtos.length}
                                    rowsPerPage={rowsPerPageProdutos}
                                    page={pageProdutos}
                                    onPageChange={handleChangePageProduto}
                                    onRowsPerPageChange={handleChangeRowsPerPageProduto}
                                />
                            </Paper>
                        </Box>
                    </form>
                    <h5 className='pt-4 pb-1'>Total de combinações: {combinacao.length}</h5>
                    <form className="form">
                        <Box sx={{ width: '100%' }}>
                            <Paper className="table-pro4tech-border mb-0" sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table className="table-pro4tech-header" aria-labelledby="tableTitle">
                                        <TableHead>
                                            <TableRow className="table-pro4tech-header">
                                                <TableCell className='table-pro4tech-header-cell' align="left">Combinação</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {combinacao
                                                .slice(
                                                    pageCombinacao * rowsPerPageCombinacao,
                                                    pageCombinacao * rowsPerPageCombinacao + rowsPerPageCombinacao
                                                )
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            key={row.id}
                                                            className="table-pro4tech-row"
                                                            hover
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell className='table-pro4tech-cell' align="left">{row.nome}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {combinacao.length === 0 && (
                                                <TableRow className='table-pro4tech-row'>
                                                    <TableCell className='table-pro4tech-cell text-center' colSpan={5}>
                                                        Nenhuma combinação encontrada
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    className="table-pro4tech-footer"
                                    labelRowsPerPage="Linhas por página"
                                    rowsPerPageOptions={[10, 25]}
                                    component="div"
                                    count={produtos.length}
                                    rowsPerPage={rowsPerPageProdutos}
                                    page={pageProdutos}
                                    onPageChange={handleChangePageCombinacao}
                                    onRowsPerPageChange={handleChangeRowsPerPageCombinacao}
                                />
                            </Paper>
                        </Box>
                    </form>
                </div>
                <div className="card-footer py-4 d-flex justify-content-end">
                    <button type="button" className="btn btn-secondary" style={{ marginRight: '1rem' }} onClick={onClose}>
                        Voltar
                    </button>
                    <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        onClick={() => salvarComposicao()}
                        disabled={informacao.title === 'Visualizar' && informacao.visualizar === true}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </Modal >
    );
};

export { ModalSalvarLook };
