import { FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardPage } from '../pages/dashboard/DashboardPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { AdministracaoPage } from '../pages/administracao/AdministracaoPage'
import { EnumAutenticacaoTipo } from '../modules/services/core/_enums'
import ContaPage from '../pages/conta/ContaPage'
import { EstiloPage } from '../pages/estilo/EstiloPage'
import ChatApp from '../pages/chat/Chat'
import { CuradoriaPage } from '../pages/curadoria/CuradoriaPage'

const PrivateRoutes = () => {
  const { auth } = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='dashboard' element={<DashboardPage />} />
        {auth && auth.Tipo === EnumAutenticacaoTipo.AspNetID && (
          <Route path='conta/*' element={<ContaPage />} />
        )}
        <Route
          path='administracao/*'
          element={
            <SuspensedView>
              <AdministracaoPage />
            </SuspensedView>
          }
        />
        <Route
          path='chat'
          element={
            <ChatApp />
          }
        />
        <Route
          path='estilo/*'
          element={
            <SuspensedView>
              <EstiloPage />
            </SuspensedView>
          }
        />
        <Route
          path='curadoria/*'
          element={
            <SuspensedView>
              <CuradoriaPage />
            </SuspensedView>
          }
        />
        <Route index element={<Navigate to='/dashboard' />} />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
