import * as Yup from 'yup'
import { ISubAreaModel } from '../../../../../modules/services/core/_models'

const initialValues: ISubAreaModel = {
  Id: 0,
  Nome: '',
  Descricao: '',
  AreaId: 0
}

const defaultValidationSchemas = Yup.object().shape({
  Nome: Yup.string()
    .required('O Nome é obrigatório'),
  
  AreaId: Yup.number().required('A área é obrigatório').min(1, 'A área é obrigatório'),

  Descricao: Yup.string()

})

export { defaultValidationSchemas, initialValues }
