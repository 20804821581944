import React, { useState } from 'react';
import '../../../../../styles/looks.css';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';

interface ModalJustificativaListaProps {
    ids: number[];
    show: boolean;
    onClose: () => void;
}

const ModalJustificativaLista: React.FC<ModalJustificativaListaProps> = ({ show, onClose, ids }) => {
    const alert = useAlert();
    const httpClient = useHttpClient();
    const [justificativaAgente, setJustificativaAgente] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false); // Estado de loading

    const validarJustificativa = () => {
        if (!justificativaAgente.trim()) {
            alert.createMessage({
                html: 'Por favor, descreva uma justificativa.',
                icon: 'warning',
            });
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        if (!validarJustificativa()) return;

        setLoading(true); // Ativar loading

        try {
            const data = {
                Ids: ids,
                Justificativa: justificativaAgente,
            };

            const response = await httpClient.request({
                method: RequestMethod.POST,
                endpoint: `/Administracao/Agente/JustificativaFeedbackLista`,
                data: data,
            });

            if (response.success) {
                alert.createMessage({
                    html: 'Justificativa salva com sucesso!',
                    icon: 'success',
                }).then(() => {
                    onClose(); // Fechar modal após sucesso
                    setJustificativaAgente(""); // Limpar campo após salvar
                    window.location.reload()
                })
            } else {
                alert.createMessage({
                    html: 'Erro ao salvar. Tente novamente.',
                    icon: 'error',
                });
            }
        } catch (error) {
            alert.createMessage({
                html: 'Falha ao conectar com o servidor.',
                icon: 'error',
            });
        } finally {
            setLoading(false); // Desativar loading
        }
    };

    return (
        <Modal
            className="bg-body"
            id="kt_header_search_modal"
            aria-hidden="true"
            dialogClassName="modal-fullscreen h-auto custom-modal-size"
            show={show}
            onHide={onClose}
            centered
        >
            <div className="card mb-5 mb-xl-10">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5>Justificativa</h5>
                    <button className="btn btn-icon btn-sm btn-light-primary ms-2" onClick={onClose}>
                        <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                    </button>
                </div>
                <div className="card-body">
                    <p className="text-muted">
                        A justificativa é necessária para documentar as alterações realizadas. Isso garante maior transparência e controle sobre as modificações.
                    </p>
                    <textarea
                        className="form-control"
                        rows={5}
                        value={justificativaAgente}
                        onChange={(e) => setJustificativaAgente(e.target.value)}
                    />
                </div>
                <div className="card-footer py-4 d-flex justify-content-end">
                    <button type="button" className="btn btn-secondary me-2" onClick={onClose}>
                        Voltar
                    </button>
                    <button type="button" className="btn btn-lg btn-primary" onClick={handleSave} disabled={loading}>
                        {loading ? "Salvando..." : "Salvar"}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export { ModalJustificativaLista };
