import * as Yup from 'yup'
import { INivelModel } from '../../../../../modules/services/core/_models'


const initialValues: INivelModel = {
  Niveis: [{ Id: null, Nome: '', IdPai: null }]
};


const defaultValidationSchemas = Yup.object().shape({
});


export {defaultValidationSchemas, initialValues}
