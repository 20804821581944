// LandingPage.tsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../../modules/services/Bundle";
import { RequestMethod } from "../../modules/services/core/_enums";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import '../../../styles/landingPage.css'
import { Carousel } from "react-bootstrap";

interface CarouselItem {
    Image: string;
    Link: string;
    Titulo: string;
}

interface Data {
    ImagePrincipal: string;
    Titulo: string;
    CarouselItems: CarouselItem[];
}

const LandingPage: React.FC = () => {
    const httpClient = useHttpClient();
    const { id } = useParams();
    const [data, setData] = useState<Data | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0); // Estado para armazenar o índice da imagem ativa
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError(null);
                let response = await httpClient.request({
                    method: RequestMethod.GET,
                    endpoint: '/Estilo/Landing/ObterLanding',
                    blockFree: false,
                    queryObject: {
                        IdComposicaoLook: id,
                    }
                })
                const result: Data = await response.payload.data;

                setData(result);
            } catch (error: any) {
                console.error("Erro ao buscar dados:", error);
                setError(error.message || "Erro desconhecido.");
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchData();
        }

        // Atualiza isMobile quando a tela for redimensionada
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [id]);

    if (loading) return <p>Carregando...</p>;
    if (error) return <p>Erro: {error}</p>;
    if (!data) return <p>Nenhum dado encontrado.</p>;

    const getButtonLink = () => data.CarouselItems[activeIndex]?.Link || "#";

    const handleSelect = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };

    return (
        <div
            className="container-fluid d-flex flex-column align-items-center p-3"
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/debrum/fundo-landing.png')})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
                minHeight: '100vh',
            }}
        >
            <img
                alt='Logo'
                src={toAbsoluteUrl('/media/debrum/logo-escuro.png')}
                style={{ maxHeight: "7rem" }}
            />
            <div className="container mt-5">
                <div className="row justify-content-center align-items-center text-center">
                    <div className="col-lg-6 col-12 mb-4 mb-lg-0">
                        <h3 className="mb-10 tamanho-titulo fonte-titulo" style={{ color: '#222221', fontSize: '2rem', fontFamily: 'Argumentum Regular 10' }}>{data.Titulo}</h3>
                        <img
                            src={data.ImagePrincipal}
                            alt="Imagem 1"
                            className="img-fluid rounded w-100 h-100 mb-3 image-landing"
                        />
                    </div>

                    {/* Carrossel de imagens */}
                    <div className="col-lg-6 col-12 mb-4 mb-lg-0">
                    <h3 className="mb-10 tamanho-titulo fonte-titulo" style={{ color: '#222221', fontSize: '2rem', fontFamily: 'Argumentum Regular 10' }}>
                            {data.CarouselItems[activeIndex]?.Titulo}
                        </h3>
                        <Carousel
                            activeIndex={activeIndex}
                            onSelect={handleSelect}
                            id="carouselExampleControls"
                            className="carousel slide mb-3 custom-carousel carousel-espaco-imagem"
                            data-bs-ride="carousel"
                            style={{
                                maxWidth: '500px',
                                maxHeight: '700px'
                            }}
                            interval={null}
                        >
                            {data.CarouselItems.map((item, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100 rounded image-landing"
                                        src={item.Image}
                                        alt={`Slide ${index + 1}`}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="w-100 text-center mt-4">
                <button
                    className="btn btn-lg px-12 py-8 fonte-botao"
                    style={{
                        backgroundColor: "#222221",
                        borderColor: "#222221",
                        color: "#fff",
                        fontSize: "3rem",
                    }}
                    onClick={() => {
                        window.open(getButtonLink(), "_blank"); // Redireciona para o link baseado no índice
                    }}
                >
                    Compre agora!
                </button>
            </div>
        </div>
    );
};

export default LandingPage;
