import React, { useEffect, useState } from 'react';
import '../../../../../styles/looks.css';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useAlert, useHttpClient } from '../../../../modules/services/Bundle';
import { useNavigate } from 'react-router-dom';
import { RequestMethod } from '../../../../modules/services/core/_enums';
import {
    Input
} from '@mui/material';

interface ModalSalvarLookProps {
    show: boolean;
    onClose: () => void;
    idAgente: string;
    idFeedback: number;
}

interface FeedBack {
    Data: string,
    Hora: string,
    AgenteNivelNome: string,
    TituloMensagem: string,
    Pergunta: string,
    Resposta: string
}


const ModalMensagensAgenteNaoEncontrado: React.FC<ModalSalvarLookProps> = ({
    show, onClose, idAgente, idFeedback
}) => {
    const alert = useAlert();
    const httpClient = useHttpClient();
    const navigate = useNavigate();
    const [feedback, setFeedBack] = useState<FeedBack>();

    const obterAgente = async () => {
        const response = await httpClient.request({
            method: RequestMethod.GET,
            endpoint: `/Administracao/Agente/ObterFeedbackAgenteNaoEncontrado`,
            queryObject: {
                idFeedback: idFeedback,
            },
        });

        if (response.success && response.payload) {
            setFeedBack(response.payload)
        }
    }

    useEffect(() => {
        obterAgente()
    }, [])


    return (
        <Modal
            className="bg-body"
            id="kt_header_search_modal"
            aria-hidden="true"
            dialogClassName="modal-fullscreen h-auto custom-modal-size"
            show={show}
            centered
        >
            <div className="card mb-5 mb-xl-10">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5>Conversa pendente</h5>
                    <div className="btn btn-icon btn-sm btn-light-primary ms-2" onClick={onClose}>
                        <KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
                    </div>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='mb-5 col-lg-12'>
                            <label className='form-label required'>Assunto</label>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                value={feedback?.TituloMensagem}
                                placeholder='Insira o assunto principal do agente'
                                disabled={true}
                            />
                        </div>
                        <div className='mb-5 col-lg-6'>
                            <label className='form-label required'>Data e hora</label>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                value={`${feedback?.Data}  ${feedback?.Hora}`}
                                placeholder='Insira o assunto principal do agente'
                                disabled={true}
                            />
                        </div>
                        <div className='mb-5 col-lg-6'>
                            <label className='form-label required'>Nível</label>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                value={feedback?.AgenteNivelNome}
                                placeholder='Insira o assunto principal do agente'
                                disabled={true}
                            />
                        </div>
                        <div className='mb-5 col-lg-12'>
                            <label className='form-label required'>Pergunta</label>
                            <textarea
                                className='form-control form-control-lg form-control-solid'
                                rows={3}
                                value={feedback?.Pergunta}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer py-4 d-flex justify-content-end">
                    <button type="button" className="btn btn-secondary" style={{ marginRight: '1rem' }} onClick={onClose}>
                        Voltar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export { ModalMensagensAgenteNaoEncontrado };
