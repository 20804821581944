/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../../modules/services/core/Breadcrumb'
import { Areas } from './components/Areas'
import { Area } from './components/Area'
import { EnumPermissoes, EnumRoles } from '../../../modules/services/core/_enums'
import { useAuth } from '../../../modules/auth'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Administração',
    path: '',
  },
]

const wizardsBreadCrumbsUsuarios: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Administração',
    path: '',
  },
  {
    titulo: 'Áreas',
    path: '/administracao/area/lista',
  }
]

const AreasPage: FC = () => {
    const {auth} = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
  
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='lista'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbs}>Áreas</BreadcrumbLayout>
                <Areas />
              </>
            }
          />

          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Áreas_Adicionar)) && (
              <Route
                path='adicionar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Adicionar Área</BreadcrumbLayout>
                    <Area />
                  </>
                }
              /> 
          )}
          
          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Áreas_Editar)) && (
              <Route
                path='editar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Editar Área</BreadcrumbLayout>
                    <Area />
                  </>
                }
              /> 
          )}          

          <Route
            path='visualizar'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbsUsuarios}>Visualizar Área</BreadcrumbLayout>
                <Area />
              </>
            }
          /> 

          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route index element={<Navigate to='/administracao/area/lista' />} />
        </Route>
      </Routes>
    )
}

export {AreasPage}
