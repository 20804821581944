import React, { useState } from 'react';
import '../../../../../styles/looks.css';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../../_metronic/helpers';
import { IProdutosSelecionadosModel } from '../../../../modules/services/core/_models';
import { ModalSalvarLook } from './ModalSalvarLook';

interface Dados {
    idComposicao: string;
    tipo: number;
    visualizar: boolean;
    title: string;
}

interface ModalVisualizarLookProps {
    produtos: IProdutosSelecionadosModel[]; // Lista de produtos passada como propriedade
    informacao: Dados;
    titulo: string;
    show: boolean; // Controla a visibilidade da modal
    onClose: () => void; // Função para fechar a modal
    onUpdateProdutos: (updatedProdutos: IProdutosSelecionadosModel[]) => void;
}

const ModalVisualizarLook: React.FC<ModalVisualizarLookProps> = ({ produtos, show, onClose, onUpdateProdutos, informacao, titulo }) => {
    const [isModalOpenSalvar, setIsModalOpenSalvar] = useState(false);

    return (
        <>
            <Modal
                className="bg-body"
                id="kt_header_search_modal"
                aria-hidden="true"
                dialogClassName="modal-fullscreen h-auto custom-modal-size"
                show={show}
                onHide={onClose}
                centered
            >
                <div className="card mb-5 mb-xl-10">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5>Produtos Selecionados</h5>
                        <div className='btn btn-icon btn-sm btn-light-primary ms-2' onClick={onClose}>
                            <KTSVG className='svg-icon-2' path='/media/icons/duotune/arrows/arr061.svg' />
                        </div>
                    </div>
                    <div className="card-body">
                        <div
                            className="product-list"
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '20px',
                                justifyContent: 'center',
                            }}
                        >
                            {produtos.map((produto) => (
                                <div
                                    key={produto.Id}
                                    style={{
                                        position: 'relative', // Necessário para posicionar o aviso sobre a imagem
                                        textAlign: 'center',
                                        maxWidth: '200px',
                                    }}
                                >
                                    {produto.IsPrincipal && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '10px',
                                                left: '10px',
                                                backgroundColor: '#ffc107',
                                                color: '#000',
                                                padding: '5px 10px',
                                                borderRadius: '5px',
                                                fontWeight: 'bold',
                                                zIndex: 1,
                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                            }}
                                        >
                                            Produto Principal
                                        </div>
                                    )}
                                    <img
                                        src={produto.Imagem}
                                        alt={produto.Nome}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            objectFit: 'cover',
                                            borderRadius: '5px',
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <p>{produto.Nome}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="card-footer py-4 d-flex justify-content-end">
                        <button
                            type='button'
                            className='btn btn-secondary'
                            style={{ marginRight: '1rem' }}
                            onClick={() => onClose()}
                        >
                            Voltar
                        </button>
                        <button
                            type='button'
                            className={`btn btn-lg btn-primary ${informacao?.visualizar ? 'd-none' : ''}`}
                            onClick={() => setIsModalOpenSalvar(true)}
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </Modal>
            {isModalOpenSalvar && (
                <ModalSalvarLook
                    titulo={titulo}
                    informacao={informacao}
                    onUpdateProdutos={onUpdateProdutos}
                    produtos={produtos}
                    show={isModalOpenSalvar}
                    onClose={() => setIsModalOpenSalvar(false)}
                />
            )}
        </>
    );
};

export { ModalVisualizarLook };
