import * as Yup from 'yup'
import { IAreaModel } from '../../../../../modules/services/core/_models'

const initialValues: IAreaModel = {
  Id: 0,
  Nome: '',
  Descricao: '',
}

const defaultValidationSchemas = Yup.object().shape({
  Nome: Yup.string()
    .required('O Nome é obrigatório'),

  Descricao: Yup.string()

})

export { defaultValidationSchemas, initialValues }
