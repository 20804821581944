import "../../../../main.css";
import { Login } from "@microsoft/mgt-react";
import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import { getToken } from "../../services/Bundle";
import { EnumAutenticacaoTipo } from "../../services/core/_enums";
import { AuthModel } from "../core/_models";
import { useNavigate } from "react-router-dom";

const LoginGraph = () => {
  const [loginCompleted, setLoginCompleted] = useState(false)
  const {auth, saveAuth} = useAuth()
  const navigate = useNavigate()
  
  const loginWithGraph = () => {
    
    const btnLogin = document.getElementById('login-mtg-graph')?.shadowRoot?.getElementById('login-button')
    
    if (btnLogin) btnLogin.click()
  }

  const reLogin = async () => {    
    if (loginCompleted && !auth) {
      const newAuth: AuthModel = {
        Token: await getToken(),
        Tipo: EnumAutenticacaoTipo.MSEntraID,
        User: {
          IdUsuario: '',
          DataCadastro: '',
          Nome: '',
          Email: '',
          Celular: '',
          AutenticacaoEmDoisFatores: false,
          FotoDataUrl: '',
        },
        Roles: [],
        Permissoes: []
      }
      
      saveAuth(newAuth) 

      navigate('/dashboard')
    }
  }

  useEffect(() => {
    reLogin()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginCompleted])

  return (
    <div className="row">
      <div className="col-lg-12 d-flex justify-content-center">
        <div className="row">
          <button
            onClick={() => loginWithGraph()}
            type="button"
            className={`btn btn-lg btn-secondary ${loginCompleted ? 'd-none' : ''}`}
          >
            <img className="me-2" src={toAbsoluteUrl("/media/svg/avatars/icons8-microsoft.svg")} width="30px" alt="" />Entrar com Microsoft
          </button>

          <div className={`${loginCompleted ? '' : 'd-none'}`}><h2 className="text-muted">Entrando...</h2></div>
          
          <Login 
            show-presence
            id="login-mtg-graph"
            loginCompleted={() => setLoginCompleted(true)}
            className={`w-100 login-mtg-graph d-none`}
          />
        </div>
      </div>
    </div>
  );
}

export {LoginGraph}
