export enum EnumRoles {
  Administrador = 'Administrador',
}

export enum EnumPermissoes {
  // Áreas
  'Administração_Portal_Áreas_Adicionar' = 'E504D45C629C47C5B4D26D8B9F9167F1967082A3232867BECC4B23FAA635AE2E',
  'Administração_Portal_Áreas_Ativar/Desativar' = '066B3A6FF0B6EF41D64AE3E65CDC1690B088804661D851FAAE3964A25A882E19',
  'Administração_Portal_Áreas_Editar' = 'B97A186A0F795026FB4B050DDDF51E890F36009AA65DAA8603DDDD3EE6CFB3D1',
  'Administração_Portal_Áreas_Visualizar' = '90C0C51C2CC7CF154947BDFD4DD48032871F233842BB6FE106F0120D734BA1B1',

  // Perfis
  'Administração_Portal_Perfis_Adicionar' = '27C4BBD3C6D066DFFDCCD291DD62528F982FACE75C60A42C61B029CE0CC7F247',
  'Administração_Portal_Perfis_Ativar/Desativar' = '3FE674F71F31978FCB60036808D85E6B9DF4B135129D79EEBC5C00EAACFA6698',
  'Administração_Portal_Perfis_Deletar' = '4D713997B35FA8BB8F63293951DC9ABC46D3CC20720D1E3DEE04572B72E4A734',
  'Administração_Portal_Perfis_Editar' = 'DE64BC92617F7E703825DED7ED9BE39768BF37D4B533A979D00B0DEDDD950C4E',
  'Administração_Portal_Perfis_Visualizar' = '712B2F74A47DB50D459236EFCE9B95202C26033E48CD85CBDE6BB1CDB6470D18',
  
  // Subáreas
  'Administração_Portal_Subáreas_Adicionar' = 'B2821603EBE508C49081C7DB7F7CFB681F3BAFEB23C1C528564473262D9E9616',
  'Administração_Portal_Subáreas_Ativar/Desativar' = '266A9ED85BED656A5ADD0CA30C99D9BEB493642CF56E615E35F5BDC616920CCC',
  'Administração_Portal_Subáreas_Editar' = '518DD1BAD2192B3E44B47F566D3029CA28148C3661687BA7D2D27C66246C7CE9',
  'Administração_Portal_Subáreas_Visualizar' = '97C7677357D240FFB75859A0528C684A37D5F88786B7A613AED39AD8EB213173',
  
  // Usuários
  'Administração_Portal_Usuários_Adicionar' = '7AED85EB53F7573A0513D5A3D039D99DEDDA7BA51EA2A6724E8745135BCEB129',
  'Administração_Portal_Usuários_Ativar/Desativar' = '02517CC47EBD95A4C97BC08023CBB66B5E28F276C629585EA6F07364FAE438EB',
  'Administração_Portal_Usuários_Editar' = '54C5A6A61FB8F35E8806276CA11EB6A97C12A7408C029B053D92ED8BA2E837C8',
  'Administração_Portal_Usuários_Visualizar' = 'F5A96C30C9E70541CBFBC39DB158346FB98CA66D6894EE4C3600185E8C3C1105',

  // Dúvidas Não Respondidas
  'Curadoria_Agentes_Dúvidas Não Resolvidas_Concluir' = '7C92188C83688932A118053D464B31DAE5C9827DB7CD58308B5A3A51C1FA6882',
  'Curadoria_Agentes_Dúvidas Não Resolvidas_Visualizar' = 'D25356C44EDCD149C3689DFCE18FEDC0FD896DF827BA8A534097BA0D4F738686',

  // Gerenciamento de Agentes
  'Curadoria_Agentes_Gerenciamento de Agentes_Adicionar' = '6554F0ED11019F8ED4DCD4BFE779B8055A83F2675AFBB56EA0B7C4CFA1D77C14',
  'Curadoria_Agentes_Gerenciamento de Agentes_Ativar/Desativar' = '1043FC0948B923804EE83E9BA256769A58F9114B3923582A8C1030316F87D55D',
  'Curadoria_Agentes_Gerenciamento de Agentes_Editar' = 'C9F67A9AB2761D89C28A8D7E999FAEE9B95D437076849EA1476ADB8C7527D26B',
  'Curadoria_Agentes_Gerenciamento de Agentes_Restaurar' = '1187E90512F9FA5B44BE368738B2952FCEBD14FCDEFEA10CA72A6121855F0B23',
  'Curadoria_Agentes_Gerenciamento de Agentes_Visualizar' = '40E91C900CBC764C447224B37718534DC3B1683E59097103082E21A1327B2477',

  // Gerenciamento de Looks
  'Design_Sugestão de Compras_Gerenciamento de Looks_Adicionar' = '88352A10BB1645308405EA003AD9B46889BC849E9AD490717219E12F334103D8',
  'Design_Sugestão de Compras_Gerenciamento de Looks_Ativar/Desativar' = '0FD2750838B05ACF1BC0B9A89495B811AB0DB1D346D71E2D44CE10748D8EED2D',
  'Design_Sugestão de Compras_Gerenciamento de Looks_Editar' = '9D4D61F6DE8EE56233EEC8926AD71FAFE6FC69565228F12FCD3F5E9AA93774AC',
  'Design_Sugestão de Compras_Gerenciamento de Looks_Visualizar' = '741718EEB41F2283F09A781ABC51BAA04EDBFDD5BCE6E9C3987B76157D8D65F9',

  // Produtos
  'Design_Sugestão de Compras_Produtos_Adicionar' = '718C5740F92F9E55A9F29C067752AD45507C5C2D920AA35E324EC233FF702FF2',
  'Design_Sugestão de Compras_Produtos_Ativar/Desativar' = '0361B6707B584527F4C6AFE92A2A710F38C25729B747C9AD651EA6225FE23DDA',
  'Design_Sugestão de Compras_Produtos_Editar' = '8A4C6EE12824123FB5D71C131A9D5EEE856D34F6F691EAF968D44B1C1254D440',
  'Design_Sugestão de Compras_Produtos_Visualizar' = 'B59FABB4B86F94A5D50F4E1769362D7E6183EFDF3A7013705BF931DF26470AC4',

  //Prompts
  'Administração_Portal_Prompts_Editar' = '602E112219C7E5FC1F0A6539F484AAD487BC75810060C9D277D7568F7D8E425B',
  'Administração_Portal_Prompts_Visualizar' = '64CBAE425F359DB55B1AC431367792EB05524FA448CA872AB7F4864EBDC6232C',

    // Níveis
    'Administração_Portal_Níveis_Adicionar' = '2228CCCBDBF451DAE7D0F81FE88AD3FD8AAFDA10C80EF544250E508B1916A80F',
    'Administração_Portal_Níveis_Ativar/Desativar' = 'AE862E96E7ADBA09DFCF8ACDE773B8FBE93498DD4420E065DA92A8AA6E629C99',
    'Administração_Portal_Níveis_Editar' = '603F2396934809433407960A532FBAAAA5B1352AA4FD56DC0384055459A14CC7',
    'Administração_Portal_Níveis_Visualizar' = 'D5DB16E2EABC641E057DB9A60B21A45F6EBB759FE3006AE04C963B32FAA2FC0A',
}


export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum EnumTaskStatus {
  "Processando" = 1,
  "Incompleto" = 2,
  "Completo" = 3,
  "Erro" = 4,
}

export enum EnumAutenticacaoTipo {
  "AspNetID" = 1,
  "MSEntraID" = 2,
}

export enum EnumUsuarioTipo {
  "Microsoft Interno" = 1,
  "Microsoft Externo" = 2,
  "Domínio Externo" = 3,
}