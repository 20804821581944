import * as Yup from 'yup'
import { IPromptModel } from "../../../../../modules/services/core/_models"

const defaultValidationSchemas = Yup.object().shape({
  Mensagem: Yup.string()
  .max(10000, 'A mensagem deve ter no máximo 10000 caracteres')
  .required('A mensagem é obrigatória')
})

const initialValues: IPromptModel = {
  Id: 0,
  IdAplicacao: 0,
  NomeAplicacao: '',
  Tipo: 0,
  TipoNome: '',
  TipoDescricao: '',
  Parametros: [],
  Mensagem: '',
}

export {defaultValidationSchemas, initialValues}
