/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { ArrayBreadcrumb, BreadcrumbLayout } from '../../../modules/services/core/Breadcrumb'
import { Perfis } from './components/Perfis'
import { Perfil } from './components/Perfil'
import { useAuth } from '../../../modules/auth'
import { EnumPermissoes, EnumRoles } from '../../../modules/services/core/_enums'

const wizardsBreadCrumbs: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Administração',
    path: '',
  },
]

const wizardsBreadCrumbsPerfis: Array<ArrayBreadcrumb> = [
  {
    titulo: 'Administração',
    path: '',
  },
  {
    titulo: 'Perfis',
    path: '/administracao/perfis/lista',
  }
]

const PerfisPage: FC = () => {
    const {auth} = useAuth()
    const roles = auth!.Roles
    const permissoes = auth!.Permissoes
  
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='lista'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbs}>Perfis</BreadcrumbLayout>
                <Perfis />
              </>
            }
          />

          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Perfis_Adicionar)) && (
              <Route
                path='adicionar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsPerfis}>Adicionar Perfil</BreadcrumbLayout>
                    <Perfil />
                  </>
                }
              /> 
          )}      
          
          {(roles.find((x) => x === EnumRoles.Administrador) || permissoes.find((x) => x === EnumPermissoes.Administração_Portal_Perfis_Editar)) && (
              <Route
                path='editar'
                element={
                  <>
                    <BreadcrumbLayout props={wizardsBreadCrumbsPerfis}>Editar Perfil</BreadcrumbLayout>
                    <Perfil />
                  </>
                }
              /> 
          )} 

          <Route
            path='visualizar'
            element={
              <>
                <BreadcrumbLayout props={wizardsBreadCrumbsPerfis}>Visualizar Perfil</BreadcrumbLayout>
                <Perfil />
              </>
            }
          /> 

          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route index element={<Navigate to='/administracao/perfis/lista' />} />
        </Route>
      </Routes>
    )
}

export {PerfisPage}
